import React from 'react';

import {
  FilterCategory,
  SortOrder,
  filterOptions,
  sortOptions,
} from '../__constants__/comment.types';
import './CommentControls.scss';

interface ICommentControlsProps {
  filterCategory: FilterCategory;
  setFilterCategory: (category: FilterCategory) => void;
  sortOrder: SortOrder;
  setSortOrder: (order: SortOrder) => void;
}

export const CommentControls: React.FC<ICommentControlsProps> = ({
  filterCategory,
  setFilterCategory,
  sortOrder,
  setSortOrder,
}) => {
  return (
    <div className='brutalist-comment-controls'>
      <div className='brutalist-control-group'>
        <label htmlFor='filter-category-select'>Filter by:</label>
        <select
          id='filter-category-select'
          className='brutalist-select'
          value={filterCategory}
          onChange={(e) => setFilterCategory(e.target.value as FilterCategory)}
        >
          {filterOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      <div className='brutalist-control-group'>
        <label htmlFor='sort-order-select'>Sort by:</label>
        <select
          id='sort-order-select'
          className='brutalist-select'
          value={sortOrder}
          onChange={(e) => setSortOrder(e.target.value as SortOrder)}
        >
          {sortOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
