import React, { useEffect } from 'react';

import { useAuth } from '../contexts/AuthContext';

interface IDarkModeRouteWrapperProps {
  children: React.ReactNode;
}

function DarkModeRouteWrapper({
  children,
}: IDarkModeRouteWrapperProps): JSX.Element {
  const { darkMode } = useAuth();

  useEffect(() => {
    const container = document.getElementById('post-login-container');
    if (container) {
      container.classList.toggle('dark-mode', darkMode);
    }
  }, [darkMode]);

  return <div id='post-login-container'>{children}</div>;
}

export default DarkModeRouteWrapper;
