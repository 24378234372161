export type CommentCategory =
  | 'left'
  | 'left-center'
  | 'right-center'
  | 'right'
  | 'other';

export const commentCategories: CommentCategory[] = [
  'left',
  'left-center',
  'right-center',
  'right',
  'other',
];

export type SortOrder = 'recent' | 'upvoted' | 'replies' | 'trending';

export const sortOptions: { value: SortOrder; label: string }[] = [
  { value: 'recent', label: 'Most Recent' },
  { value: 'upvoted', label: 'Most Upvoted' },
  { value: 'replies', label: 'Most Replies' },
  { value: 'trending', label: 'Trending' },
];

export type FilterCategory = CommentCategory | 'all';
export const filterOptions: { value: FilterCategory; label: string }[] = [
  { value: 'all', label: 'All' },
  { value: 'left', label: 'Left' },
  { value: 'left-center', label: 'Left Center' },
  { value: 'right-center', label: 'Right Center' },
  { value: 'right', label: 'Right' },
  { value: 'other', label: 'Other' },
];

export interface IUpvotes {
  [userId: string]: boolean;
}

export interface IRebuttal {
  content: string;
  author: string;
  authorId: string;
  profilePicture?: string;
  date: string;
  flags: number;
  upvotes: IUpvotes;
}

export interface IRebuttalThread {
  participants: string[]; // Array of userIds (max length 2)
  exchangeCount: number; // Current number of exchanges (max 5)
}

export interface IReply {
  content: string;
  author: string;
  authorId: string;
  profilePicture?: string;
  date: string;
  flags: number;
  upvotes: IUpvotes;
  rebuttals?: { [rebuttalId: string]: IRebuttal };
  rebuttalThread?: IRebuttalThread;
}

export interface IComment {
  content: string;
  author: string;
  authorId: string;
  profilePicture?: string;
  date: string;
  flags: number;
  upvotes: IUpvotes;
  category?: CommentCategory;
  replies?: { [replyId: string]: IReply };
}

export interface ICommentsData {
  [commentId: string]: IComment;
}

export interface IOpenReplies {
  [key: string]: { isOpen: boolean; shownReplies: number };
}

export interface IOpenRebuttals {
  [key: string]: { isOpen: boolean; shownRebuttals: number };
}

export type HandleDeleteComment = (commentId: string) => void;
export type HandleDeleteReply = (commentId: string, replyId: string) => void;
export type HandleDeleteRebuttal = (
  commentId: string,
  replyId: string,
  rebuttalId: string,
) => void;
export type HandleUpvote = (
  commentId: string,
  replyId?: string,
  rebuttalId?: string,
) => void;
export type HandleAddReply = (commentId: string) => void;
export type HandleAddRebuttal = (commentId: string, replyId: string) => void;
