import { httpsCallable } from 'firebase/functions';
import React, { useState, useEffect, useCallback } from 'react';
import { Alert, Button, Card, Col, Row, Spinner, Table } from 'react-bootstrap';

import ArticleForm from './ArticleForm';
import { IArticle } from './types';
import { functions } from '../../firebase';

// Define functions outside component to prevent recreation on each render
const getArticlesFunction = httpsCallable(functions, 'getArticles');
const deleteArticleFunction = httpsCallable(functions, 'deleteArticle');
const toggleArticlePublishedFunction = httpsCallable(
  functions,
  'toggleArticlePublished',
);

const ArticleManagement: React.FC = () => {
  const [articles, setArticles] = useState<IArticle[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [editingArticle, setEditingArticle] = useState<IArticle | null>(null);
  const [refreshKey, setRefreshKey] = useState(0); // Used to trigger refreshes

  const fetchArticles = useCallback(async () => {
    // Skip fetching if we're already loading to prevent infinite loop
    if (loading) return;

    setLoading(true);
    setError('');

    try {
      const result = await getArticlesFunction({});
      const data = result.data as { articles: IArticle[] };
      setArticles(data.articles || []);
    } catch (err) {
      setError('Failed to load articles. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [loading]);

  // Load articles on component mount
  useEffect(() => {
    fetchArticles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKey]);

  const handleDelete = async (articleId: string) => {
    // Instead of using window.confirm, we'll proceed directly
    // In a real application, you would want to use a custom confirmation dialog component
    setLoading(true);
    setError('');

    try {
      await deleteArticleFunction({ articleId });
      // Refresh the article list
      setRefreshKey((prev) => prev + 1);
    } catch (err) {
      setError('Failed to delete article. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleTogglePublished = async (
    articleId: string,
    currentStatus: boolean,
  ) => {
    setLoading(true);
    setError('');

    try {
      await toggleArticlePublishedFunction({
        articleId,
        isPublished: !currentStatus,
      });

      // Refresh the article list
      setRefreshKey((prev) => prev + 1);
    } catch (err) {
      setError(
        `Failed to ${currentStatus ? 'unpublish' : 'publish'} article. Please try again.`,
      );
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (article: IArticle) => {
    setEditingArticle(article);
    setShowForm(true);
  };

  const handleFormClose = () => {
    setShowForm(false);
    setEditingArticle(null);
    // Refresh articles after closing the form
    setRefreshKey((prev) => prev + 1);
  };

  return (
    <div className='article-management'>
      <Card className='mb-4'>
        <Card.Header className='d-flex justify-content-between align-items-center'>
          <h3>Article Management</h3>
          <Button
            variant='primary'
            onClick={() => {
              setEditingArticle(null);
              setShowForm(true);
            }}
          >
            Create New Article
          </Button>
        </Card.Header>
        <Card.Body>
          {error && <Alert variant='danger'>{error}</Alert>}

          {loading && !showForm ? (
            <div className='text-center my-4'>
              <Spinner animation='border' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </Spinner>
            </div>
          ) : articles.length === 0 ? (
            <Alert variant='info'>
              No articles found. Create your first article!
            </Alert>
          ) : (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Date</th>
                  <th>Author</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {articles.map((article) => (
                  <tr key={article.id}>
                    <td>{article.title}</td>
                    <td>{new Date(article.date).toLocaleDateString()}</td>
                    <td>{article.author}</td>
                    <td>
                      <span
                        className={`badge ${article.isPublished ? 'bg-success' : 'bg-secondary'}`}
                      >
                        {article.isPublished ? 'Published' : 'Draft'}
                      </span>
                    </td>
                    <td>
                      <Row className='g-2'>
                        <Col xs='auto'>
                          <Button
                            variant='outline-primary'
                            size='sm'
                            onClick={() => handleEdit(article)}
                          >
                            Edit
                          </Button>
                        </Col>
                        <Col xs='auto'>
                          <Button
                            variant={
                              article.isPublished
                                ? 'outline-secondary'
                                : 'outline-success'
                            }
                            size='sm'
                            onClick={() =>
                              handleTogglePublished(
                                article.id,
                                article.isPublished,
                              )
                            }
                          >
                            {article.isPublished ? 'Unpublish' : 'Publish'}
                          </Button>
                        </Col>
                        <Col xs='auto'>
                          <Button
                            variant='outline-danger'
                            size='sm'
                            onClick={() => handleDelete(article.id)}
                          >
                            Delete
                          </Button>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Card.Body>
      </Card>

      {showForm && (
        <ArticleForm
          show={showForm}
          onHide={handleFormClose}
          article={editingArticle}
        />
      )}
    </div>
  );
};

export default ArticleManagement;
