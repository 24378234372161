import {
  signOut,
  signInWithPopup,
  GoogleAuthProvider,
  signInAnonymously,
} from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';

import { auth, functions } from '../firebase';

/**
 * Authentication service for interacting with Firebase Functions
 */
export const authService = {
  /**
   * Gets user preferences including dark mode setting
   */
  async getUserPreferences(): Promise<{ darkMode: boolean }> {
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('No user is logged in');
      }

      const getUserPreferencesFn = httpsCallable(
        functions,
        'getUserPreferences',
      );
      const result = await getUserPreferencesFn({ uid: user.uid });
      return (
        (result.data as { data?: { darkMode: boolean } }).data || {
          darkMode: false,
        }
      );
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : 'Failed to get user preferences';
      throw new Error(errorMessage);
    }
  },

  /**
   * Updates user's dark mode preference
   */
  async updateDarkModePreference(darkMode: boolean): Promise<unknown> {
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('No user is logged in');
      }

      const updateDarkModePreferenceFn = httpsCallable(
        functions,
        'updateDarkModePreference',
      );
      const result = await updateDarkModePreferenceFn({
        uid: user.uid,
        darkMode,
      });
      return result.data;
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : 'Failed to update dark mode preference';
      throw new Error(errorMessage);
    }
  },
  /**
   * Creates a new user with email and password
   */
  async signup(
    email: string,
    password: string,
    username: string,
  ): Promise<unknown> {
    try {
      const createUserFn = httpsCallable(functions, 'createUser');
      const result = await createUserFn({ email, password, username });
      return result.data;
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error ? error.message : 'Failed to create user';
      throw new Error(errorMessage);
    }
  },

  /**
   * Logs in a user with email and password
   * Using Firebase Auth directly as recommended by Firebase
   * @param email User's email
   * @param password User's password
   * @param rememberMe Whether to keep the user logged in for 30 days
   */
  async login(
    email: string,
    password: string,
    rememberMe: boolean = false,
  ): Promise<{ success: boolean }> {
    try {
      // Use Firebase Auth directly for email/password authentication
      const {
        signInWithEmailAndPassword,
        setPersistence,
        browserLocalPersistence,
        browserSessionPersistence,
      } = await import('firebase/auth');

      // Set persistence based on rememberMe checkbox
      // LOCAL: Persists across browser sessions (30 days)
      // SESSION: Cleared when browser window is closed
      await setPersistence(
        auth,
        rememberMe ? browserLocalPersistence : browserSessionPersistence,
      );

      // Sign in user after setting persistence
      await signInWithEmailAndPassword(auth, email, password);
      return { success: true };
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error ? error.message : 'Failed to login';
      throw new Error(errorMessage);
    }
  },

  /**
   * Logs out the current user
   */
  async logout(): Promise<void> {
    try {
      await signOut(auth);
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error ? error.message : 'Failed to logout';
      throw new Error(errorMessage);
    }
  },

  /**
   * Sends a password reset email using Firebase Auth's built-in functionality
   */
  async forgotPassword(
    email: string,
  ): Promise<{ success: boolean; message: string }> {
    try {
      const { sendPasswordResetEmail } = await import('firebase/auth');
      await sendPasswordResetEmail(auth, email);
      return { success: true, message: 'Password reset email sent' };
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : 'Failed to send password reset email';
      throw new Error(errorMessage);
    }
  },

  /**
   * Updates a user's email
   */
  async updateUserEmail(email: string): Promise<unknown> {
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('No user is logged in');
      }

      const updateEmailFn = httpsCallable(functions, 'updateEmail');
      const result = await updateEmailFn({ uid: user.uid, email });
      return result.data;
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error ? error.message : 'Failed to update email';
      throw new Error(errorMessage);
    }
  },

  /**
   * Updates a user's password
   */
  async updateUserPassword(password: string): Promise<unknown> {
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('No user is logged in');
      }

      const updatePasswordFn = httpsCallable(functions, 'updatePassword');
      const result = await updatePasswordFn({ uid: user.uid, password });
      return result.data;
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error ? error.message : 'Failed to update password';
      throw new Error(errorMessage);
    }
  },

  /**
   * Updates a user's username
   */
  async updateUsername(username: string): Promise<unknown> {
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('No user is logged in');
      }

      const updateUsernameFn = httpsCallable(functions, 'updateUsername');
      const result = await updateUsernameFn({ uid: user.uid, username });
      return result.data;
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error ? error.message : 'Failed to update username';
      throw new Error(errorMessage);
    }
  },

  /**
   * Logs in a user anonymously
   */
  async loginAsGuest(): Promise<void> {
    try {
      await signInAnonymously(auth);
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error ? error.message : 'Failed to login as guest';
      throw new Error(errorMessage);
    }
  },

  /**
   * Uploads a profile picture
   */
  async uploadProfilePicture(file: File): Promise<unknown> {
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('No user is logged in');
      }

      // Convert file to base64
      const reader = new FileReader();
      const fileBase64 = await new Promise<string>((resolve) => {
        reader.onload = () => resolve(reader.result as string);
        reader.readAsDataURL(file);
      });

      const uploadProfilePictureFn = httpsCallable(
        functions,
        'uploadProfilePicture',
      );
      const result = await uploadProfilePictureFn({
        uid: user.uid,
        file: fileBase64,
        contentType: file.type,
      });

      return result.data;
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : 'Failed to upload profile picture';
      throw new Error(errorMessage);
    }
  },

  /**
   * Authenticates a user with Google
   */
  async googleAuth(): Promise<import('firebase/auth').User> {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);

      // Get the ID token
      const idToken = await result.user.getIdToken();

      // Call the Firebase Function to handle additional logic if needed
      const googleAuthFn = httpsCallable(functions, 'googleAuth');
      await googleAuthFn({ idToken });

      return result.user;
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : 'Failed to authenticate with Google';
      throw new Error(errorMessage);
    }
  },

  /**
   * Validates a username
   */
  async validateUsername(username: string): Promise<unknown> {
    try {
      const validateUsernameFn = httpsCallable(functions, 'validateUsername');
      const result = await validateUsernameFn({ username });
      return result.data;
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error ? error.message : 'Failed to validate username';
      throw new Error(errorMessage);
    }
  },

  /**
   * Checks if a username is unique
   */
  async checkUsernameUnique(username: string): Promise<boolean> {
    try {
      const checkUsernameUniqueFn = httpsCallable(
        functions,
        'checkUsernameUnique',
      );
      const result = await checkUsernameUniqueFn({ username });
      return (
        (result.data as { data?: { isUnique: boolean } }).data?.isUnique ||
        false
      );
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : 'Failed to check username uniqueness';
      throw new Error(errorMessage);
    }
  },

  /**
   * Updates a user's profile information including bio and social links
   */
  async updateUserProfile(
    uid: string,
    bio: string,
    socialLinks: Array<{ platform: string; username: string }>,
  ): Promise<{ success: boolean; error?: string }> {
    try {
      const updateUserProfileFn = httpsCallable(functions, 'updateUserProfile');
      const result = await updateUserProfileFn({
        uid,
        bio,
        socialLinks,
      });
      return result.data as { success: boolean; error?: string };
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : 'Failed to update profile information';
      return {
        success: false,
        error: errorMessage,
      };
    }
  },

  /**
   * Check if the current user has admin role
   * @returns Promise that resolves to a boolean indicating if user is admin
   */
  async isUserAdmin(): Promise<boolean> {
    try {
      const user = auth.currentUser;
      if (!user) return false;

      // Get the ID token with fresh claims
      const idTokenResult = await user.getIdTokenResult(true);

      // Check admin claim
      return !!idTokenResult.claims.admin;
    } catch (error: unknown) {
      console.error('Error checking admin status:', error);
      return false;
    }
  },

  /**
   * Set a user as admin (only callable by existing admins)
   * @param uid User ID to set as admin
   * @param isAdmin Whether to grant or revoke admin status
   */
  async setUserAdmin(
    uid: string,
    isAdmin: boolean,
  ): Promise<{ success: boolean; message?: string; error?: string }> {
    try {
      const setAdminRoleFunc = httpsCallable(functions, 'setAdminRole');
      const result = await setAdminRoleFunc({ uid, isAdmin });
      return result.data as {
        success: boolean;
        message?: string;
        error?: string;
      };
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error ? error.message : 'Failed to set admin role';
      return {
        success: false,
        error: errorMessage,
      };
    }
  },

  /**
   * Get muted users for the current user
   */
  async getMutedUsers(): Promise<
    Array<{
      id: string;
      displayName: string;
      username: string;
      photoURL: string;
    }>
  > {
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('No user is logged in');
      }

      const getMutedUsersFn = httpsCallable(functions, 'getMutedUsers');
      const result = await getMutedUsersFn({});
      return result.data as Array<{
        id: string;
        displayName: string;
        username: string;
        photoURL: string;
      }>;
    } catch (error: unknown) {
      console.error('Error getting muted users:', error);
      return [];
    }
  },

  /**
   * Mute a user
   */
  async muteUser(targetUserId: string): Promise<{
    success: boolean;
    alreadyMuted?: boolean;
    error?: string;
  }> {
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('No user is logged in');
      }

      const muteUserFn = httpsCallable(functions, 'muteUser');
      const result = await muteUserFn({ targetUserId });
      return result.data as {
        success: boolean;
        alreadyMuted?: boolean;
      };
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error ? error.message : 'Failed to mute user';
      return {
        success: false,
        error: errorMessage,
      };
    }
  },

  /**
   * Unmute a user
   */
  async unmuteUser(targetUserId: string): Promise<{
    success: boolean;
    notMuted?: boolean;
    error?: string;
  }> {
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('No user is logged in');
      }

      const unmuteUserFn = httpsCallable(functions, 'unmuteUser');
      const result = await unmuteUserFn({ targetUserId });
      return result.data as {
        success: boolean;
        notMuted?: boolean;
      };
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error ? error.message : 'Failed to unmute user';
      return {
        success: false,
        error: errorMessage,
      };
    }
  },

  /**
   * Check if a user is muted
   */
  async checkMuteStatus(targetUserId: string): Promise<boolean> {
    try {
      const user = auth.currentUser;
      if (!user) {
        return false;
      }

      const checkMuteStatusFn = httpsCallable(functions, 'checkMuteStatus');
      const result = await checkMuteStatusFn({ targetUserId });
      return (result.data as { isMuted: boolean }).isMuted;
    } catch (error: unknown) {
      console.error('Error checking mute status:', error);
      return false;
    }
  },

  /**
   * Get comments from followed users
   */
  async getFollowedComments(
    options: {
      limit?: number;
      startAfter?: { timestamp: number; commentId: string };
      includeArticleDetails?: boolean;
    } = {},
  ): Promise<
    Array<{
      id: string;
      articleId: string;
      articleTitle?: string;
      content: string;
      author: string;
      authorId: string;
      authorUsername?: string;
      profilePicture?: string;
      timestamp: number;
      timestampISO: string;
    }>
  > {
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('No user is logged in');
      }

      const getFollowedCommentsFn = httpsCallable(
        functions,
        'getFollowedComments',
      );
      const result = await getFollowedCommentsFn(options);
      return result.data as Array<{
        id: string;
        articleId: string;
        articleTitle?: string;
        content: string;
        author: string;
        authorId: string;
        authorUsername?: string;
        profilePicture?: string;
        timestamp: number;
        timestampISO: string;
      }>;
    } catch (error: unknown) {
      console.error('Error getting followed comments:', error);
      return [];
    }
  },
};
