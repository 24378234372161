import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import AdminPortal from './AdminPortal';
import AdminRoute from './AdminRoute';
import DarkModeRouteWrapper from './DarkModeRouteWrapper';
import EventDisplay from './MainComponents/EventDisplay/eventDisplay';
import FollowersFeed from './MainComponents/FollowingFeed/FollowingFeed';
import ProfileScreen from './MainComponents/ProfileScreen';
import PrivateRoute from './PrivateRoute';
import Dashboard from './StartingScreens/PostLogin/dashboard';
import { DashboardProvider } from './StartingScreens/PostLogin/dashboardProvider';
import UpdateProfile from './StartingScreens/PostLogin/updateProfile';
import WelcomeScreen from './StartingScreens/PostLogin/welcomeScreen';
import ForgotPass from './StartingScreens/PreLogin/forgotPassword';
import LandingPage from './StartingScreens/PreLogin/landingPage';
import LoginPage from './StartingScreens/PreLogin/loginPage';
import PreLoginLayout from './StartingScreens/PreLogin/PreLoginLayout';
import SignUpPage from './StartingScreens/PreLogin/signupScreen';
import AlertProvider from '../contexts/AlertContext';
import { AuthProvider } from '../contexts/AuthContext';

function App(): JSX.Element {
  return (
    <BrowserRouter>
      <AuthProvider>
        <AlertProvider>
          <Routes>
            {/* Pre-login routes - no dark mode */}
            <Route element={<PreLoginLayout />}>
              <Route path='/' element={<LandingPage />} />
              <Route path='/login' element={<LoginPage />} />
              <Route path='/signup' element={<SignUpPage />} />
              <Route path='/forgot-password' element={<ForgotPass />} />
            </Route>

            {/* Post-login routes - with dark mode */}
            <Route
              path='/welcome'
              element={
                <DarkModeRouteWrapper>
                  <WelcomeScreen />
                </DarkModeRouteWrapper>
              }
            />
            <Route
              path='/dashboard'
              element={
                <DarkModeRouteWrapper>
                  <PrivateRoute>
                    <DashboardProvider>
                      <Dashboard />
                    </DashboardProvider>
                  </PrivateRoute>
                </DarkModeRouteWrapper>
              }
            />
            <Route
              path='/update-profile'
              element={
                <DarkModeRouteWrapper>
                  <PrivateRoute>
                    <UpdateProfile />
                  </PrivateRoute>
                </DarkModeRouteWrapper>
              }
            />
            <Route
              path='/profile'
              element={
                <DarkModeRouteWrapper>
                  <PrivateRoute>
                    <ProfileScreen />
                  </PrivateRoute>
                </DarkModeRouteWrapper>
              }
            />
            <Route
              path='/profile/:userId'
              element={
                <DarkModeRouteWrapper>
                  <PrivateRoute>
                    <ProfileScreen />
                  </PrivateRoute>
                </DarkModeRouteWrapper>
              }
            />
            <Route
              path='/followers'
              element={
                <DarkModeRouteWrapper>
                  <PrivateRoute>
                    <FollowersFeed />
                  </PrivateRoute>
                </DarkModeRouteWrapper>
              }
            />
            {/* Support for both new SEO-friendly URLs and legacy article IDs */}
            <Route
              path='/event/:articleId'
              element={
                <DarkModeRouteWrapper>
                  <EventDisplay />
                </DarkModeRouteWrapper>
              }
            />
            <Route
              path='/event/article/:urlSlug'
              element={
                <DarkModeRouteWrapper>
                  <EventDisplay />
                </DarkModeRouteWrapper>
              }
            />
            <Route
              path='/admin'
              element={
                <DarkModeRouteWrapper>
                  <AdminRoute>
                    <AdminPortal />
                  </AdminRoute>
                </DarkModeRouteWrapper>
              }
            />
          </Routes>
        </AlertProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
