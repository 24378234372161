import React from 'react';

import { BrutalistButton } from '../../../SharedComponents/BrutalistUI';

interface IFollowButtonProps {
  isFollowing: boolean;
  isOwnProfile: boolean;
  onFollow: () => Promise<void>;
  onUnfollow: () => Promise<void>;
  isLoading?: boolean;
  error?: string | null;
}

const FollowButton: React.FC<IFollowButtonProps> = ({
  isFollowing,
  isOwnProfile,
  onFollow,
  onUnfollow,
  isLoading = false,
  error = null,
}) => {
  // Don't show follow button on own profile
  if (isOwnProfile) return null;

  return (
    <div className='follow-button-container'>
      <BrutalistButton
        onClick={isFollowing ? onUnfollow : onFollow}
        className={`follow-button ${isFollowing ? 'following' : ''}`}
        btnSize='small'
        disabled={isLoading}
      >
        {isLoading ? 'Loading...' : isFollowing ? 'Following' : 'Follow'}
      </BrutalistButton>
      {error && <div className='follow-error-message'>{error}</div>}
    </div>
  );
};

export default FollowButton;
