import { useState, useEffect } from 'react';

import { authService } from '../../../services/authService';

interface IUseMuteStatusProps {
  userId?: string;
  isOwnProfile?: boolean;
  currentUser: {
    uid: string;
    // Add other user properties as needed
  } | null;
}

interface IUseMuteStatusResult {
  isMuted: boolean;
  muteStatusLoading: boolean;
  handleMuteToggle: () => Promise<void>;
}

export const useMuteStatus = ({
  userId,
  isOwnProfile,
  currentUser,
}: IUseMuteStatusProps): IUseMuteStatusResult => {
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [muteStatusLoading, setMuteStatusLoading] = useState<boolean>(false);

  // Check if the profile being viewed is of a muted user
  useEffect(() => {
    const checkMuteStatus = async () => {
      if (!currentUser || !userId || isOwnProfile) {
        setIsMuted(false);
        return;
      }

      setMuteStatusLoading(true);
      try {
        const muted = await authService.checkMuteStatus(userId);
        setIsMuted(muted);
      } catch (error) {
        console.error('Error checking mute status:', error);
      } finally {
        setMuteStatusLoading(false);
      }
    };

    checkMuteStatus();
  }, [currentUser, userId, isOwnProfile]);

  // Handle muting/unmuting a user
  const handleMuteToggle = async () => {
    if (!currentUser || !userId || isOwnProfile) return;

    setMuteStatusLoading(true);
    try {
      if (isMuted) {
        // Unmute the user
        await authService.unmuteUser(userId);
        setIsMuted(false);
      } else {
        // Mute the user
        await authService.muteUser(userId);
        setIsMuted(true);
      }
    } catch (error) {
      console.error('Error toggling mute status:', error);
    } finally {
      setMuteStatusLoading(false);
    }
  };

  return {
    isMuted,
    muteStatusLoading,
    handleMuteToggle,
  };
};
