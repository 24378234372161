//useCommentSystemPresentation.ts
import { styles as lightStyles, IStyles } from './commentSystem.styles';
import { useAuth } from '../../../contexts/AuthContext';

export default function useCommentSystemPresentation(): { styles: IStyles } {
  const { darkMode } = useAuth();

  // Create dark mode styles by modifying the light mode styles
  const darkStyles: IStyles = {
    ...lightStyles,
    // Override specific styles for dark mode
    profilePicture: {
      ...lightStyles.profilePicture,
      border: '2px solid var(--brutalist-dark-border)',
    },
    commentWrapper: {
      ...lightStyles.commentWrapper,
      backgroundColor: 'var(--comment-bg)',
      border: '2px solid var(--comment-border)',
      borderLeft: '4px solid var(--comment-accent)',
      color: 'var(--brutalist-dark-text)',
    },
    replyWrapper: {
      ...lightStyles.replyWrapper,
      backgroundColor: 'var(--reply-bg)',
      border: '2px solid var(--reply-border)',
      color: 'var(--brutalist-dark-text)',
    },
    rebuttalWrapper: {
      ...lightStyles.rebuttalWrapper,
      backgroundColor: 'var(--rebuttal-bg)',
      border: '2px solid var(--rebuttal-border)',
      color: 'var(--brutalist-dark-text)',
    },
    commentText: {
      ...lightStyles.commentText,
      color: darkMode
        ? 'var(--brutalist-dark-text)'
        : 'var(--brutalist-text-dark)',
    },
    authorText: {
      ...lightStyles.authorText,
      color: darkMode
        ? 'var(--brutalist-gray-400)'
        : 'var(--brutalist-gray-600)',
    },
    upvoteButton: {
      ...lightStyles.upvoteButton,
      color: darkMode
        ? 'var(--brutalist-gray-500)'
        : 'var(--brutalist-gray-400)',
    },
    deleteIcon: {
      ...lightStyles.deleteIcon,
      color: darkMode
        ? 'var(--brutalist-gray-500)'
        : 'var(--brutalist-gray-400)',
    },
    touchableText: {
      ...lightStyles.touchableText,
      color: darkMode
        ? 'var(--brutalist-gray-500)'
        : 'var(--brutalist-gray-400)',
    },
    primaryColor: {
      ...lightStyles.primaryColor,
      color: darkMode
        ? 'var(--brutalist-dark-accent)'
        : 'var(--brutalist-gray-400)',
    },
    addReply: {
      ...lightStyles.addReply,
      color: darkMode
        ? 'var(--brutalist-gray-500)'
        : 'var(--brutalist-gray-400)',
    },
    replyBox: {
      ...lightStyles.replyBox,
      backgroundColor: darkMode
        ? 'var(--brutalist-dark-surface)'
        : 'var(--comment-bg)',
      border: darkMode
        ? '2px solid var(--brutalist-dark-border)'
        : '2px solid var(--comment-border)',
      color: darkMode
        ? 'var(--brutalist-dark-text)'
        : 'var(--brutalist-text-dark)',
    },
    timestamp: {
      ...lightStyles.timestamp,
      color: darkMode
        ? 'var(--brutalist-gray-600)'
        : 'var(--brutalist-gray-500)',
    },
  };

  return { styles: darkMode ? darkStyles : lightStyles };
}
