//dashboard.tsx
import React, { ReactNode } from 'react';
import { Container, Row } from 'react-bootstrap';

import LeftSidePanel from './DashboardComponents/leftSidePanel';
import MainContent from './DashboardComponents/mainContent';
import NavbarMini from './DashboardComponents/Navigation/navbarMini';
import RightSidePanel from './DashboardComponents/rightSidePanel';
import { useDashboard } from './dashboardProvider';
import { useAuth } from '../../../contexts/AuthContext';
import IAlertsContent from '../../MainComponents/AlertsFeed/alertsContent';
import ArchiveContent from '../../MainComponents/ArchiveFeed/archiveContent';
import FollowersFeed from '../../MainComponents/FollowingFeed/FollowingFeed';

import '../../../styles/brutalist.scss';
import './dashboard.scss';

interface IDashboardLayoutProps {
  children: ReactNode;
}

const DashboardLayout = ({ children }: IDashboardLayoutProps) => {
  const { darkMode } = useAuth();
  return (
    <Container
      fluid
      className={`dashboard-layout ${darkMode ? 'dark-mode' : ''}`}
    >
      <Row className='dashboard-row'>{children}</Row>
    </Container>
  );
};

export default function Dashboard(): JSX.Element {
  const { activeComponent } = useDashboard();

  return (
    <DashboardLayout>
      {activeComponent === 'main' ? (
        <Row className='dashboard-row'>
          <LeftSidePanel />
          <MainContent />
          <RightSidePanel />
        </Row>
      ) : activeComponent === 'alerts' ? (
        <Row className='dashboard-row'>
          <LeftSidePanel />
          <IAlertsContent />
          <RightSidePanel />
        </Row>
      ) : activeComponent === 'followers' ? (
        <Row className='dashboard-row'>
          <LeftSidePanel />
          <FollowersFeed />
          <RightSidePanel />
        </Row>
      ) : (
        <Row className='dashboard-row'>
          <LeftSidePanel />
          <ArchiveContent />
          <RightSidePanel />
        </Row>
      )}
      <Row className='d-sm-none'>
        <NavbarMini />
      </Row>
    </DashboardLayout>
  );
}
