import { collection, query, where, getDocs } from 'firebase/firestore';
import { useState, useEffect } from 'react';

import { alertEvents } from './alertEvents';
import { useAuth } from '../../../../../contexts/AuthContext';
import { fsdb } from '../../../../../firebase';

interface IUnreadAlertsResult {
  unreadCount: number;
  loading: boolean;
}

export const useUnreadAlerts = (): IUnreadAlertsResult => {
  const [unreadCount, setUnreadCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    const fetchUnreadAlerts = async () => {
      if (!user) {
        setUnreadCount(0);
        setLoading(false);
        return;
      }

      try {
        setLoading(true);

        // Query the alerts collection for unread alerts for this user
        const alertsRef = collection(fsdb, 'alerts');
        const alertsQuery = query(
          alertsRef,
          where('userId', '==', user.uid),
          where('read', '==', false),
        );

        const querySnapshot = await getDocs(alertsQuery);
        setUnreadCount(querySnapshot.size);
      } catch (error) {
        console.error('Error fetching unread alerts count:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUnreadAlerts();

    // Subscribe to alert events (when alerts are marked as read)
    const unsubscribe = alertEvents.subscribe(fetchUnreadAlerts);

    // Set up an interval to refresh the count every minute
    const intervalId = setInterval(fetchUnreadAlerts, 60000);

    return () => {
      clearInterval(intervalId);
      unsubscribe();
    };
  }, [user]);

  return { unreadCount, loading };
};
