import React, { useState } from 'react';
import { Container, Nav, Tab } from 'react-bootstrap';

import { AlertManagement } from './AdminComponents/alerts/index';
import ArticleManagement from './AdminComponents/ArticleManagement';
import AdminDebug from './AdminDebug';
import { useAuth } from '../contexts/AuthContext';

const AdminPortal: React.FC = () => {
  const { isAdmin } = useAuth();
  const [activeTab, setActiveTab] = useState('articles');

  if (!isAdmin) {
    return (
      <Container className='mt-5'>
        <h2>Access Denied</h2>
        <p>You must be an admin to access this page.</p>
      </Container>
    );
  }

  return (
    <Container fluid className='admin-portal mt-4'>
      <h1 className='mb-4'>Admin Portal</h1>

      <Tab.Container
        activeKey={activeTab}
        onSelect={(k) => k && setActiveTab(k)}
      >
        <Nav variant='tabs' className='mb-4'>
          <Nav.Item>
            <Nav.Link eventKey='articles'>Article Management</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey='alerts'>Alerts</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey='debug'>Admin Debug</Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content>
          <Tab.Pane eventKey='articles'>
            <ArticleManagement />
          </Tab.Pane>
          <Tab.Pane eventKey='alerts'>
            <AlertManagement />
          </Tab.Pane>
          <Tab.Pane eventKey='debug'>
            <AdminDebug />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Container>
  );
};

export default AdminPortal;
