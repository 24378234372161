//tooltip.tsx
import React, { useState } from 'react';
import { TbInfoCircle } from 'react-icons/tb';

interface ITooltipContent {
  title: string;
  body: string;
  bulletPointOne: string;
  bulletPointTwo: string;
  bulletPointThree: string;
}

const contentObject: Record<string, ITooltipContent> = {
  commentComponent: {
    title: 'This is the comment component',
    body: 'This is how you use the comment component',
    bulletPointOne: 'This is bullet one',
    bulletPointTwo: 'This is bullet two',
    bulletPointThree: 'This is bullet three',
  },
  eventDisplay: {
    title: 'This is the event display',
    body: 'This is how you use the event display',
    bulletPointOne: 'This is bullet one',
    bulletPointTwo: 'This is bullet two',
    bulletPointThree: 'This is bullet three',
  },
};

interface ITooltipProps {
  componentName: string;
}

const Tooltip: React.FC<ITooltipProps> = ({ componentName }) => {
  const [isVisible, setIsVisible] = useState(false);

  const tooltipContent = contentObject[componentName];

  const handleMouseEnter = () => {
    setIsVisible(true);
  };

  const handleMouseLeave = () => {
    setIsVisible(false);
  };

  const parentStyle: React.CSSProperties = {
    position: 'relative',
  };

  const tooltipStyle: React.CSSProperties = {
    backgroundColor: 'var(--brutalist-gray-900)',
    color: 'var(--brutalist-text-light)',
    padding: '10px',
    borderRadius: '5px',
    position: 'absolute',
    zIndex: 1,
    top: '30px',
    right: '0',
    width: '200px',
    fontSize: '0.85rem',
    boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
  };

  return (
    <div
      style={parentStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onTouchStart={handleMouseEnter}
      onTouchEnd={handleMouseLeave}
    >
      <TbInfoCircle size={24} style={{ cursor: 'pointer' }} />
      {isVisible && tooltipContent && (
        <div style={tooltipStyle}>
          <strong>{tooltipContent.title}</strong>
          <p>{tooltipContent.body}</p>
          <ul>
            <li>{tooltipContent.bulletPointOne}</li>
            <li>{tooltipContent.bulletPointTwo}</li>
            <li>{tooltipContent.bulletPointThree}</li>
          </ul>
        </div>
      )}
    </div>
  );
};
export default Tooltip;
