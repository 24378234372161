import React, { useContext, useState, useEffect } from 'react';

import { IUser } from './types';
import { auth } from '../firebase';
import { authService } from '../services/authService';

export const AuthContext = React.createContext<IAuthContextType | null>(null);

// Define a type for auth operation results
interface IAuthResult {
  success: boolean;
  error?: string;
}

export interface IAuthContextType {
  user: IUser | null;
  login: (
    email: string,
    password: string,
    rememberMe?: boolean,
  ) => Promise<void>;
  logout: () => Promise<void>;
  signup: (
    email: string,
    password: string,
    username: string,
  ) => Promise<IAuthResult>;
  forgotPassword: (email: string) => Promise<void>;
  updateUserEmail: (email: string) => Promise<void>;
  updateUserPassword: (password: string) => Promise<void>;
  loginAsGuest: () => Promise<void>;
  updateUsername: (username: string) => Promise<void>;
  darkMode: boolean;
  toggleDarkMode: () => Promise<void>;
  checkAdminStatus: () => Promise<boolean>;
  refreshUserClaims: () => Promise<void>;
  isAdmin: boolean;
}

export function isValidUsername(username: string): boolean {
  const usernameRegex = /^[a-zA-Z0-9]+$/;
  return usernameRegex.test(username);
}

export async function checkUsernameUnique(
  username: string | null,
): Promise<boolean> {
  if (!username) return false;
  if (!isValidUsername(username)) return false;
  return await authService.checkUsernameUnique(username);
}

export function useAuth(): IAuthContextType {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export function AuthProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [user, setUser] = useState<IUser | null>(null);
  const [loading, setLoading] = useState(true);
  const [darkMode, setDarkMode] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  // Helper function for consistent error handling
  const handleAuthError = (error: unknown, defaultMessage: string): never => {
    const errorMessage =
      error instanceof Error ? error.message : defaultMessage;
    throw new Error(errorMessage);
  };

  // Helper function to handle results with success property
  const handleAuthResult = (
    result: IAuthResult,
    defaultErrorMessage: string,
  ): void => {
    if (!result.success) {
      throw new Error(result.error || defaultErrorMessage);
    }
  };

  async function signup(
    email: string,
    password: string,
    username: string,
  ): Promise<IAuthResult> {
    try {
      const result = (await authService.signup(
        email,
        password,
        username,
      )) as IAuthResult;
      handleAuthResult(result, 'Failed to create an account');
      return result;
    } catch (error: unknown) {
      return handleAuthError(error, 'Failed to create an account');
    }
  }

  async function login(
    email: string,
    password: string,
    rememberMe: boolean = false,
  ): Promise<void> {
    try {
      await authService.login(email, password, rememberMe);
    } catch (error: unknown) {
      handleAuthError(error, 'Failed to login');
    }
  }

  async function logout(): Promise<void> {
    try {
      return await authService.logout();
    } catch (error: unknown) {
      handleAuthError(error, 'Failed to logout');
    }
  }

  async function forgotPassword(email: string): Promise<void> {
    try {
      await authService.forgotPassword(email);
    } catch (error: unknown) {
      handleAuthError(error, 'Failed to send password reset email');
    }
  }

  async function updateUserEmail(email: string): Promise<void> {
    try {
      const result = (await authService.updateUserEmail(email)) as IAuthResult;
      handleAuthResult(result, 'Failed to update email');
      setUser(auth.currentUser);
    } catch (error: unknown) {
      handleAuthError(error, 'Failed to update email');
    }
  }

  async function updateUserPassword(password: string): Promise<void> {
    try {
      const result = (await authService.updateUserPassword(
        password,
      )) as IAuthResult;
      handleAuthResult(result, 'Failed to update password');
      setUser(auth.currentUser);
    } catch (error: unknown) {
      handleAuthError(error, 'Failed to update password');
    }
  }

  async function updateUsername(username: string): Promise<void> {
    try {
      const result = (await authService.updateUsername(
        username,
      )) as IAuthResult;
      handleAuthResult(result, 'Failed to update username');
      setUser(auth.currentUser);
    } catch (error: unknown) {
      handleAuthError(error, 'Failed to update username');
    }
  }

  async function toggleDarkMode(): Promise<void> {
    try {
      // Toggle dark mode state locally
      const newDarkMode = !darkMode;
      setDarkMode(newDarkMode);

      // Save to localStorage as a fallback
      try {
        localStorage.setItem('darkMode', String(newDarkMode));
      } catch (localStorageError) {
        // Ignore localStorage errors
      }

      // Only try to update the server if the user is logged in
      if (user) {
        // Update user's preference in the database
        // We use a separate try/catch here to allow local toggling even if server fails
        try {
          await authService.updateDarkModePreference(newDarkMode);
        } catch (error) {
          console.warn('Error updating dark mode preference:', error);
          // We don't revert the state to allow local toggling even if server fails
        }
      }
    } catch (error: unknown) {
      console.error('Unexpected error in toggleDarkMode:', error);
      // Don't throw the error to prevent the UI from breaking
    }
  }

  /**
   * Check if the current user has admin status
   * This uses the Firebase custom claims mechanism
   */
  async function checkAdminStatus(): Promise<boolean> {
    try {
      if (!user) return false;

      const adminStatus = await authService.isUserAdmin();

      // Update local state
      setIsAdmin(adminStatus);

      // Also update user object for components that check it directly
      if (user) {
        user.customClaims = {
          ...user.customClaims,
          admin: adminStatus,
        };
      }

      return adminStatus;
    } catch (error) {
      console.error('Error checking admin status:', error);
      return false;
    }
  }

  /**
   * Force refresh the user's ID token to get latest custom claims
   */
  async function refreshUserClaims(): Promise<void> {
    try {
      if (!user) return;

      // Force refresh the token
      await auth.currentUser?.getIdToken(true);

      // Check admin status with fresh token
      await checkAdminStatus();
    } catch (error) {
      console.error('Error refreshing user claims:', error);
    }
  }

  async function loginAsGuest(): Promise<void> {
    try {
      await authService.loginAsGuest();
    } catch (error: unknown) {
      handleAuthError(error, 'Failed to login as guest');
    }
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (firebaseUser) => {
      // Create a user object that includes our custom properties
      const currentUser = firebaseUser as IUser | null;

      // Fetch user's dark mode preference and admin status if logged in
      if (currentUser) {
        try {
          // Check if user is admin and add to customClaims
          const adminStatus = await authService.isUserAdmin();
          setIsAdmin(adminStatus);

          currentUser.customClaims = {
            ...currentUser.customClaims,
            admin: adminStatus,
          };

          // Get user preferences including dark mode
          const userPreferences = await authService.getUserPreferences();
          if (
            userPreferences &&
            typeof userPreferences.darkMode === 'boolean'
          ) {
            setDarkMode(userPreferences.darkMode);
          }
        } catch (error) {
          // Just log the error and continue - don't let this block the app
          console.warn('Failed to fetch user data:', error);

          // Try to load from localStorage as fallback
          try {
            const savedMode = localStorage.getItem('darkMode');
            if (savedMode !== null) {
              setDarkMode(savedMode === 'true');
            }
          } catch (localStorageError) {
            // Ignore localStorage errors
          }
        }
      } else {
        // Reset admin status when logged out
        setIsAdmin(false);

        // For non-logged in users, try to get preference from localStorage
        try {
          const savedMode = localStorage.getItem('darkMode');
          if (savedMode !== null) {
            setDarkMode(savedMode === 'true');
          }
        } catch (localStorageError) {
          // Ignore localStorage errors
        }
      }

      setUser(currentUser);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value: IAuthContextType = {
    user,
    login,
    logout,
    signup,
    forgotPassword,
    updateUserEmail,
    updateUserPassword,
    updateUsername,
    loginAsGuest,
    darkMode,
    toggleDarkMode,
    checkAdminStatus,
    refreshUserClaims,
    isAdmin,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

// Helper function using authService for profile picture upload
export async function upload(
  file: File,
  user: IUser | null,
  setLoad: React.Dispatch<React.SetStateAction<boolean>>,
): Promise<void> {
  if (!user) {
    console.warn('No user is logged in');
    return;
  }

  setLoad(true);

  try {
    await authService.uploadProfilePicture(file);
    setLoad(false);
  } catch (error: unknown) {
    setLoad(false);
    const errorMessage =
      error instanceof Error ? error.message : 'Unknown error occurred';
    console.warn('Failed to upload profile picture:', errorMessage);
  }
}
