//useEventDisplayContent.ts
import { onValue, ref } from 'firebase/database';
import { useEffect, useState } from 'react';

import { IArticle, IArticleContent } from './constants/eventDisplay.types';
import { db } from '../../../firebase';

export default function useEventDisplayContent(articleId: string): {
  article: IArticle | null;
  content: IArticleContent | null;
} {
  const [article, setArticle] = useState<IArticle | null>(null);
  const [content, setContent] = useState<IArticleContent | null>(null);

  useEffect(() => {
    const articleRef = ref(db, `articles/${articleId}`);
    const unsubscribe = onValue(
      articleRef,
      (snapshot) => {
        const articleData = snapshot.val() as IArticle;
        if (articleData) {
          setArticle(articleData);
          // Access the nested content object directly
          setContent(articleData.content || null);
        }
      },
      (error) => {
        console.error('Error reading data: ', error);
      },
    );
    return () => {
      unsubscribe();
    };
  }, [articleId]);

  return {
    article,
    content,
  };
}
