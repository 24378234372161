import { useState, useCallback } from 'react';

import { IUserProfile, ISocialLink } from './constants/profileScreen.types';
import { useAuth } from '../../../contexts/AuthContext';
import { authService } from '../../../services/authService';

interface IUseProfileInfoEditReturn {
  bio: string;
  setBio: (bio: string) => void;
  socialLinks: ISocialLink[];
  addSocialLink: (platform: ISocialLink['platform']) => void;
  removeSocialLink: (index: number) => void;
  updateSocialLink: (index: number, username: string) => void;
  saveChanges: () => Promise<boolean>;
  isSaving: boolean;
  error: string | null;
}

export const useProfileInfoEdit = (
  userProfile: IUserProfile,
): IUseProfileInfoEditReturn => {
  const { user } = useAuth();
  const [bio, setBio] = useState<string>(userProfile.bio || '');
  const [socialLinks, setSocialLinks] = useState<ISocialLink[]>(
    userProfile.socialLinks || [],
  );
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // Add a new social link
  const addSocialLink = useCallback(
    (platform: ISocialLink['platform']) => {
      // Check if platform already exists
      const exists = socialLinks.some((link) => link.platform === platform);
      if (exists) {
        return; // Don't add duplicate platforms
      }

      setSocialLinks((prev) => [...prev, { platform, username: '' }]);
    },
    [socialLinks],
  );

  // Remove a social link
  const removeSocialLink = useCallback((index: number) => {
    setSocialLinks((prev) => prev.filter((_, i) => i !== index));
  }, []);

  // Update a social link username
  const updateSocialLink = useCallback((index: number, username: string) => {
    setSocialLinks((prev) =>
      prev.map((link, i) => (i === index ? { ...link, username } : link)),
    );
  }, []);

  // Save changes to the profile
  const saveChanges = useCallback(async (): Promise<boolean> => {
    if (!user) {
      setError('You must be logged in to update your profile');
      return false;
    }

    setIsSaving(true);
    setError(null);

    try {
      // Filter out social links with empty usernames
      const validSocialLinks = socialLinks.filter(
        (link) => link.username.trim() !== '',
      );

      // Call the Firebase function to update the user profile
      const result = await authService.updateUserProfile(
        user.uid,
        bio,
        validSocialLinks,
      );

      if (!result.success) {
        throw new Error(result.error || 'Failed to update profile');
      }

      // Update the local userProfile object
      userProfile.bio = bio;
      userProfile.socialLinks = validSocialLinks;

      return true;
    } catch (err) {
      const errorMessage =
        err instanceof Error ? err.message : 'An unknown error occurred';
      setError(errorMessage);
      return false;
    } finally {
      setIsSaving(false);
    }
  }, [user, bio, socialLinks, userProfile]);

  return {
    bio,
    setBio,
    socialLinks,
    addSocialLink,
    removeSocialLink,
    updateSocialLink,
    saveChanges,
    isSaving,
    error,
  };
};
