import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import ArticleCard from './ArticleCard';
import useFeedMainContent from './useFeedMainContent';
import { BrutalistLoading } from '../../../components/SharedComponents/BrutalistUI';
import '../../../App.scss';
import '../../../styles/brutalist.scss';
import './feedMain.scss';

export default function FeedMain(): JSX.Element {
  const { articles, loading } = useFeedMainContent();
  const [expandedArticleId, setExpandedArticleId] = useState<string | null>(
    null,
  );

  // Renamed handler for clarity when passing down
  const handleToggleExpand = (articleId: string) => {
    if (expandedArticleId === articleId) {
      setExpandedArticleId(null); // Collapse if already expanded
    } else {
      setExpandedArticleId(articleId); // Expand this article
    }
  };

  return (
    <Container fluid className='feed-container p-0'>
      <Row>
        <Col>
          <h1 className='feed-title'>LATEST BATTLES</h1>

          {loading ? (
            <BrutalistLoading />
          ) : articles.length > 0 ? (
            <div>
              {articles.map((article) => (
                <ArticleCard
                  key={article.id}
                  article={article}
                  isExpanded={expandedArticleId === article.id}
                  onToggleExpand={handleToggleExpand}
                />
              ))}
            </div>
          ) : (
            <div className='feed-empty'>
              <h3>NO ARTICLES YET</h3>
              <p>Check back soon for updates</p>
              <div
                className='brutalist-primary-background'
                style={{ width: '50px', height: '50px', margin: '0 auto' }}
              />
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
}
