// LoginPage.tsx
import React, { useRef, useState, useEffect } from "react";
import { Form, Alert } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import { useAuth } from "../../../contexts/AuthContext";
import {
  BrutalistButton,
  BrutalistHeading,
  BrutalistCard,
} from "../../SharedComponents/BrutalistUI";
import "../../../styles/brutalist.scss";
import "./preLogin.scss";

// Using CSS classes instead of styled components

const LoginCard = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => (
  <BrutalistCard className="login-card">{children}</BrutalistCard>
);

const LoginForm = ({
  onSubmit,
  emailRef,
  passwordRef,
  rememberMeRef,
}: {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  emailRef: React.RefObject<HTMLInputElement>;
  passwordRef: React.RefObject<HTMLInputElement>;
  rememberMeRef: React.RefObject<HTMLInputElement>;
}): React.ReactElement => (
  <Form onSubmit={onSubmit} className="brutalist-form">
    <Form.Group id="email" className="mb-3">
      <Form.Label className="brutalist-label">EMAIL</Form.Label>
      <Form.Control
        type="email"
        ref={emailRef}
        required
        className="brutalist-form-input"
      />
    </Form.Group>
    <Form.Group id="password" className="mb-3">
      <Form.Label className="brutalist-label">PASSWORD</Form.Label>
      <Form.Control
        type="password"
        ref={passwordRef}
        required
        className="brutalist-form-input"
      />
    </Form.Group>
    <Form.Group id="remember-me" className="mb-4">
      <Form.Check
        type="checkbox"
        label="Keep me logged in for 30 days"
        ref={rememberMeRef}
        className="brutalist-check"
      />
    </Form.Group>
    <BrutalistButton className="w-100 text-center" type="submit">
      LOG IN
    </BrutalistButton>
  </Form>
);

const SignupLink = (): React.ReactElement => (
  <div className="brutalist-signup-link">
    Don&apos;t have an account?{" "}
    <Link to="/signup" className="brutalist-link">
      SIGN UP
    </Link>
  </div>
);

export default function LoginPage(): React.ReactElement {
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const rememberMeRef = useRef<HTMLInputElement>(null);

  const { user, login } = useAuth();
  const [error, setError] = useState("");
  const navigate = useNavigate();

  // Redirect authenticated users appropriately
  useEffect(() => {
    if (user) {
      // If user is anonymous (guest), redirect to welcome screen
      // Otherwise redirect to dashboard
      if (user.isAnonymous) {
        navigate("/welcome");
      } else {
        navigate("/dashboard");
      }
    }
  }, [user, navigate]);

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const email = emailRef.current?.value;
    const password = passwordRef.current?.value;
    const rememberMe = rememberMeRef.current?.checked || false;

    if (!email || !password) {
      setError("Email and password are required");
      return;
    }

    try {
      setError("");
      await login(email, password, rememberMe);
      navigate("/dashboard"); // Redirect directly to dashboard
    } catch (error) {
      setError("Invalid email or password.");
    }
  }

  return (
    <div className="login-page-wrapper">
      <div className="login-card-container">
        <LoginCard>
          <BrutalistHeading
            level="h2"
            className="text-center mb-4 brutalist-rotate-1"
          >
            SIGN IN
          </BrutalistHeading>

          {error && (
            <Alert variant="danger" className="brutalist-alert">
              {error}
            </Alert>
          )}

          <LoginForm
            onSubmit={handleSubmit}
            emailRef={emailRef}
            passwordRef={passwordRef}
            rememberMeRef={rememberMeRef}
          />

          <div className="w-100 text-center mt-4">
            <Link to="/forgot-password" className="brutalist-link">
              FORGOT PASSWORD?
            </Link>
          </div>
        </LoginCard>
        <SignupLink />
      </div>
    </div>
  );
}
