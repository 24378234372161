import React, { useRef, useState } from 'react';
import { FaMoon, FaSun } from 'react-icons/fa';
import { HiChevronDown, HiChevronRight } from 'react-icons/hi';
import { Link, useNavigate } from 'react-router-dom';

import ProfilePicture from './profilePicture';
import { useAuth } from '../../../contexts/AuthContext';
import { IUserSettings } from '../../MainComponents/ProfileScreen/constants/profileScreen.types';
import SettingsSection from '../../MainComponents/ProfileScreen/sections/SettingsSection';
import { BrutalistButton } from '../../SharedComponents/BrutalistUI';
import BrutalistArrowButton from '../../SharedComponents/BrutalistUI/BrutalistArrowButton';

import './profile.scss';

interface IActionButtonProps {
  onClick: () => void;
  children: React.ReactNode;
  variant?: string;
  disabled?: boolean;
}

export default function UpdateProfile(): JSX.Element {
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);

  const {
    logout,
    user,
    updateUserEmail,
    updateUserPassword,
    darkMode,
    toggleDarkMode,
  } = useAuth();

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [cardVisible, setCardVisible] = useState(true);
  const [settingsCardVisible, setSettingsCardVisible] = useState(true);

  // Default user settings - would normally be fetched from backend
  const [userSettings] = useState<IUserSettings>({
    darkMode: darkMode,
    notificationPreferences: {
      emailNotifications: true,
      commentReplies: true,
      mentions: true,
      newFollowers: true,
    },
    privacySettings: {
      profileVisibility: 'public',
      showActivity: true,
      showFollowers: true,
    },
  });

  const toggleCardVisibility = () => {
    setCardVisible((prevState) => !prevState);
  };

  const toggleSettingsCardVisibility = () => {
    setSettingsCardVisible((prevState) => !prevState);
  };

  const navigate = useNavigate();
  const isAnonymous = user?.email === null;

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const email = emailRef.current?.value;
    const password = passwordRef.current?.value;
    const confirmPassword = confirmPasswordRef.current?.value;

    if (password !== confirmPassword) {
      return setError('Passwords do not match');
    }

    const promises = [];

    if (email !== user?.email) {
      promises.push(updateUserEmail(email || ''));
    }
    if (password) {
      promises.push(updateUserPassword(password));
    }

    try {
      setLoading(true);
      await Promise.all(promises);
      navigate('/dashboard');
    } catch {
      setError('Failed to update profile');
    } finally {
      setLoading(false);
    }
  }

  const ActionButton = ({
    onClick,
    children,
    variant = 'primary',
    disabled = false,
  }: IActionButtonProps): JSX.Element => (
    <BrutalistButton variant={variant} onClick={onClick} disabled={disabled}>
      {children}
    </BrutalistButton>
  );

  async function handleLogout() {
    setError('');
    try {
      await logout();
      navigate('/');
    } catch (error) {
      setError('Failed to log out');
    }
  }

  const renderForm = () => (
    <form onSubmit={handleSubmit}>
      <div className='form-group'>
        <label htmlFor='email'>Email</label>
        <input
          type='email'
          id='email'
          ref={emailRef}
          required
          defaultValue={user?.email ? user.email : ''}
        />
      </div>

      <div className='form-group'>
        <label htmlFor='password'>New Password</label>
        <input
          type='password'
          id='password'
          ref={passwordRef}
          placeholder='Leave blank to keep current'
        />
      </div>

      <div className='form-group'>
        <label htmlFor='password-confirm'>Confirm Password</label>
        <input
          type='password'
          id='password-confirm'
          ref={confirmPasswordRef}
          placeholder='Confirm new password'
        />
      </div>

      <BrutalistButton
        disabled={loading}
        variant='primary'
        type='submit'
        style={{ width: '100%' }}
      >
        {loading ? 'Updating...' : 'Update account'}
      </BrutalistButton>
    </form>
  );

  const renderProfileSection = () => (
    <>
      <h2>Account Settings</h2>
      <Link
        to='#'
        onClick={(e) => {
          e.preventDefault();
          toggleCardVisibility();
        }}
        className='toggle-link'
      >
        {cardVisible ? <HiChevronDown /> : <HiChevronRight />}
        Update account information
      </Link>

      {cardVisible && (
        <div className='profile-form-container'>
          {error && <div className='error-message'>{error}</div>}
          {renderForm()}
        </div>
      )}

      <div className='profile-actions'>
        <ActionButton variant='danger' onClick={handleLogout}>
          Log out
        </ActionButton>
      </div>
    </>
  );

  const renderSettingsSection = () => (
    <>
      <h2>Preferences & Privacy</h2>
      <Link
        to='#'
        onClick={(e) => {
          e.preventDefault();
          toggleSettingsCardVisibility();
        }}
        className='toggle-link'
      >
        {settingsCardVisible ? <HiChevronDown /> : <HiChevronRight />}
        Customize settings
      </Link>

      {settingsCardVisible && (
        <div className='settings-section-container'>
          <SettingsSection
            userSettings={userSettings}
            toggleDarkMode={toggleDarkMode}
            darkMode={darkMode}
            handleLogout={handleLogout}
            isOwnProfile={true}
          />
        </div>
      )}
    </>
  );

  return (
    <div className={`profile-container ${darkMode ? 'dark-mode' : ''}`}>
      <BrutalistArrowButton
        direction='left'
        onClick={() => navigate('/dashboard')}
        className='profile-back-button'
      />
      <div className='dark-mode-toggle'>
        <button
          onClick={toggleDarkMode}
          className='dark-mode-button'
          aria-label={darkMode ? 'Switch to light mode' : 'Switch to dark mode'}
        >
          {darkMode ? <FaSun /> : <FaMoon />}
        </button>
      </div>
      <div className='profile-header'>
        <h2>Profile Settings</h2>
      </div>

      {!isAnonymous ? (
        <div className='profile-content'>
          <div className='profile-card'>
            <ProfilePicture />
          </div>
          <div className='profile-card'>{renderProfileSection()}</div>
          <div className='profile-card'>{renderSettingsSection()}</div>
        </div>
      ) : (
        <div className='profile-card'>
          <h2>You are not logged in</h2>
          <p>Please log in or sign up to access your profile settings.</p>
          <div className='profile-actions'>
            <ActionButton onClick={logout}>Sign up now</ActionButton>
          </div>
        </div>
      )}
    </div>
  );
}
