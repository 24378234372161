// commentSystem.styles.ts
import { CSSProperties } from 'react';

export interface IStyles {
  commentWrapper: CSSProperties;
  replyWrapper: CSSProperties;
  rebuttalWrapper: CSSProperties;
  commentText: CSSProperties;
  authorText: CSSProperties;
  upvoteButton: CSSProperties;
  deleteIcon: CSSProperties;
  touchableText: CSSProperties;
  touchableDiv: CSSProperties;
  primaryColor: CSSProperties;
  addReply: CSSProperties;
  replyBox: CSSProperties;
  profilePicture: CSSProperties;
  authorContainer: CSSProperties;
  timestamp: CSSProperties;
  commentTree: CSSProperties;
  commentNode: CSSProperties;
  replyNode: CSSProperties;
  rebuttalNode: CSSProperties;
  commentContainer: CSSProperties;
  replyContainer: CSSProperties;
  rebuttalContainer: CSSProperties;
}

export const styles: IStyles = {
  profilePicture: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    marginRight: '10px',
    objectFit: 'cover',
    border: '2px solid var(--brutalist-gray-800)',
  },
  authorContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
  },
  // Tree structure containers
  commentTree: {
    position: 'relative',
    width: '100%',
  },
  commentNode: {
    position: 'relative',
  },
  replyNode: {
    position: 'relative',
    paddingLeft: '30px',
  },
  rebuttalNode: {
    position: 'relative',
    paddingLeft: '30px',
  },
  // Content containers
  commentContainer: {
    marginBottom: '20px',
    backgroundColor: 'transparent',
    position: 'relative',
  },
  replyContainer: {
    marginBottom: '15px',
    backgroundColor: 'transparent',
    position: 'relative',
  },
  rebuttalContainer: {
    marginBottom: '10px',
    backgroundColor: 'transparent',
    position: 'relative',
  },
  // Wrappers with brutalist minimalist design
  commentWrapper: {
    padding: '15px',
    margin: '10px 0',
    backgroundColor: 'var(--comment-bg)',
    position: 'relative',
    border: '2px solid var(--comment-border)',
    borderLeft: '4px solid var(--comment-accent)',
    paddingBottom: '35px', // Space for action buttons
  },
  replyWrapper: {
    padding: '10px',
    margin: '10px 0',
    backgroundColor: 'var(--reply-bg)',
    position: 'relative',
    border: '2px solid var(--reply-border)',
    paddingBottom: '35px', // Space for action buttons
  },
  rebuttalWrapper: {
    padding: '10px',
    margin: '10px 0',
    backgroundColor: 'var(--rebuttal-bg)',
    position: 'relative',
    border: '2px solid var(--rebuttal-border)',
    paddingBottom: '35px', // Space for action buttons
  },
  commentText: {
    marginBottom: '8px',
    wordBreak: 'break-word',
    color: 'var(--brutalist-text-dark)',
    fontWeight: '400',
  },
  authorText: {
    marginBottom: '5px',
    wordBreak: 'break-word',
    color: 'var(--brutalist-gray-400)',
    fontSize: '0.85em',
    fontWeight: '600',
  },
  upvoteButton: {
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: 'var(--brutalist-gray-400)',
    marginRight: '15px',
    transition: 'color 0.2s',
  },
  deleteIcon: {
    cursor: 'pointer',
    color: 'var(--brutalist-gray-400)',
    position: 'absolute',
    right: '10px',
    top: '10px',
    opacity: '0.7',
    transition: 'opacity 0.2s',
  },
  touchableText: {
    cursor: 'pointer',
    color: 'var(--brutalist-gray-400)',
    background: 'none',
    border: 'none',
    padding: '5px 0',
    fontSize: '0.85em',
    transition: 'color 0.2s',
  },
  touchableDiv: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: '5px',
    position: 'absolute',
    bottom: '10px',
    left: '15px',
  },
  primaryColor: {
    color: 'var(--brutalist-gray-400)',
  },
  addReply: {
    marginLeft: '5px',
    color: 'var(--brutalist-gray-400)',
    fontSize: '0.85em',
  },
  replyBox: {
    marginTop: '10px',
    marginBottom: '8px',
    border: '2px solid var(--comment-border)',
    backgroundColor: 'var(--comment-bg)',
    color: 'var(--brutalist-text-dark)',
  },
  timestamp: {
    fontSize: '0.8em',
    color: 'var(--brutalist-gray-500)',
    marginLeft: '10px',
  },
};
