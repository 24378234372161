// alertsContent.tsx
import { format, fromUnixTime } from 'date-fns';
import { Timestamp } from 'firebase/firestore';
import React from 'react';
import { Col } from 'react-bootstrap';

import '../../../styles/brutalist.scss';
import './alertsContent.scss';
import {
  IFirestoreTimestampJson,
  IFirestoreTimestampObject,
  IFirestoreTimestampWithToMillis,
  IAlert,
} from './constants/alertsContent.types';
import { AlertType } from './constants/alertTypes';
import useAlertsContent from './useAlertsContent';

// Type guard functions
function isFirestoreTimestamp(value: unknown): value is Timestamp {
  return (
    value !== null &&
    typeof value === 'object' &&
    'seconds' in value &&
    'nanoseconds' in value &&
    'toDate' in value &&
    typeof (value as Record<string, unknown>).seconds === 'number' &&
    typeof (value as Record<string, unknown>).nanoseconds === 'number' &&
    typeof (value as Record<string, unknown>).toDate === 'function'
  );
}

function isIFirestoreTimestampObject(
  value: unknown,
): value is IFirestoreTimestampObject {
  return (
    value !== null &&
    typeof value === 'object' &&
    'seconds' in value &&
    'nanoseconds' in value &&
    typeof (value as Record<string, unknown>).seconds === 'number' &&
    typeof (value as Record<string, unknown>).nanoseconds === 'number'
  );
}

function isFirestoreTimestampWithMillis(
  value: unknown,
): value is IFirestoreTimestampWithToMillis {
  return (
    value !== null &&
    typeof value === 'object' &&
    'seconds' in value &&
    'nanoseconds' in value &&
    'toMillis' in value &&
    typeof (value as Record<string, unknown>).seconds === 'number' &&
    typeof (value as Record<string, unknown>).nanoseconds === 'number' &&
    typeof (value as Record<string, unknown>).toMillis === 'function'
  );
}

function isFirestoreTimestampJson(
  value: unknown,
): value is IFirestoreTimestampJson {
  return (
    value !== null &&
    typeof value === 'object' &&
    '_seconds' in value &&
    '_nanoseconds' in value &&
    typeof (value as Record<string, unknown>)._seconds === 'number' &&
    typeof (value as Record<string, unknown>)._nanoseconds === 'number'
  );
}

export default function AlertsContent(): JSX.Element {
  const {
    alerts,
    loading,
    error,
    hasMore,
    markAsRead,
    markAllAsRead,
    loadMoreAlerts,
  } = useAlertsContent();

  const getAlertTypeDisplay = (type: AlertType | string): string => {
    switch (type) {
      case AlertType.WELCOME:
      case 'welcome':
        return 'Welcome';
      case AlertType.ADMIN_CRITICAL:
      case 'admin_critical':
        return 'Critical Alert';
      case AlertType.ADMIN_GENERAL:
      case 'admin_general':
        return 'General Update';
      case AlertType.COMMENT_REPLY:
      case 'comment_reply':
        return 'Comment Reply';
      case AlertType.TRENDING_DISCUSSION:
      case 'trending_discussion':
        return 'Trending';
      case AlertType.BREAKING_NEWS:
      case 'breaking_news':
        return 'Breaking';
      case AlertType.SYSTEM_NOTIFICATION:
      case 'system_notification':
        return 'System';
      default:
        return 'Alert';
    }
  };

  const formatTimestamp = (timestamp: IAlert['timestamp']): string => {
    try {
      let date: Date;
      // Handle different timestamp formats
      if (!timestamp) {
        return 'Unknown date';
      }

      // Use type guards for better type safety
      if (isFirestoreTimestamp(timestamp)) {
        // Firestore Timestamp with toDate method - we know toDate exists because of the type guard
        date = timestamp.toDate();
      } else if (isIFirestoreTimestampObject(timestamp)) {
        // Firestore Timestamp serialized object with seconds
        date = fromUnixTime(timestamp.seconds);
      } else if (isFirestoreTimestampWithMillis(timestamp)) {
        // Firestore timestamp with toMillis - we know toMillis exists because of the type guard
        date = new Date(timestamp.toMillis());
      } else if (isFirestoreTimestampJson(timestamp)) {
        // Firestore timestamp JSON format
        date = fromUnixTime(timestamp._seconds);
      } else if (timestamp instanceof Date) {
        // Date object
        date = timestamp;
      } else if (typeof timestamp === 'number') {
        // Unix timestamp (number)
        date = new Date(timestamp);
      } else {
        return 'Unknown date';
      }

      return format(date, 'MMM d, yyyy h:mm a');
    } catch (err: unknown) {
      console.error('Error formatting timestamp:', err);
      return 'Unknown date';
    }
  };

  const handleMarkAsRead = async (alertId: string) => {
    await markAsRead(alertId);
  };

  const handleMarkAllAsRead = async () => {
    await markAllAsRead();
  };

  const handleLoadMore = async () => {
    await loadMoreAlerts();
  };

  const hasUnreadAlerts = alerts.some((alert) => !alert.read);

  return (
    <Col xs={12} sm={9} md={8} lg={6} xl={6} className='alerts-content'>
      <div className='alerts-header'>
        <h3>Alerts</h3>
        <div className='actions-container'>
          {hasUnreadAlerts && (
            <button className='mark-all-button' onClick={handleMarkAllAsRead}>
              Mark all as read
            </button>
          )}
        </div>
      </div>

      {loading && alerts.length === 0 ? (
        <div className='brutalist-loading'>
          <div className='loading-indicator' />
          <h3>LOADING</h3>
        </div>
      ) : error ? (
        <div className='brutalist-error'>
          <h3>ERROR</h3>
          <p>{error.message}</p>
        </div>
      ) : alerts.length === 0 ? (
        <div className='empty-alerts'>
          <h3>NO ALERTS</h3>
          <p>You do not have any alerts at this time.</p>
          <div
            className='brutalist-primary-background'
            style={{ width: '50px', height: '50px', margin: '0 auto' }}
          />
        </div>
      ) : (
        <>
          <ul className='alerts-list'>
            {alerts.map((alert) => (
              <li
                key={alert.id}
                className={`alert-item ${!alert.read ? 'unread' : ''}`}
              >
                <div className='alert-header'>
                  <span
                    className={`alert-type ${String(alert.type).toLowerCase()}`}
                  >
                    {getAlertTypeDisplay(alert.type)}
                  </span>
                  <span className='alert-date'>
                    {formatTimestamp(alert.timestamp)}
                  </span>
                </div>
                <div className='alert-message'>{alert.message}</div>
                {!alert.read && (
                  <div className='alert-actions'>
                    <button onClick={() => handleMarkAsRead(alert.id)}>
                      Mark as read
                    </button>
                  </div>
                )}
              </li>
            ))}
          </ul>

          {hasMore && (
            <div className='load-more-container'>
              <button onClick={handleLoadMore} disabled={loading}>
                {loading ? 'Loading...' : 'Load More'}
              </button>
            </div>
          )}
        </>
      )}
    </Col>
  );
}
