import React from 'react';
import { Form } from 'react-bootstrap';

import CharacterCounter from './characterCounter';
import {
  BrutalistButton,
  BrutalistLoading,
} from '../../../SharedComponents/BrutalistUI';
import {
  CommentCategory,
  commentCategories,
} from '../__constants__/comment.types'; // Moved import

interface ICommentBoxProps {
  newComment: string;
  setNewComment: (comment: string) => void;
  handleAddComment: () => void;
  isLoading: boolean;
  selectedCategory: CommentCategory; // Add prop for selected category
  setSelectedCategory: (category: CommentCategory) => void; // Add prop for setter
}

const CommentBox: React.FC<ICommentBoxProps> = ({
  newComment,
  setNewComment,
  handleAddComment,
  isLoading,
  selectedCategory, // Destructure new props
  setSelectedCategory, // Destructure new props
}) => {
  // Function to format category names for display
  const formatCategoryName = (category: CommentCategory): string => {
    return category.replace('-', ' ').toUpperCase();
  };

  return (
    <div className='brutalist-comment-box'>
      <Form.Control
        as='textarea'
        value={newComment}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          setNewComment(e.target.value)
        }
        placeholder='Write a comment...'
        maxLength={250}
        rows={3}
        disabled={isLoading}
      />
      <CharacterCounter currentCount={newComment.length} limit={250} />

      {/* Category Selection Buttons */}
      <div className='brutalist-category-selector'>
        <span className='category-label'>Category:</span>
        {commentCategories.map((category) => (
          <button
            key={category}
            type='button'
            className={`brutalist-category-button ${
              selectedCategory === category ? 'selected' : ''
            } category--${category}`}
            onClick={() => setSelectedCategory(category)}
            disabled={isLoading}
          >
            {formatCategoryName(category)}
          </button>
        ))}
      </div>

      {isLoading ? (
        <BrutalistLoading text='SUBMITTING COMMENT' size='small' />
      ) : (
        <BrutalistButton variant='primary' onClick={handleAddComment}>
          Comment
        </BrutalistButton>
      )}
    </div>
  );
};

export default CommentBox;
