import React from 'react';
import { ImCross } from 'react-icons/im';
import { styled } from 'styled-components';

import { BrutalistButton } from '../../../SharedComponents/BrutalistUI';

interface IConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const DialogOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const DialogContent = styled.div`
  background: var(--comment-bg, black);
  padding: 20px;
  border: 1px solid var(--comment-border, #333);
  max-width: 400px;
  width: 90%;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  color: var(--brutalist-gray-500, #999);
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: var(--brutalist-dark-text, #fff);
  }
`;

const DialogTitle = styled.h3`
  margin: 0 0 20px 0;
  color: var(--brutalist-text-dark, #fff);
  padding-right: 30px;
  font-size: 1.1rem;
  border-bottom: 1px solid var(--comment-border, #333);
  padding-bottom: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  margin-top: 20px;
`;

export const ConfirmationDialog: React.FC<IConfirmationDialogProps> = ({
  open,
  onClose,
  onConfirm,
}) => {
  if (!open) return null;

  return (
    <DialogOverlay onClick={onClose}>
      <DialogContent
        onClick={(e) => e.stopPropagation()}
        className='confirmation-dialog'
      >
        <CloseButton onClick={onClose}>
          <ImCross size={12} />
        </CloseButton>
        <DialogTitle>Are you sure you want to delete this?</DialogTitle>
        <ButtonContainer>
          <BrutalistButton variant='secondary' onClick={onClose}>
            Cancel
          </BrutalistButton>
          <BrutalistButton variant='primary' onClick={onConfirm}>
            Delete
          </BrutalistButton>
        </ButtonContainer>
      </DialogContent>
    </DialogOverlay>
  );
};
