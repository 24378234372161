import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import {
  BsFillHouseDoorFill,
  BsPeopleFill,
  BsFillBellFill,
  BsThreeDotsVertical,
} from 'react-icons/bs';
import { CgProfile } from 'react-icons/cg';
import { MdSettings, MdAdminPanelSettings } from 'react-icons/md';
import { RiVideoAddLine } from 'react-icons/ri';
import { SiInternetarchive } from 'react-icons/si';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { AlertCounter } from './AlertCounter';
import '../../../../../App.scss';
import '../../../../../styles/brutalist.scss';
import './navbar.scss';
import { useAuth } from '../../../../../contexts/AuthContext';
import { IUser } from '../../../../../contexts/types';
import { ActiveComponentType, useDashboard } from '../../dashboardProvider';

// Type definitions
interface ILogoutBtnProps {
  onLogout: () => void;
}

interface INavItemProps {
  icon: React.ReactNode;
  label: string;
  to: string;
  isActive: boolean;
  onClick?: () => void;
  showCounter?: boolean;
}

// Helper components
const NavItem = ({
  icon,
  label,
  to,
  isActive,
  onClick,
  showCounter,
}: INavItemProps) => (
  <li className={`nav-item ${isActive ? 'active' : ''}`}>
    <Link to={to} onClick={onClick}>
      <div style={{ position: 'relative' }}>
        {icon}
        {showCounter && <AlertCounter />}
      </div>
      <span className='d-lg-inline-block d-none'>{label}</span>
    </Link>
  </li>
);

const LogoutBtn = ({ onLogout }: ILogoutBtnProps) => (
  <button className='brutalist-logout-btn' onClick={onLogout}>
    SIGN IN
  </button>
);

const BrandLogo = () => (
  <div className='navbar-header'>
    <Link to='/dashboard'>
      <span className='dark-mode-neg'>NEG</span>
      <span style={{ color: 'var(--brutalist-primary)' }}>NAN</span>
    </Link>
  </div>
);

interface IProfileSectionProps {
  user: IUser | null;
  navigate: (path: string) => void;
}

const ProfileSection = ({ user, navigate }: IProfileSectionProps) => {
  if (!user) return null;

  return (
    <div className='navbar-profile brutalist-bg-light'>
      <button
        onClick={() => navigate('/profile')}
        aria-label='Navigate to profile'
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: '0 auto',
        }}
      >
        <img
          src={
            user.photoURL ||
            require('../../../../../components/assets/anonymous.jpg')
          }
          alt={`${user.displayName || 'User'}'s profile`}
          className='navbar-profile-img'
        />
        <span className='navbar-profile-username' style={{ marginTop: '8px' }}>
          {user.displayName || user.email}
        </span>
      </button>
    </div>
  );
};

interface IMoreMenuProps {
  isOpen: boolean;
  isAdmin: boolean;
  setMenuOpen: (isOpen: boolean) => void;
  setActiveComponent: React.Dispatch<React.SetStateAction<ActiveComponentType>>;
  isComponentActive: (component: string) => boolean;
  isActive: (path: string) => boolean;
}

const MoreMenu = ({
  isOpen,
  isAdmin,
  setMenuOpen,
  setActiveComponent,
  isComponentActive,
  isActive,
}: IMoreMenuProps) => (
  <div className={`hamburger-menu-dropdown ${isOpen ? 'show' : ''}`}>
    <NavItem
      icon={<SiInternetarchive />}
      label='Archive'
      to='#'
      isActive={isComponentActive('archive')}
      onClick={() => {
        setActiveComponent('archive');
        setMenuOpen(false);
      }}
    />
    <NavItem
      icon={<CgProfile />}
      label='Profile'
      to='/profile'
      isActive={isActive('/profile')}
      onClick={() => setMenuOpen(false)}
    />
    <NavItem
      icon={<MdSettings />}
      label='Settings'
      to='/update-profile'
      isActive={isActive('/update-profile')}
      onClick={() => setMenuOpen(false)}
    />
    {isAdmin && (
      <NavItem
        icon={<MdAdminPanelSettings />}
        label='Portal'
        to='/admin'
        isActive={isActive('/admin')}
        onClick={() => setMenuOpen(false)}
      />
    )}
  </div>
);

interface IMainNavigationProps {
  user: IUser | null;
  menuOpen: boolean;
  toggleMenu: () => void;
  isComponentActive: (component: string) => boolean;
  setActiveComponent: React.Dispatch<React.SetStateAction<ActiveComponentType>>;
  isActive: (path: string) => boolean;
  isAdmin: boolean;
  setMenuOpen: (isOpen: boolean) => void;
}

const MainNavigation = ({
  user,
  menuOpen,
  toggleMenu,
  isComponentActive,
  setActiveComponent,
  isActive,
  isAdmin,
  setMenuOpen,
}: IMainNavigationProps) => (
  <nav>
    <ul className='list-unstyled'>
      <NavItem
        icon={<BsFillHouseDoorFill />}
        label='Home'
        to='/dashboard'
        isActive={isComponentActive('main')}
        onClick={() => setActiveComponent('main')}
      />

      {user && !user.isAnonymous && (
        <>
          <NavItem
            icon={<BsFillBellFill />}
            label='Alerts'
            to='#'
            isActive={isComponentActive('alerts')}
            onClick={() => setActiveComponent('alerts')}
            showCounter={true}
          />
          <NavItem
            icon={<BsPeopleFill />}
            label='Following'
            to='#'
            isActive={isComponentActive('followers')}
            onClick={() => setActiveComponent('followers')}
          />
          <NavItem
            icon={<RiVideoAddLine />}
            label='Negs'
            to='#'
            isActive={false}
          />

          <li className={`nav-item ${menuOpen ? 'menu-active' : ''}`}>
            <Link
              to='#'
              onClick={(e) => {
                e.preventDefault();
                toggleMenu();
              }}
            >
              <BsThreeDotsVertical />
              <span className='d-lg-inline-block d-none'>More</span>
            </Link>
          </li>

          <MoreMenu
            isOpen={menuOpen}
            isAdmin={isAdmin}
            setMenuOpen={setMenuOpen}
            setActiveComponent={setActiveComponent}
            isComponentActive={isComponentActive}
            isActive={isActive}
          />
        </>
      )}
    </ul>
  </nav>
);

export default function NavbarFull(): JSX.Element {
  // Hooks
  const { logout, user, isAdmin } = useAuth();
  const { setActiveComponent } = useDashboard();
  const navigate = useNavigate();
  const location = useLocation();

  // State
  const [error, setError] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);

  // Handlers
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const isActive = (path: string) => {
    return location.pathname === path;
  };

  const isComponentActive = (component: string) => {
    return (
      location.pathname === '/dashboard' &&
      ((component === 'main' && !location.hash) ||
        location.hash === `#${component}`)
    );
  };

  async function handleLogout() {
    setError('');
    try {
      await logout();
      navigate('/');
    } catch (err) {
      setError('Failed to Log Out');
    }
  }

  // Render
  return (
    <Container fluid className='navbar-full brutalist-navbar p-0'>
      {error && <div className='brutalist-alert m-3'>{error}</div>}

      <BrandLogo />

      <MainNavigation
        user={user}
        menuOpen={menuOpen}
        toggleMenu={toggleMenu}
        isComponentActive={isComponentActive}
        setActiveComponent={setActiveComponent}
        isActive={isActive}
        isAdmin={isAdmin}
        setMenuOpen={setMenuOpen}
      />

      {!user || user.isAnonymous ? (
        <div className='logout-container'>
          <LogoutBtn onLogout={handleLogout} />
        </div>
      ) : (
        <ProfileSection user={user} navigate={navigate} />
      )}
    </Container>
  );
}
