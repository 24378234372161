import { getFunctions, httpsCallable } from 'firebase/functions';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import React, { useState, useEffect } from 'react';
import { Button, Form, Modal, Spinner, Tab, Tabs } from 'react-bootstrap';

import {
  IArticle,
  IArticleContent,
  ICreateArticleRequest,
  IUpdateArticleRequest,
} from './types';

const MainInfoTab: React.FC<{
  title: string;
  setTitle: (value: string) => void;
  author: string;
  setAuthor: (value: string) => void;
  content: IArticleContent;
  handleContentChange: (key: string, value: string) => void;
  bulletPointsText: string;
  setBulletPointsText: (value: string) => void;
  image: string;
  handleImageChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  weight: string;
  setWeight: (value: string) => void;
}> = ({
  title,
  setTitle,
  author,
  setAuthor,
  content,
  handleContentChange,
  bulletPointsText,
  setBulletPointsText,
  image,
  handleImageChange,
  weight,
  setWeight,
}) => (
  <>
    <Form.Group className='mb-3'>
      <Form.Label>Title *</Form.Label>
      <Form.Control
        type='text'
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder='Article title'
        required
      />
    </Form.Group>

    <Form.Group className='mb-3'>
      <Form.Label>Author</Form.Label>
      <Form.Control
        type='text'
        value={author}
        onChange={(e) => setAuthor(e.target.value)}
        placeholder='Author name'
      />
    </Form.Group>

    <Form.Group className='mb-3'>
      <Form.Label>Main Content (Event) *</Form.Label>
      <Form.Control
        as='textarea'
        rows={5}
        value={content.event || ''}
        onChange={(e) => handleContentChange('event', e.target.value)}
        placeholder='Main article content'
        required
      />
      <Form.Text className='text-muted'>
        This is the main content that will be displayed in the center of the
        article.
      </Form.Text>
    </Form.Group>

    <Form.Group className='mb-3'>
      <Form.Label>Bullet Points</Form.Label>
      <Form.Control
        as='textarea'
        rows={4}
        value={bulletPointsText}
        onChange={(e) => setBulletPointsText(e.target.value)}
        placeholder='Enter key takeaways, one per line.'
      />
      <Form.Text className='text-muted'>
        Each line will be displayed as a bullet point in the expanded article
        view.
      </Form.Text>
    </Form.Group>

    <Form.Group className='mb-3'>
      <Form.Label>Thumbnail Image</Form.Label>
      {image && (
        <div className='mb-2'>
          <img
            src={image}
            alt='Thumbnail preview'
            style={{ maxWidth: '100%', maxHeight: '200px' }}
          />
        </div>
      )}
      <Form.Control type='file' accept='image/*' onChange={handleImageChange} />
      <Form.Text className='text-muted'>
        Upload a thumbnail image for the article.
      </Form.Text>
    </Form.Group>

    <Form.Group className='mb-3'>
      <Form.Label>Weight</Form.Label>
      <Form.Control
        type='number'
        value={weight}
        onChange={(e) => setWeight(e.target.value)}
        min='0'
        max='100'
      />
      <Form.Text className='text-muted'>
        Controls the article&#39;s position in listings. Higher values appear
        first.
      </Form.Text>
    </Form.Group>
  </>
);

const PerspectivesTab: React.FC<{
  content: IArticleContent;
  handleContentChange: (key: string, value: string) => void;
}> = ({ content, handleContentChange }) => (
  <>
    <Form.Group className='mb-3'>
      <Form.Label>Left Perspective</Form.Label>
      <Form.Control
        as='textarea'
        rows={4}
        value={content.left || ''}
        onChange={(e) => handleContentChange('left', e.target.value)}
        placeholder='Left perspective content'
      />
    </Form.Group>

    <Form.Group className='mb-3'>
      <Form.Label>Left-Center Perspective</Form.Label>
      <Form.Control
        as='textarea'
        rows={4}
        value={content.leftCenter || ''}
        onChange={(e) => handleContentChange('leftCenter', e.target.value)}
        placeholder='Left-center perspective content'
      />
    </Form.Group>

    <Form.Group className='mb-3'>
      <Form.Label>Right-Center Perspective</Form.Label>
      <Form.Control
        as='textarea'
        rows={4}
        value={content.rightCenter || ''}
        onChange={(e) => handleContentChange('rightCenter', e.target.value)}
        placeholder='Right-center perspective content'
      />
    </Form.Group>

    <Form.Group className='mb-3'>
      <Form.Label>Right Perspective</Form.Label>
      <Form.Control
        as='textarea'
        rows={4}
        value={content.right || ''}
        onChange={(e) => handleContentChange('right', e.target.value)}
        placeholder='Right perspective content'
      />
    </Form.Group>
  </>
);

const SeoTab: React.FC<{
  seoTitle: string;
  setSeoTitle: (value: string) => void;
  seoDescription: string;
  setSeoDescription: (value: string) => void;
  seoKeywords: string;
  setSeoKeywords: (value: string) => void;
}> = ({
  seoTitle,
  setSeoTitle,
  seoDescription,
  setSeoDescription,
  seoKeywords,
  setSeoKeywords,
}) => (
  <>
    <Form.Group className='mb-3'>
      <Form.Label>SEO Title</Form.Label>
      <Form.Control
        type='text'
        value={seoTitle}
        onChange={(e) => setSeoTitle(e.target.value)}
        placeholder='SEO optimized title (if different from main title)'
      />
      <Form.Text className='text-muted'>
        If left empty, the main title will be used.
      </Form.Text>
    </Form.Group>

    <Form.Group className='mb-3'>
      <Form.Label>SEO Description</Form.Label>
      <Form.Control
        as='textarea'
        rows={3}
        value={seoDescription}
        onChange={(e) => setSeoDescription(e.target.value)}
        placeholder='Meta description for search engines'
      />
      <Form.Text className='text-muted'>
        A short description (150-160 characters) that appears in search results.
      </Form.Text>
    </Form.Group>

    <Form.Group className='mb-3'>
      <Form.Label>SEO Keywords</Form.Label>
      <Form.Control
        type='text'
        value={seoKeywords}
        onChange={(e) => setSeoKeywords(e.target.value)}
        placeholder='Comma-separated keywords'
      />
      <Form.Text className='text-muted'>
        Enter keywords separated by commas (e.g., news, politics, economy).
      </Form.Text>
    </Form.Group>
  </>
);

const emptyContent: IArticleContent = {
  event: '',
  left: '',
  leftCenter: '',
  rightCenter: '',
  right: '',
};

interface IArticleFormProps {
  show: boolean;
  onHide: () => void;
  article: IArticle | null;
}

const uploadImageToStorage = async (
  storage: ReturnType<typeof getStorage>,
  imageFile: File,
): Promise<string> => {
  try {
    const filePath = `article_images/${Date.now()}_${imageFile.name}`;
    const storageRef = ref(storage, filePath);
    await uploadBytes(storageRef, imageFile);
    return await getDownloadURL(storageRef);
  } catch (err) {
    console.error('Error uploading image:', err);
    throw new Error('Failed to upload image. Please try again.');
  }
};

const parseKeywords = (keywordsString: string): string[] => {
  return keywordsString
    .split(',')
    .map((keyword) => keyword.trim())
    .filter((keyword) => keyword.length > 0);
};

const parseBulletPoints = (text: string): string[] => {
  return text
    .split('\n')
    .map((line) => line.trim())
    .filter((line) => line.length > 0);
};

const ArticleForm: React.FC<IArticleFormProps> = ({
  show,
  onHide,
  article,
}) => {
  const isEditing = !!article;
  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [content, setContent] = useState<IArticleContent>(emptyContent);
  const [bulletPointsText, setBulletPointsText] = useState('');
  const [image, setImage] = useState('');
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [weight, setWeight] = useState('0');
  const [seoTitle, setSeoTitle] = useState('');
  const [seoDescription, setSeoDescription] = useState('');
  const [seoKeywords, setSeoKeywords] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState('main');

  const functions = getFunctions();
  const storage = getStorage();
  const createArticleFunction = httpsCallable(functions, 'createArticle');
  const updateArticleFunction = httpsCallable(functions, 'updateArticle');

  useEffect(() => {
    if (article) {
      setTitle(article.title || '');
      setAuthor(article.author || '');
      setContent({
        event: article.content?.event || '',
        left: article.content?.left || '',
        leftCenter: article.content?.leftCenter || '',
        rightCenter: article.content?.rightCenter || '',
        right: article.content?.right || '',
      });
      setBulletPointsText(article.bulletPoints?.join('\n') || '');
      setImage(article.image || '');
      setWeight(article.weight || '0');
      setSeoTitle(article.seoTitle || '');
      setSeoDescription(article.seoDescription || '');
      setSeoKeywords(article.seoKeywords?.join(', ') || '');
    } else {
      resetForm();
    }
  }, [article]);

  const resetForm = () => {
    setTitle('');
    setAuthor('');
    setContent(emptyContent);
    setBulletPointsText('');
    setImage('');
    setImageFile(null);
    setWeight('0');
    setSeoTitle('');
    setSeoDescription('');
    setSeoKeywords('');
    setError('');
  };

  const handleContentChange = (key: string, value: string) => {
    setContent((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      setImageFile(files[0]);
      const previewUrl = URL.createObjectURL(files[0]);
      setImage(previewUrl);
    }
  };

  const validateForm = (): boolean => {
    if (!title.trim() || !content.event?.trim()) {
      setError('Title and main content are required.');
      return false;
    }
    return true;
  };

  const prepareArticleData = async (): Promise<
    ICreateArticleRequest | IUpdateArticleRequest
  > => {
    let imageUrl = image;
    if (imageFile) {
      imageUrl = await uploadImageToStorage(storage, imageFile);
    }

    const keywordsArray = parseKeywords(seoKeywords);
    const bulletPointsArray = parseBulletPoints(bulletPointsText);

    return {
      title,
      author,
      image: imageUrl,
      weight,
      content,
      bulletPoints: bulletPointsArray,
      seoTitle: seoTitle || title,
      seoDescription,
      seoKeywords: keywordsArray,
    };
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      if (!validateForm()) {
        setLoading(false);
        return;
      }

      const articleData = await prepareArticleData();

      if (isEditing && article) {
        await updateArticleFunction({
          articleId: article.id,
          updates: articleData,
        });
      } else {
        await createArticleFunction(articleData);
      }

      onHide();
      resetForm();
    } catch (err) {
      setError(
        `Failed to ${isEditing ? 'update' : 'create'} article: ${
          err instanceof Error ? err.message : 'Unknown error'
        }`,
      );
      console.error('Error submitting article:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size='lg'
      backdrop='static'
      keyboard={false}
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditing ? 'Edit Article' : 'Create New Article'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <div className='alert alert-danger'>{error}</div>}

          <Tabs
            activeKey={activeTab}
            onSelect={(k) => k && setActiveTab(k)}
            className='mb-3'
          >
            <Tab eventKey='main' title='Main Info'>
              <MainInfoTab
                title={title}
                setTitle={setTitle}
                author={author}
                setAuthor={setAuthor}
                content={content}
                handleContentChange={handleContentChange}
                bulletPointsText={bulletPointsText}
                setBulletPointsText={setBulletPointsText}
                image={image}
                handleImageChange={handleImageChange}
                weight={weight}
                setWeight={setWeight}
              />
            </Tab>

            <Tab eventKey='perspectives' title='Perspectives'>
              <PerspectivesTab
                content={content}
                handleContentChange={handleContentChange}
              />
            </Tab>

            <Tab eventKey='seo' title='SEO'>
              <SeoTab
                seoTitle={seoTitle}
                setSeoTitle={setSeoTitle}
                seoDescription={seoDescription}
                setSeoDescription={setSeoDescription}
                seoKeywords={seoKeywords}
                setSeoKeywords={setSeoKeywords}
              />
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={onHide} disabled={loading}>
            Cancel
          </Button>
          <Button variant='primary' type='submit' disabled={loading}>
            {loading ? (
              <>
                <Spinner
                  as='span'
                  animation='border'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                  className='me-2'
                />
                {isEditing ? 'Saving...' : 'Creating...'}
              </>
            ) : isEditing ? (
              'Save Changes'
            ) : (
              'Create Article'
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ArticleForm;
