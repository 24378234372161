import React from 'react';

interface ICharacterCounterProps {
  currentCount: number;
  limit: number;
}

const CharacterCounter: React.FC<ICharacterCounterProps> = ({
  currentCount,
  limit,
}) => {
  const isNearLimit = currentCount >= limit * 0.8;

  return (
    <div className={`character-count ${isNearLimit ? 'warning' : ''}`}>
      {currentCount}/{limit}
    </div>
  );
};

export default CharacterCounter;
