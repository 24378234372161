import React from 'react';

import {
  IUserActivity,
  IUserBadges,
  IUserEngagement,
  IUserProfile,
} from './constants/profileScreen.types';
import {
  BadgesSection,
  EngagementSection,
  SectionHeader,
  UserInfoSection,
} from './sections';

interface IProfileSectionsProps {
  userProfile: IUserProfile;
  userActivity: IUserActivity;
  userEngagement: IUserEngagement;
  userBadges: IUserBadges;
  activeTab: string;
  setActiveTab: (tab: string) => void;
  isEditMode: boolean;
  toggleEditMode: () => void;
  handleLogout: () => Promise<void>;
  toggleDarkMode: () => Promise<void>;
  darkMode: boolean;
  expandedSections: Record<string, boolean>;
  toggleSection: (section: string) => void;
  isLoading?: boolean; // New prop for loading state
}

export const ProfileSections: React.FC<IProfileSectionsProps> = ({
  userProfile,
  userActivity,
  userEngagement,
  userBadges,
  activeTab,
  setActiveTab,
  isEditMode,
  toggleEditMode,
  expandedSections,
  toggleSection,
  isLoading = false, // Default to false if not provided
}) => {
  return (
    <div className='profile-content'>
      {/* 1. User Information Section */}
      <div className='profile-section'>
        <SectionHeader
          title='Profile Information'
          isExpanded={expandedSections.userInfo}
          toggleSection={() => toggleSection('userInfo')}
        />

        {expandedSections.userInfo && (
          <UserInfoSection
            userProfile={userProfile}
            userActivity={userActivity}
            isEditMode={isEditMode}
            toggleEditMode={toggleEditMode}
            isLoading={isLoading}
          />
        )}
      </div>

      {/* 3. User Engagement & Content */}
      <div className='profile-section'>
        <SectionHeader
          title='Engagement & Content'
          isExpanded={expandedSections.engagement}
          toggleSection={() => toggleSection('engagement')}
        />

        {expandedSections.engagement && userEngagement && (
          <EngagementSection
            userEngagement={userEngagement}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}
      </div>

      {/* 5. Additional Features */}
      <div className='profile-section'>
        <SectionHeader
          title='Badges & Reputation'
          isExpanded={expandedSections.badges}
          toggleSection={() => toggleSection('badges')}
        />

        {expandedSections.badges && userBadges && (
          <BadgesSection
            userBadges={userBadges}
            isOwnProfile={userProfile.isOwnProfile}
          />
        )}
      </div>
    </div>
  );
};
