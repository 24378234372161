// PrivateRoute.tsx
import React, { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuth, IAuthContextType } from '../contexts/AuthContext';

interface IPrivateRouteProps {
  children: ReactElement;
}

function PrivateRoute({ children }: IPrivateRouteProps): JSX.Element {
  const auth: IAuthContextType | null = useAuth();
  const user = auth?.user ?? null;

  // Directly return children if user exists; otherwise navigate to home page
  return user ? children : <Navigate to='/' />;
}

export default PrivateRoute;
