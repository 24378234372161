import React, { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuth } from '../contexts/AuthContext';

/**
 * AdminRoute component - A route that requires the user to be logged in AND have admin privileges
 * Redirects to dashboard if not logged in or not admin
 */
interface IAdminRouteProps {
  children: ReactElement;
}

export default function AdminRoute({
  children,
}: IAdminRouteProps): JSX.Element {
  const { user, isAdmin } = useAuth();

  // Check if user is logged in and has admin privileges
  if (!user || user.isAnonymous || !isAdmin) {
    return <Navigate to='/dashboard' />;
  }

  // Directly return children to avoid react/jsx-no-useless-fragment warning
  return children;
}
