import React from 'react';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';

interface ISectionHeaderProps {
  title: string;
  isExpanded: boolean;
  toggleSection: () => void;
}

const SectionHeader: React.FC<ISectionHeaderProps> = ({
  title,
  isExpanded,
  toggleSection,
}) => {
  return (
    <div className='section-header'>
      <h2>{title}</h2>
      <button
        className='toggle-button'
        onClick={toggleSection}
        aria-label={isExpanded ? 'Collapse section' : 'Expand section'}
      >
        {isExpanded ? <FaChevronDown /> : <FaChevronRight />}
      </button>
    </div>
  );
};

export default SectionHeader;
