// SignupForm.tsx
import React, { useState } from "react";
import { Alert, Button, Form, InputGroup } from "react-bootstrap";

import TermsOfServiceModal from "./TermsOfServiceModal";
import {
  BrutalistButton,
  BrutalistHeading,
  BrutalistCard,
} from "../../SharedComponents/BrutalistUI";
import "../../../styles/brutalist.scss";
import "./preLogin.scss";

export default function SignupForm({
  onSubmit,
  emailRef,
  passwordRef,
  confirmPasswordRef,
  usernameRef,
  termsRef,
  error,
  loading,
}: {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  emailRef: React.RefObject<HTMLInputElement>;
  passwordRef: React.RefObject<HTMLInputElement>;
  confirmPasswordRef: React.RefObject<HTMLInputElement>;
  usernameRef: React.RefObject<HTMLInputElement>;
  termsRef: React.RefObject<HTMLInputElement>;
  error: string;
  loading: boolean;
}): React.ReactElement {
  const [showPassword, setShowPassword] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const openTermsModal = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowTermsModal(true);
  };

  return (
    <BrutalistCard className="signup-card">
      <BrutalistHeading
        level="h2"
        className="text-center mb-4 brutalist-rotate-1"
      >
        SIGN UP
      </BrutalistHeading>

      {error && (
        <Alert variant="danger" className="brutalist-alert">
          {error}
        </Alert>
      )}

      <Form onSubmit={onSubmit} className="brutalist-form">
        <Form.Group id="username" className="mb-3">
          <Form.Label className="brutalist-label">USERNAME</Form.Label>
          <Form.Control
            type="text"
            ref={usernameRef}
            required
            className="brutalist-form-input"
          />
        </Form.Group>

        <Form.Group id="email" className="mb-3">
          <Form.Label className="brutalist-label">EMAIL</Form.Label>
          <Form.Control
            type="email"
            ref={emailRef}
            required
            className="brutalist-form-input"
          />
        </Form.Group>

        <Form.Group id="password" className="mb-3">
          <Form.Label className="brutalist-label">PASSWORD</Form.Label>
          <InputGroup>
            <Form.Control
              type={showPassword ? "text" : "password"}
              ref={passwordRef}
              required
              className="brutalist-form-input"
            />
            <InputGroup.Text className="brutalist-input-group-text">
              <Button
                className="brutalist-toggle-button"
                onClick={toggleShowPassword}
              >
                {showPassword ? "HIDE" : "SHOW"}
              </Button>
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>

        <Form.Group id="password-confirm" className="mb-4">
          <Form.Label className="brutalist-label">CONFIRM PASSWORD</Form.Label>
          <InputGroup>
            <Form.Control
              type={showPassword ? "text" : "password"}
              ref={confirmPasswordRef}
              required
              className="brutalist-form-input"
            />
            <InputGroup.Text className="brutalist-input-group-text">
              <Button
                className="brutalist-toggle-button"
                onClick={toggleShowPassword}
              >
                {showPassword ? "HIDE" : "SHOW"}
              </Button>
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>

        <Form.Group id="terms-check" className="mb-4">
          <Form.Check
            type="checkbox"
            ref={termsRef}
            required
            className="brutalist-check"
            label={
              <span>
                I agree to the{" "}
                <button
                  type="button"
                  onClick={openTermsModal}
                  className="brutalist-link-button"
                >
                  TERMS OF SERVICE
                </button>
              </span>
            }
          />
        </Form.Group>

        <BrutalistButton
          disabled={loading}
          className="w-100 text-center"
          type="submit"
        >
          {loading ? "CREATING ACCOUNT..." : "CREATE ACCOUNT"}
        </BrutalistButton>
      </Form>

      <TermsOfServiceModal
        show={showTermsModal}
        onHide={() => setShowTermsModal(false)}
      />
    </BrutalistCard>
  );
}
