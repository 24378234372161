/* eslint-disable react/no-unknown-property */
// LandingPage.tsx
import { logEvent } from "@firebase/analytics";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { collection, query, where, getDocs } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { Card, Container, Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  AppleLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";

import UpdateUsernameForm from "./UpdateUsernameForm";
import { useAlert } from "../../../contexts/AlertContext";
import { useAuth } from "../../../contexts/AuthContext";
import { fsdb } from "../../../firebase";
import {
  BrutalistButton,
  BrutalistHeading,
} from "../../SharedComponents/BrutalistUI";
import "./preLogin.scss";

const LogoTitle = (): React.ReactElement => (
  <div className="brutalist-logo">
    <BrutalistHeading level="h1" variant="light" className="d-inline">
      Neg
    </BrutalistHeading>
    <BrutalistHeading level="h1" variant="primary" className="d-inline">
      nan
    </BrutalistHeading>
  </div>
);

const EmailLoginButton = ({
  onClick,
}: {
  onClick: () => void;
}): React.ReactElement => {
  const handleClick = () => {
    logEvent(getAuth(), "login", { method: "email" });
    onClick();
  };

  return (
    <BrutalistButton className="w-100" onClick={handleClick}>
      Join the Discussion
    </BrutalistButton>
  );
};

const GuestLoginLink = ({
  onClick,
}: {
  onClick: () => void;
}): React.ReactElement => (
  <div className="mx-auto landing-page-guest-link">
    Continue without logging in?{" "}
    <Link to="#" onClick={onClick} className="guest-link">
      [GUEST MODE]
    </Link>
  </div>
);

// Earth component is now in the PreLoginLayout

export default function LandingPage(): React.ReactElement {
  const [showUsernameForm, setShowUsernameForm] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();
  const { user, loginAsGuest } = useAuth();
  const { showAlert } = useAlert();
  const googleAuthProvider = new GoogleAuthProvider();

  // Redirect logged in users appropriately
  useEffect(() => {
    if (user) {
      // If user is anonymous (guest), redirect to welcome screen
      // Otherwise redirect to dashboard
      if (user.isAnonymous) {
        navigate("/welcome");
      } else {
        navigate("/dashboard");
      }
    }
  }, [user, navigate]);

  async function handleGuestLogin() {
    try {
      await loginAsGuest();
      logEvent(auth, "login", { method: "guest" });
      navigate("/welcome"); // Guest users need to see the welcome screen
    } catch (error) {
      showAlert("Failed to log in as a guest.");
    }
  }

  function handleEmail() {
    navigate("/login");
  }

  // This code signs the user in with a Google account.
  // It uses the Google Sign-in API to get a Google credential,
  // and then it signs the user in using that credential.
  // It then redirects the user to the home page.
  async function handleGoogleSignIn() {
    try {
      const result = await signInWithPopup(auth, googleAuthProvider);
      const user = result.user;
      if (user.email) {
        const displayNamesRef = collection(fsdb, "displayNames");
        const q = query(displayNamesRef, where("email", "==", user.email));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
          setShowUsernameForm(true);
        } else {
          const doc = querySnapshot.docs[0];
          const data = doc.data();
          if (data.username && data.email === user.email) {
            navigate("/dashboard");
          } else {
            setShowUsernameForm(true);
          }
        }
      } else {
        setShowUsernameForm(true);
      }
    } catch (error: unknown) {
      interface IFirebaseError {
        code: string;
        message: string;
      }
      const googleErrorCode = (error as IFirebaseError).code;
      const googleErrorMessage = (error as IFirebaseError).message;
      showAlert(googleErrorCode + ": " + googleErrorMessage);
    }
  }

  const handleUsernameUpdated = () => {
    navigate("/dashboard");
  };

  return showUsernameForm ? (
    <Container className="mt-5">
      <UpdateUsernameForm onUsernameUpdated={handleUsernameUpdated} />
    </Container>
  ) : (
    <div className="landing-page-wrapper">
      <Container fluid className="p-0">
        <Row className="justify-content-center">
          <Col xs={12} sm={10} md={8} lg={6}>
            <Card className="text-center landing-page-card card-fixed-width">
              <Card.Body>
                <h2 className="mb-4">
                  <LogoTitle />
                </h2>
                <div className="brutalist-buttons">
                  <EmailLoginButton onClick={handleEmail} />
                  <GoogleLoginButton
                    onClick={handleGoogleSignIn}
                    className="brutalist-social-button"
                    style={{
                      background: "var(--brutalist-background)",
                      color: "var(--brutalist-text-light)",
                      border: "4px solid var(--brutalist-text-light)",
                      borderRadius: 0,
                      fontSize: "1.2rem",
                      fontWeight: 800,
                      textTransform: "uppercase",
                      marginBottom: "15px",
                    }}
                  />
                  <AppleLoginButton
                    className="brutalist-social-button"
                    style={{
                      background: "var(--brutalist-text-light)",
                      color: "var(--brutalist-text-dark)",
                      border: "4px solid var(--brutalist-text-dark)",
                      borderRadius: 0,
                      fontSize: "1.2rem",
                      fontWeight: 800,
                      textTransform: "uppercase",
                    }}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <GuestLoginLink onClick={handleGuestLogin} />
      </Container>
    </div>
  );
}
