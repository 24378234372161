import { ref, onValue } from 'firebase/database';
import React, { useEffect, useMemo, useState, useCallback } from 'react'; // Added useCallback
import { Carousel } from 'react-responsive-carousel';
import { useParams, useNavigate } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import useEventDisplayContent from './useEventDisplayContent';
import { auth, db } from '../../../firebase';
import {
  BrutalistArrowButton,
  BrutalistLoading,
} from '../../SharedComponents/BrutalistUI';
import Tooltip from '../../SharedComponents/InfoTooltip/tooltip';
import { CommentSystem } from '../CommentSystem/commentSystem';
import './eventDisplay.scss';

// Helper function to detect mobile devices
const isMobileDevice = () => {
  return (
    typeof window !== 'undefined' &&
    (window.innerWidth <= 768 ||
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      ))
  );
};

export default function EventDisplay(): JSX.Element {
  const { articleId, urlSlug } = useParams<{
    articleId: string;
    urlSlug: string;
  }>();
  const [loading, setLoading] = useState(true);
  const [articleIdToUse, setArticleIdToUse] = useState<string>('');
  const { article, content } = useEventDisplayContent(articleIdToUse);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const [showFullEventContent, setShowFullEventContent] = useState(false); // State for event content toggle

  const displayName = auth.currentUser?.displayName || 'Anonymous';

  // Handle URL resolution - either by articleId or by urlSlug
  useEffect(() => {
    // If we have an articleId directly, use it
    if (articleId && !urlSlug) {
      setArticleIdToUse(articleId);
      setLoading(false);
      return;
    }

    // If we have a urlSlug, look up the article by its slug
    if (urlSlug) {
      // Query Firebase for the article with this slug
      const _articlesRef = ref(db, 'articles');
      const query = ref(db, 'articles');

      // Use the orderByChild query to find the article by urlSlug
      onValue(
        query,
        (snapshot) => {
          let foundArticleId = '';
          snapshot.forEach((childSnapshot) => {
            const article = childSnapshot.val();
            if (article.urlSlug === urlSlug) {
              foundArticleId = article.id;
              return true; // Stop iteration
            }
            return false; // Continue iteration
          });

          if (foundArticleId) {
            setArticleIdToUse(foundArticleId);
          } else {
            console.error(`No article found with slug: ${urlSlug}`);
          }
          setLoading(false);
        },
        {
          onlyOnce: true,
        },
      );
    } else {
      setLoading(false);
    }
  }, [articleId, urlSlug]);

  const contentKeys = useMemo(
    () => ['left', 'leftCenter', 'event', 'rightCenter', 'right'],
    [],
  );

  const [selectedItem, setSelectedItem] = useState(
    contentKeys.findIndex((key) => key === 'event'),
  );

  // Reset full content view when carousel item changes away from 'event'
  useEffect(() => {
    if (contentKeys[selectedItem] !== 'event') {
      setShowFullEventContent(false);
    }
  }, [selectedItem, contentKeys]);

  // Handler for toggling the full event content
  const handleToggleEventContent = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation(); // Prevent carousel interaction
      setShowFullEventContent((prev) => !prev);
    },
    [],
  );

  // Underscore prefix indicates an intentionally unused variable
  const [_showComments, setShowComments] = useState(true);

  // Check if device is mobile on component mount and window resize
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(isMobileDevice());
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      switch (event.key) {
        case 'ArrowLeft':
          setSelectedItem((current) => (current > 0 ? current - 1 : current));
          break;
        case 'ArrowRight':
          setSelectedItem((current) =>
            current < contentKeys.length - 1 ? current + 1 : current,
          );
          break;
        case 'ArrowDown':
          setShowComments(true);
          break;
        case 'ArrowUp':
          setShowComments(false);
          setSelectedItem(contentKeys.findIndex((key) => key === 'event'));
          break;
        default:
          break;
      }
    }

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [contentKeys, contentKeys.length]);

  if (loading) {
    return <BrutalistLoading text='LOADING EVENT' size='medium' />;
  }

  if (!articleIdToUse) {
    return <div>Error: Article not found. The URL may be incorrect.</div>;
  }

  if (!article) {
    return <BrutalistLoading text='LOADING EVENT' size='medium' />;
  }

  return (
    <div className='event-display__wrapper'>
      {/* Main Content Section */}
      <div className='event-display__content-section'>
        <div className='event-display__header'>
          <BrutalistArrowButton
            direction='left'
            onClick={() => navigate('/dashboard')}
            className='event-display__back-button'
          />
        </div>

        <div className='event-display__container'>
          <div className='event-display__carousel-container'>
            <div className='event-display__tooltip'>
              <Tooltip componentName='eventDisplay' />
            </div>

            {/* Only show navigation arrows on non-mobile devices */}
            {!isMobile && (
              <BrutalistArrowButton
                direction='left'
                onClick={() =>
                  setSelectedItem((current) =>
                    current > 0 ? current - 1 : current,
                  )
                }
                className='event-display__nav-button event-display__nav-button--left'
                isNav={true}
              />
            )}

            <Carousel
              showArrows={false}
              showStatus={false}
              showThumbs={false}
              selectedItem={selectedItem}
              swipeable={true}
              emulateTouch={true}
              infiniteLoop={false}
              useKeyboardArrows={true}
              onChange={(index) => setSelectedItem(index)}
            >
              {contentKeys.map((key) => {
                const hasBulletPoints =
                  article?.bulletPoints && article.bulletPoints.length > 0;

                return (
                  <div key={key} className='event-display__carousel-item'>
                    {key === 'event' && article ? (
                      // New layout for the 'event' perspective
                      <div className='event-display__event-layout'>
                        <div className='event-display__event-flex-container'>
                          {article.image ? (
                            <div className='event-display__event-thumbnail'>
                              <img src={article.image} alt={article.title} />
                            </div>
                          ) : (
                            <div className='event-display__event-no-thumbnail'>
                              <div className='event-display__event-placeholder' />
                            </div>
                          )}
                          <div className='event-display__event-content'>
                            {hasBulletPoints && (
                              <ul className='event-display__event-bullet-points'>
                                {article.bulletPoints?.map((point, index) => (
                                  // eslint-disable-next-line react/no-array-index-key
                                  <li key={`${point}-${index}`}>{point}</li>
                                ))}
                              </ul>
                            )}
                            {hasBulletPoints && (
                              <button
                                className='brutalist-view-more-less event-display__view-more-less'
                                onClick={handleToggleEventContent}
                              >
                                {showFullEventContent
                                  ? 'View Less ▲'
                                  : 'View More ▼'}
                              </button>
                            )}
                          </div>
                        </div>
                        {/* Main Content (Event) - shown if no bullets or if 'View More' is clicked */}
                        {(!hasBulletPoints || showFullEventContent) && (
                          <div className='event-display__event-main-content'>
                            {content && content.event
                              ? content.event
                              : 'No event details available'}
                          </div>
                        )}
                        <p className='perspective-label'>{key}</p>
                      </div>
                    ) : (
                      // Original layout for other perspectives
                      <>
                        <p>
                          {content && content[key]
                            ? content[key]
                            : 'No content available'}
                        </p>
                        <p className='perspective-label'>{key}</p>
                      </>
                    )}
                  </div>
                );
              })}
            </Carousel>

            {/* Only show navigation arrows on non-mobile devices */}
            {!isMobile && (
              <BrutalistArrowButton
                direction='right'
                onClick={() =>
                  setSelectedItem((current) =>
                    current < contentKeys.length - 1 ? current + 1 : current,
                  )
                }
                className='event-display__nav-button event-display__nav-button--right'
                isNav={true}
              />
            )}
          </div>
        </div>
      </div>

      {/* Always visible Comments Section */}
      <div className='event-display__comments-section'>
        <div className='event-display__comments'>
          <CommentSystem articleId={articleIdToUse} displayName={displayName} />
        </div>
      </div>
    </div>
  );
}
