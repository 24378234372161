/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-unknown-property */
import { useLoader, useThree, ThreeEvent } from '@react-three/fiber';
import React, { useState, useEffect } from 'react';
import { TextureLoader, Mesh } from 'three';

import EarthLights from '../../assets/earthLights.jpg';

interface IEarthProps {
  // Define the properties of the props object
}

export default function Earth(props: IEarthProps): JSX.Element {
  const { gl } = useThree();
  const [meshRef, setMeshRef] = useState<Mesh | null>(null);

  const [nightMap] = useLoader(TextureLoader, [EarthLights]);
  const [isDragging, setIsDragging] = useState(false);
  const [lastX, setLastX] = useState(0);

  const handlePointerDown = (event: ThreeEvent<PointerEvent>) => {
    setIsDragging(true);
    setLastX(event.nativeEvent.clientX);
  };

  const handlePointerUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    const animate = () => {
      if (meshRef) {
        meshRef.rotation.y += 0.0001; // Adjust the rotation speed here
      }
      requestAnimationFrame(animate);
    };
    animate();
  }, [meshRef]);

  useEffect(() => {
    const handlePointerMove = (event: PointerEvent) => {
      if (isDragging) {
        const deltaX = event.clientX - lastX;
        if (meshRef) {
          meshRef.rotation.y += deltaX * 0.01;
        }
        setLastX(event.clientX);
      }
    };

    if (gl.domElement) {
      gl.domElement.addEventListener('pointermove', handlePointerMove);
      gl.domElement.addEventListener('pointerup', handlePointerUp);
      gl.domElement.addEventListener('pointercancel', handlePointerUp);
    }

    return () => {
      if (gl.domElement) {
        gl.domElement.removeEventListener('pointermove', handlePointerMove);
        gl.domElement.removeEventListener('pointerup', handlePointerUp);
        gl.domElement.removeEventListener('pointercancel', handlePointerUp);
      }
    };
  }, [gl.domElement, isDragging, lastX, meshRef]);

  return (
    <>
      {/* Grid lines for brutalist effect */}
      <mesh position={[0, 0, -11]} rotation={[0, 0, 0]}>
        <planeGeometry args={[100, 100, 10, 10]} />
        <meshBasicMaterial
          color='#ff3e00'
          wireframe={true}
          transparent={true}
          opacity={0.3}
        />
      </mesh>

      {/* Earth sphere */}
      <mesh
        {...props}
        ref={(mesh) => setMeshRef(mesh)}
        position={[0, 0, -10]} // Adjust the Earth model's position
        scale={1.8} // Increase the scale size
        onPointerDown={handlePointerDown}
      >
        <sphereGeometry attach='geometry' args={[5, 32, 32]} />
        <meshStandardMaterial
          map={nightMap}
          roughnessMap={nightMap}
          attach='material'
          emissive='#ff3e00'
          emissiveIntensity={0.15}
        />
      </mesh>

      {/* Additional brutalist geometric elements */}
      <mesh position={[15, 15, -15]} rotation={[Math.PI / 4, Math.PI / 4, 0]}>
        <boxGeometry args={[3, 3, 3]} />
        <meshBasicMaterial color='#ff3e00' wireframe={true} />
      </mesh>

      <mesh position={[-15, -15, -15]} rotation={[Math.PI / 3, Math.PI / 5, 0]}>
        <tetrahedronGeometry args={[4, 0]} />
        <meshBasicMaterial color='#ffffff' wireframe={true} />
      </mesh>
    </>
  );
}
