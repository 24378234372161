import React from 'react';
import './BrutalistUI.scss';

type HeadingLevel = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
type ColorVariant = 'light' | 'dark' | 'primary';

interface IBrutalistHeadingProps {
  level?: HeadingLevel;
  variant?: ColorVariant;
  children: React.ReactNode;
  className?: string;
  rotate?: 1 | 2 | 3;
}

const BrutalistHeading: React.FC<IBrutalistHeadingProps> = ({
  level = 'h2',
  variant = 'dark',
  children,
  className = '',
  rotate,
}) => {
  const HeadingTag = level;

  let variantClass = '';
  switch (variant) {
    case 'light':
      variantClass = 'brutalist-title-light';
      break;
    case 'dark':
      variantClass = 'brutalist-title-dark';
      break;
    case 'primary':
      variantClass = 'brutalist-title-primary';
      break;
  }

  const rotateClass = rotate ? `brutalist-rotate-${rotate}` : '';

  return (
    <HeadingTag
      className={`brutalist-title ${variantClass} ${rotateClass} ${className}`}
    >
      {children}
    </HeadingTag>
  );
};

export default BrutalistHeading;
