import { httpsCallable } from 'firebase/functions';
import React, { useState } from 'react';
import {
  Alert as BootstrapAlert,
  Button,
  Card,
  Form,
  Spinner,
} from 'react-bootstrap';

import { AlertType } from './types';
import { useAuth } from '../../../contexts/AuthContext';
import { functions } from '../../../firebase';

// Define function outside component to prevent recreation on each render
const deleteAdminAlertsFunction = httpsCallable(functions, 'deleteAdminAlerts');

// Component for deleting admin alerts
const DeleteAdminAlerts: React.FC = () => {
  const { user } = useAuth();
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedType, setSelectedType] = useState<string>('all');
  const [alertState, setAlertState] = useState<{
    show: boolean;
    variant: 'success' | 'danger';
    message: string;
  }>({
    show: false,
    variant: 'success',
    message: '',
  });

  const handleTypeChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    setSelectedType(e.target.value);
  };

  const handleDelete = async () => {
    if (!user) return;

    setIsDeleting(true);
    setAlertState({ show: false, variant: 'success', message: '' });

    try {
      // Prepare request payload
      const payload = {
        adminId: user.uid,
        // Only include type if a specific one is selected
        ...(selectedType !== 'all' && {
          type: selectedType as
            | AlertType.ADMIN_GENERAL
            | AlertType.ADMIN_CRITICAL,
        }),
      };

      const result = await deleteAdminAlertsFunction(payload);
      const data = result.data as {
        success: boolean;
        message: string;
        data?: { deletedCount: number };
        error?: string;
      };

      if (data.success) {
        setAlertState({
          show: true,
          variant: 'success',
          message:
            data.message ||
            `${data.data?.deletedCount || 0} alerts deleted successfully`,
        });
      } else {
        throw new Error(
          data.error || data.message || 'Failed to delete alerts',
        );
      }
    } catch (error: unknown) {
      console.error('Error deleting admin alerts:', error);
      setAlertState({
        show: true,
        variant: 'danger',
        message:
          error instanceof Error
            ? error.message
            : 'An error occurred while deleting alerts',
      });
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Card className='mb-4'>
      <Card.Header>
        <h4>Delete Admin Alerts</h4>
      </Card.Header>
      <Card.Body>
        {alertState.show && (
          <BootstrapAlert
            variant={alertState.variant}
            dismissible
            onClose={() => setAlertState((prev) => ({ ...prev, show: false }))}
          >
            {alertState.message}
          </BootstrapAlert>
        )}

        <Form>
          <Form.Group className='mb-3'>
            <Form.Label>Select Alert Type to Delete</Form.Label>
            <Form.Select
              value={selectedType}
              onChange={handleTypeChange}
              aria-label='Select alert type to delete'
            >
              <option value='all'>All Admin Alerts</option>
              <option value={AlertType.ADMIN_GENERAL}>
                General Updates Only
              </option>
              <option value={AlertType.ADMIN_CRITICAL}>
                Critical Alerts Only
              </option>
            </Form.Select>
          </Form.Group>

          <div className='d-flex justify-content-between align-items-center mt-4'>
            <div className='text-muted'>
              <small>
                Warning: This action will delete all admin alerts of the
                selected type and cannot be undone.
              </small>
            </div>
            <Button
              variant='danger'
              onClick={handleDelete}
              disabled={isDeleting}
            >
              {isDeleting ? (
                <>
                  <Spinner
                    as='span'
                    animation='border'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                    className='me-2'
                  />
                  Deleting...
                </>
              ) : (
                'Delete Alerts'
              )}
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default DeleteAdminAlerts;
