// alertTypes.ts
// Mirror of the server-side AlertType enum
import { Timestamp } from 'firebase/firestore';

import {
  IFirestoreTimestampJson,
  IFirestoreTimestampObject,
} from './alertsContent.types';

export enum AlertType {
  WELCOME = 'welcome',
  ADMIN_CRITICAL = 'admin_critical',
  ADMIN_GENERAL = 'admin_general',
  COMMENT_REPLY = 'comment_reply',
  TRENDING_DISCUSSION = 'trending_discussion',
  BREAKING_NEWS = 'breaking_news',
  SYSTEM_NOTIFICATION = 'system_notification',
}

export interface IAlert {
  id: string;
  type: AlertType | string;
  message: string;
  timestamp:
    | Timestamp
    | Date
    | number
    | IFirestoreTimestampJson
    | IFirestoreTimestampObject; // All possible timestamp formats
  read: boolean;
  userId: string;
  metadata?: Record<string, unknown>;
}
