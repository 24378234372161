import React, { createContext, useContext, useState } from 'react';

export type ActiveComponentType = 'main' | 'archive' | 'alerts' | 'followers';

interface IDashboardContextProps {
  activeComponent: ActiveComponentType;
  setActiveComponent: React.Dispatch<React.SetStateAction<ActiveComponentType>>;
}

const DashboardContext = createContext<IDashboardContextProps | undefined>(
  undefined,
);

export const useDashboard = (): IDashboardContextProps => {
  const context = useContext(DashboardContext);
  if (!context) {
    throw new Error('useDashboard must be used within a DashboardProvider');
  }
  return context;
};

interface IDashboardProviderProps {
  children: React.ReactNode;
}

export const DashboardProvider = ({
  children,
}: IDashboardProviderProps): JSX.Element => {
  const [activeComponent, setActiveComponent] =
    useState<ActiveComponentType>('main');

  return (
    <DashboardContext.Provider value={{ activeComponent, setActiveComponent }}>
      {children}
    </DashboardContext.Provider>
  );
};
