//navbarMini.tsx
import React, { useState } from 'react';
import {
  BsFillHouseDoorFill,
  BsPeopleFill,
  BsFillBellFill,
  BsThreeDotsVertical,
} from 'react-icons/bs';
import { CgProfile } from 'react-icons/cg';
import { MdSettings, MdAdminPanelSettings } from 'react-icons/md';
import { RiVideoAddLine } from 'react-icons/ri';
import { SiInternetarchive } from 'react-icons/si';
import { Link, useLocation } from 'react-router-dom';

import { AlertCounter } from './AlertCounter';
import { useAuth } from '../../../../../contexts/AuthContext';
import { useDashboard } from '../../dashboardProvider';
import '../../../../../App.scss';
import '../../../../../styles/brutalist.scss';
import './navbar.scss';

export default function NavbarMini(): JSX.Element {
  const { setActiveComponent } = useDashboard();
  const { isAdmin } = useAuth();
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const isActive = (path: string) => {
    return location.pathname === path;
  };

  const isComponentActive = (component: string) => {
    return (
      location.pathname === '/dashboard' &&
      ((component === 'main' && !location.hash) ||
        location.hash === `#${component}`)
    );
  };

  return (
    <div className='navbar-mini brutalist-navbar'>
      <div className={`nav-item ${isComponentActive('main') ? 'active' : ''}`}>
        <Link to='/dashboard' onClick={() => setActiveComponent('main')}>
          <BsFillHouseDoorFill />
          <span>Home</span>
        </Link>
      </div>

      <div
        className={`nav-item ${isComponentActive('alerts') ? 'active' : ''}`}
      >
        <Link to='#' onClick={() => setActiveComponent('alerts')}>
          <div style={{ position: 'relative' }}>
            <BsFillBellFill />
            <AlertCounter />
          </div>
          <span>Alerts</span>
        </Link>
      </div>

      <div className='nav-item'>
        <Link to='#'>
          <RiVideoAddLine />
          <span>Negs</span>
        </Link>
      </div>

      <div
        className={`nav-item ${isComponentActive('followers') ? 'active' : ''}`}
      >
        <Link to='#' onClick={() => setActiveComponent('followers')}>
          <BsPeopleFill />
          <span>Following</span>
        </Link>
      </div>

      <div className={`nav-item ${menuOpen ? 'menu-active' : ''}`}>
        <Link
          to='#'
          onClick={(e) => {
            e.preventDefault();
            toggleMenu();
          }}
        >
          <BsThreeDotsVertical />
          <span>More</span>
        </Link>
      </div>

      {menuOpen && (
        <div className='navbar-mini-dropdown'>
          <div
            className={`nav-item ${isComponentActive('archive') ? 'active' : ''}`}
          >
            <Link
              to='#'
              onClick={() => {
                setActiveComponent('archive');
                setMenuOpen(false);
              }}
            >
              <SiInternetarchive />
              <span>Archive</span>
            </Link>
          </div>

          <div className={`nav-item ${isActive('/profile') ? 'active' : ''}`}>
            <Link to='/profile' onClick={() => setMenuOpen(false)}>
              <CgProfile />
              <span>Profile</span>
            </Link>
          </div>

          <div
            className={`nav-item ${isActive('/update-profile') ? 'active' : ''}`}
          >
            <Link to='/update-profile' onClick={() => setMenuOpen(false)}>
              <MdSettings />
              <span>Settings</span>
            </Link>
          </div>

          {/* Admin Portal - Only visible to admins */}
          {/* Debug logging removed for production */}
          {isAdmin && (
            <div className={`nav-item ${isActive('/admin') ? 'active' : ''}`}>
              <Link to='/admin' onClick={() => setMenuOpen(false)}>
                <MdAdminPanelSettings />
                <span>Portal</span>
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
