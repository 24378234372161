import React, { ReactNode } from 'react';
import './BrutalistUI.scss';

interface IBrutalistCardProps {
  title?: string;
  children: ReactNode;
  className?: string;
}

const BrutalistCard: React.FC<IBrutalistCardProps> = ({
  title,
  children,
  className = '',
}) => {
  return (
    <div className={`brutalist-card ${className}`}>
      {title && <h3 className='brutalist-section-title'>{title}</h3>}
      <div className='brutalist-card-content'>{children}</div>
    </div>
  );
};

export default BrutalistCard;
