import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

import defaultAvatar from '../../../assets/anonymous.jpg';
import {
  BrutalistButton,
  BrutalistLoading,
} from '../../../SharedComponents/BrutalistUI';
import { FollowButton, FollowModal, SocialMediaLinks } from '../components';
import { IUserActivity, IUserProfile } from '../constants/profileScreen.types';
import { useFollowModal } from '../useFollowModal';
import { useFollowSystem } from '../useFollowSystem';
import { useProfileInfoEdit } from '../useProfileInfoEdit';

interface IUserInfoSectionProps {
  userProfile: IUserProfile;
  userActivity: IUserActivity;
  isEditMode: boolean;
  toggleEditMode: () => void;
  isLoading?: boolean;
}

const UserInfoSection: React.FC<IUserInfoSectionProps> = ({
  userProfile,
  userActivity,
  isEditMode,
  toggleEditMode,
  isLoading = false, // Default to false if not provided
}) => {
  const navigate = useNavigate(); // Initialize useNavigate

  // Handler for clicking the profile picture
  const handleProfilePictureClick = () => {
    if (userProfile.isOwnProfile) {
      navigate('/update-profile'); // Navigate to update-profile route
    }
    // Do nothing if it's not the user's own profile
  };

  const {
    bio,
    setBio,
    socialLinks,
    addSocialLink,
    removeSocialLink,
    updateSocialLink,
    saveChanges,
    isSaving,
    error: profileError,
  } = useProfileInfoEdit(userProfile);

  const handleSave = async () => {
    const success = await saveChanges();
    if (success) {
      toggleEditMode();
    }
  };

  // Format username to ensure it starts with @
  const formattedUsername = userProfile.username.startsWith('@')
    ? userProfile.username
    : `@${userProfile.username}`;

  const formattedJoinDate = userProfile.joinDate.toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const {
    isFollowing,
    isLoading: followLoading,
    followersCount,
    followingCount,
    followUser,
    unfollowUser,
    setInitialCounts,
    error: followError,
  } = useFollowSystem({
    profileId: userProfile.isOwnProfile ? '' : userProfile.userId || '',
    isOwnProfile: userProfile.isOwnProfile,
  });

  const {
    showFollowersModal,
    showFollowingModal,
    followers,
    following,
    isLoadingFollowers,
    isLoadingFollowing,
    openFollowersModal,
    openFollowingModal,
    closeFollowersModal,
    closeFollowingModal,
  } = useFollowModal({
    profileId: userProfile.userId || '',
  });

  // Set initial counts from userActivity
  React.useEffect(() => {
    setInitialCounts(userActivity.followers, userActivity.following);
  }, [userActivity.followers, userActivity.following, setInitialCounts]);

  return (
    <div className='section-content user-info-section'>
      <div className='profile-header-container'>
        {userProfile.isOwnProfile ? (
          <button
            onClick={handleProfilePictureClick}
            className='user-avatar-button' // Add a class for styling if needed
            aria-label='Update profile picture'
          >
            <img
              src={userProfile.photoURL || defaultAvatar}
              alt={`${userProfile.displayName}'s profile`}
              className='user-avatar'
            />
          </button>
        ) : (
          <img
            src={userProfile.photoURL || defaultAvatar}
            alt={`${userProfile.displayName}'s profile`}
            className='user-avatar'
          />
        )}
        <div className='user-activity-stats'>
          {isLoading ? (
            <BrutalistLoading text='LOADING STATS' size='small' />
          ) : (
            <>
              <div className='activity-stat-row'>
                <div className='activity-stat'>
                  <div className='stat-value'>
                    {userActivity.commentsPosted}
                  </div>
                  <div className='stat-label'>Comments</div>
                </div>
                <div className='activity-stat'>
                  <div className='stat-value'>{userActivity.likesReceived}</div>
                  <div className='stat-label'>Likes</div>
                </div>
              </div>
              <div className='activity-stat-row'>
                <button
                  className='activity-stat'
                  onClick={openFollowersModal}
                  aria-label={`View ${followersCount} followers`}
                >
                  <div className='stat-value'>{followersCount}</div>
                  <div className='stat-label'>Followers</div>
                </button>
                <button
                  className='activity-stat'
                  onClick={openFollowingModal}
                  aria-label={`View ${followingCount} following`}
                >
                  <div className='stat-value'>{followingCount}</div>
                  <div className='stat-label'>Following</div>
                </button>
              </div>
            </>
          )}
        </div>
      </div>

      {/* Follow Modals */}
      <FollowModal
        show={showFollowersModal}
        onHide={closeFollowersModal}
        users={followers}
        title={`${userProfile.displayName}'s Followers`}
        isLoading={isLoadingFollowers}
      />

      <FollowModal
        show={showFollowingModal}
        onHide={closeFollowingModal}
        users={following}
        title={`${userProfile.displayName} is Following`}
        isLoading={isLoadingFollowing}
      />
      <div className='user-details'>
        <div className='user-info-main'>
          <h3 className='display-name'>{userProfile.displayName}</h3>
          <p className='username'>{formattedUsername}</p>
        </div>
        {isEditMode ? (
          <div className='bio-edit'>
            <label htmlFor='bio-input'>Bio:</label>
            <textarea
              id='bio-input'
              value={bio}
              onChange={(e) => setBio(e.target.value)}
              placeholder='Tell us about yourself...'
              className='bio-textarea'
              rows={4}
            />
          </div>
        ) : (
          <p className='bio'>{bio}</p>
        )}
        <p className='join-date'>Joined: {formattedJoinDate}</p>
        <SocialMediaLinks
          links={socialLinks}
          isEditMode={isEditMode}
          onUpdate={updateSocialLink}
          onRemove={removeSocialLink}
          onAdd={addSocialLink}
        />
        {profileError && <p className='error-message'>{profileError}</p>}
        {userProfile.isOwnProfile ? (
          <div className='profile-actions'>
            {isEditMode ? (
              <>
                <BrutalistButton
                  onClick={handleSave}
                  className='save-profile-button'
                  btnSize='small'
                  disabled={isSaving}
                >
                  {isSaving ? 'Saving...' : 'Save Changes'}
                </BrutalistButton>
                <BrutalistButton
                  onClick={toggleEditMode}
                  className='cancel-edit-button'
                  btnSize='small'
                  disabled={isSaving}
                >
                  Cancel
                </BrutalistButton>
              </>
            ) : (
              <BrutalistButton
                onClick={toggleEditMode}
                className='edit-profile-button'
                btnSize='small'
              >
                Update Information
              </BrutalistButton>
            )}
          </div>
        ) : (
          <FollowButton
            isFollowing={isFollowing}
            isOwnProfile={userProfile.isOwnProfile}
            onFollow={followUser}
            onUnfollow={unfollowUser}
            isLoading={followLoading}
            error={followError}
          />
        )}
      </div>
    </div>
  );
};

export default UserInfoSection;
