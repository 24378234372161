/* eslint-disable react/no-unknown-property */
// SignupScreen.tsx
import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import SignupForm from "./signupForm";
import { useAuth } from "../../../contexts/AuthContext";
import "../../../styles/brutalist.scss";
import "./preLogin.scss";

// Using CSS classes instead of styled components

function SignupScreen(): React.ReactElement {
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);
  const usernameRef = useRef<HTMLInputElement>(null);
  const termsRef = useRef<HTMLInputElement>(null);

  const { user, signup } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Redirect authenticated users appropriately
  useEffect(() => {
    if (user) {
      // If user is anonymous (guest), redirect to welcome screen
      // Otherwise redirect to dashboard
      if (user.isAnonymous) {
        navigate("/welcome");
      } else {
        navigate("/dashboard");
      }
    }
  }, [user, navigate]);

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const email = emailRef.current?.value;
    const password = passwordRef.current?.value;
    const confirmPassword = confirmPasswordRef.current?.value;
    const username = usernameRef.current?.value;
    const termsAgreed = termsRef.current?.checked;

    if (!username) {
      return setError("Username must be provided");
    }

    if (password !== confirmPassword) {
      return setError("Passwords do not match");
    }

    if (!email || !password) {
      return setError("Email and password must be provided");
    }

    if (!termsAgreed) {
      return setError("You must agree to the Terms of Service");
    }

    try {
      setError("");
      setLoading(true);
      await signup(email, password, username);
      navigate("/dashboard");
    } catch (error: unknown) {
      interface IFirebaseError {
        message?: string;
      }
      setError(
        (error as IFirebaseError).message || "Failed to create an account",
      );
    }
    setLoading(false);
  }

  return (
    <div className="login-page-wrapper">
      <div className="login-card-container">
        <SignupForm
          onSubmit={handleSubmit}
          emailRef={emailRef}
          passwordRef={passwordRef}
          confirmPasswordRef={confirmPasswordRef}
          usernameRef={usernameRef}
          termsRef={termsRef}
          error={error}
          loading={loading}
        />

        <div className="brutalist-signup-link">
          Already have an account?{" "}
          <Link to="/login" className="brutalist-link">
            LOG IN
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SignupScreen;
