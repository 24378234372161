import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Re-using the local IArticle type from the hook for simplicity,
// ideally this would be shared from a common types location.
interface IArticle {
  id: string;
  title: string;
  author: string;
  date: string;
  weight: string;
  image: string;
  urlSlug?: string;
  content: {
    event: string;
    [key: string]: string;
  };
  bulletPoints?: string[];
  [key: string]: unknown;
}

interface IArticleCardProps {
  article: IArticle;
  isExpanded: boolean;
  onToggleExpand: (articleId: string) => void;
}

const ArticleCard: React.FC<IArticleCardProps> = ({
  article,
  isExpanded,
  onToggleExpand,
}) => {
  const navigate = useNavigate();
  const [showFullContent, setShowFullContent] = useState(false);

  // Reset showFullContent state when the card is collapsed
  useEffect(() => {
    if (!isExpanded) {
      setShowFullContent(false);
    }
  }, [isExpanded]);

  const handleToggleContent = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation(); // Prevent card collapse/expand when clicking button
    setShowFullContent(!showFullContent);
  };

  const handleViewFullDetails = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation(); // Prevent card collapse/expand
    if (article.urlSlug) {
      navigate(`/event/article/${article.urlSlug}`);
    } else {
      navigate(`/event/${article.id}`);
    }
  };

  const handleCardClick = () => {
    onToggleExpand(article.id);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      handleCardClick();
    }
  };

  const hasBulletPoints =
    article.bulletPoints && article.bulletPoints.length > 0;

  return (
    <div
      className={`brutalist-feed-card ${isExpanded ? 'expanded' : ''}`}
      onClick={handleCardClick}
      onKeyDown={handleKeyDown}
      role='button'
      tabIndex={0}
    >
      <div className='brutalist-card-content-wrapper'>
        <h2 className='brutalist-card-title'>{article.title}</h2>

        {isExpanded && (
          <div className='brutalist-card-expanded-content'>
            <div className='brutalist-card-flex-container'>
              {article.image ? (
                <div className='brutalist-card-thumbnail expanded'>
                  <img src={article.image} alt={article.title} />
                </div>
              ) : (
                <div className='brutalist-card-no-thumbnail'>
                  <div className='brutalist-card-placeholder' />
                </div>
              )}
              <div className='brutalist-card-content'>
                {hasBulletPoints && (
                  <ul className='brutalist-bullet-points'>
                    {article.bulletPoints?.map((point, index) => (
                      // Use a combination of point and index for a more stable key
                      // eslint-disable-next-line react/no-array-index-key
                      <li key={`${point}-${index}`}>{point}</li>
                    ))}
                  </ul>
                )}

                {hasBulletPoints && (
                  <button
                    className='brutalist-view-more-less'
                    onClick={handleToggleContent}
                  >
                    {showFullContent ? 'View Less ▲' : 'View More ▼'}
                  </button>
                )}
              </div>
            </div>
            {/* Moved Main Content (Event) outside flex container */}
            {isExpanded && (!hasBulletPoints || showFullContent) && (
              <div className='brutalist-main-content'>
                {article.content.event}
              </div>
            )}
            <div className='brutalist-card-meta'>
              <span className='author'>{article.author}</span>
              <span className='date'>
                {new Date(article.date).toLocaleDateString('en-US', {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric',
                })}
              </span>
            </div>
            <button
              className='brutalist-view-full-details'
              onClick={handleViewFullDetails}
            >
              GO TO DISCUSSION
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ArticleCard;
