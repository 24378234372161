import { ref, onValue, DataSnapshot } from 'firebase/database';
import { useState, useEffect } from 'react';

import { db } from '../../../firebase';
import {
  IComment,
  IReply,
  IRebuttal,
} from '../../MainComponents/CommentSystem/__constants__/content.types';

interface IUserActivityStats {
  commentsCount: number;
  likesCount: number;
  loading: boolean;
  error: string | null;
}

// Helper function to process a reply and count comments and likes
const processReply = (reply: IReply, userId: string): [number, number] => {
  let replyCount = 0;
  let replyLikeCount = 0;

  // Count reply if it belongs to the user
  if (reply.authorId === userId) {
    replyCount++;

    // Count likes on this reply
    if (reply.upvotes) {
      replyLikeCount += Object.keys(reply.upvotes).length;
    }
  }

  // Process rebuttals if they exist
  if (reply.rebuttals) {
    Object.values(reply.rebuttals).forEach((rebuttal: IRebuttal) => {
      // Count rebuttal if it belongs to the user
      if (rebuttal.authorId === userId) {
        replyCount++;

        // Count likes on this rebuttal
        if (rebuttal.upvotes) {
          replyLikeCount += Object.keys(rebuttal.upvotes).length;
        }
      }
    });
  }

  return [replyCount, replyLikeCount];
};

// Helper function to process a comment and count comments and likes
const processComment = (
  comment: IComment,
  userId: string,
): [number, number] => {
  let commentCount = 0;
  let likeCount = 0;

  // Count comment if it belongs to the user
  if (comment.authorId === userId) {
    commentCount++;

    // Count likes on this comment
    if (comment.upvotes) {
      likeCount += Object.keys(comment.upvotes).length;
    }
  }

  // Process replies if they exist
  if (comment.replies) {
    Object.values(comment.replies || {}).forEach((reply: IReply) => {
      const [replyCount, replyLikeCount] = processReply(reply, userId);
      commentCount += replyCount;
      likeCount += replyLikeCount;
    });
  }

  return [commentCount, likeCount];
};

// Helper function to process all comments in an article
const processArticleComments = (
  articleSnapshot: DataSnapshot,
  userId: string,
): [number, number] => {
  let articleCommentCount = 0;
  let articleLikeCount = 0;

  // Process all comments in this article
  articleSnapshot.forEach((commentSnapshot: DataSnapshot) => {
    const comment = commentSnapshot.val() as IComment;
    const [commentCount, likeCount] = processComment(comment, userId);
    articleCommentCount += commentCount;
    articleLikeCount += likeCount;
  });

  return [articleCommentCount, articleLikeCount];
};

export const useUserActivityStats = (userId?: string): IUserActivityStats => {
  const [commentsCount, setCommentsCount] = useState<number>(0);
  const [likesCount, setLikesCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!userId) {
      setLoading(false);
      return;
    }

    setLoading(true);

    // Reference to all comments in the database
    const commentsRef = ref(db, 'comments');

    // Subscribe to comments data
    const unsubscribe = onValue(
      commentsRef,
      (snapshot) => {
        try {
          if (!snapshot.exists()) {
            setCommentsCount(0);
            setLikesCount(0);
            setLoading(false);
            return;
          }

          let totalComments = 0;
          let totalLikes = 0;

          // Process all articles with comments
          snapshot.forEach((articleSnapshot: DataSnapshot) => {
            const [commentCount, likeCount] = processArticleComments(
              articleSnapshot,
              userId,
            );
            totalComments += commentCount;
            totalLikes += likeCount;
          });

          setCommentsCount(totalComments);
          setLikesCount(totalLikes);
          setError(null);
        } catch (err) {
          console.error('Error processing comments data:', err);
          setError('Failed to process comments data');
        } finally {
          setLoading(false);
        }
      },
      (error) => {
        console.error('Error fetching comments:', error);
        setError('Failed to fetch comments data');
        setLoading(false);
      },
    );

    // Clean up subscription
    return () => unsubscribe();
  }, [userId]);

  return { commentsCount, likesCount, loading, error };
};
