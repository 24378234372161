import imageCompression from 'browser-image-compression';
import React, { useState, useCallback, useRef } from 'react';
import Cropper from 'react-easy-crop';
import { Point, Area } from 'react-easy-crop/types';

import { BrutalistButton } from '../../SharedComponents/BrutalistUI';
import './profile.scss';

interface IImageCropModalProps {
  image: File;
  onClose: () => void;
  onCropComplete: (croppedImage: File) => void;
}

// Helper function to create a canvas with the cropped image
const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.src = url;
  });

// Helper function to get a cropped image from the canvas
const getCroppedImg = async (
  imageSrc: string,
  pixelCrop: Area,
): Promise<Blob> => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('No 2d context');
  }

  // Set canvas size to the desired dimensions (400x400)
  canvas.width = 400;
  canvas.height = 400;

  // Draw the cropped image on the canvas
  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    400,
    400,
  );

  // As a Blob
  return new Promise((resolve) => {
    canvas.toBlob(
      (blob) => {
        if (blob) {
          resolve(blob);
        }
      },
      'image/jpeg',
      0.95,
    );
  });
};

const ImageCropModal: React.FC<IImageCropModalProps> = ({
  image,
  onClose,
  onCropComplete,
}) => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const imageUrl = useRef<string>(URL.createObjectURL(image));

  // Validate file size
  const validateFileSize = (file: File): boolean => {
    const minSize = 10 * 1024; // 10KB
    const maxSize = 5 * 1024 * 1024; // 5MB

    if (file.size < minSize) {
      setError(`File size is too small. Minimum size is 10KB.`);
      return false;
    }

    if (file.size > maxSize) {
      setError(`File size is too large. Maximum size is 5MB.`);
      return false;
    }

    return true;
  };

  // Validate file type
  const validateFileType = (file: File): boolean => {
    const allowedTypes = ['image/jpeg', 'image/png', 'image/webp', 'image/gif'];
    const disallowedTypes = ['image/bmp', 'image/tiff', 'image/svg+xml'];

    if (disallowedTypes.includes(file.type)) {
      setError(
        `File type ${file.type} is not allowed. BMP, TIFF, and SVG files are not supported.`,
      );
      return false;
    }

    if (!allowedTypes.includes(file.type)) {
      setError(
        `File type ${file.type} is not allowed. Only JPEG, PNG, WebP, and GIF files are supported.`,
      );
      return false;
    }

    return true;
  };

  // Validate image dimensions
  const validateImageDimensions = async (file: File): Promise<boolean> => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        URL.revokeObjectURL(img.src);

        if (img.width < 150 || img.height < 150) {
          setError(
            'Image dimensions are too small. Minimum resolution is 150x150px.',
          );
          resolve(false);
          return;
        }

        if (img.width > 1024 || img.height > 1024) {
          setError(
            'Image dimensions are too large. Maximum resolution is 1024x1024px.',
          );
          resolve(false);
          return;
        }

        resolve(true);
      };

      img.onerror = () => {
        URL.revokeObjectURL(img.src);
        setError('Failed to load image for validation.');
        resolve(false);
      };

      img.src = URL.createObjectURL(file);
    });
  };

  const onCropChange = (crop: Point) => {
    setCrop(crop);
  };

  const onZoomChange = (zoom: number) => {
    setZoom(zoom);
  };

  const onCropAreaChange = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    [],
  );

  const handleSubmit = async () => {
    if (!croppedAreaPixels) return;

    setLoading(true);
    setError('');

    try {
      // Validate the original file
      if (!validateFileSize(image) || !validateFileType(image)) {
        setLoading(false);
        return;
      }

      const validDimensions = await validateImageDimensions(image);
      if (!validDimensions) {
        setLoading(false);
        return;
      }

      // Get the cropped image as a blob
      const croppedBlob = await getCroppedImg(
        imageUrl.current,
        croppedAreaPixels,
      );

      // Convert to WebP format for better compression
      const compressedFile = await imageCompression(
        new File([croppedBlob], image.name, { type: 'image/webp' }),
        {
          maxSizeMB: 1,
          maxWidthOrHeight: 400,
          useWebWorker: true,
        },
      );

      // Pass the cropped and compressed image back to the parent component
      onCropComplete(compressedFile);
    } catch (error) {
      console.error('Error cropping image:', error);
      setError('Failed to process image. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='modal-overlay'>
      <div className='image-crop-modal'>
        <div className='modal-header'>
          <h3>Crop Profile Picture</h3>
          <button className='close-button' onClick={onClose}>
            &times;
          </button>
        </div>

        <div className='crop-container'>
          <Cropper
            image={imageUrl.current}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={onCropChange}
            onZoomChange={onZoomChange}
            onCropComplete={onCropAreaChange}
          />
        </div>

        <div className='controls'>
          <div className='zoom-control'>
            <label htmlFor='zoom-control'>Zoom</label>
            <input
              id='zoom-control'
              type='range'
              min={1}
              max={3}
              step={0.1}
              value={zoom}
              onChange={(e) => onZoomChange(Number(e.target.value))}
            />
          </div>
        </div>

        {error && <div className='error-message'>{error}</div>}

        <div className='modal-actions'>
          <BrutalistButton
            variant='secondary'
            onClick={onClose}
            disabled={loading}
          >
            Cancel
          </BrutalistButton>
          <BrutalistButton
            variant='primary'
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? 'Processing...' : 'Apply'}
          </BrutalistButton>
        </div>

        <div className='image-requirements'>
          <h4>Image Requirements:</h4>
          <ul>
            <li>Square aspect ratio (1:1)</li>
            <li>Size: 10KB - 5MB</li>
            <li>Formats: JPEG, PNG, WebP, GIF</li>
            <li>Resolution: 150x150px - 1024x1024px</li>
            <li>Final image will be 400x400px</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ImageCropModal;
