import React from 'react';
import { Modal } from 'react-bootstrap';

import { FollowListItem } from './FollowListItem';
import { BrutalistButton } from '../../../SharedComponents/BrutalistUI';
import { IFollowUser } from '../constants/profileScreen.types';

interface IFollowModalProps {
  show: boolean;
  onHide: () => void;
  users: IFollowUser[];
  title: string;
  isLoading: boolean;
}

const FollowModal: React.FC<IFollowModalProps> = ({
  show,
  onHide,
  users,
  title,
  isLoading,
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className='brutalist-modal follow-modal'
    >
      <Modal.Header className='brutalist-border'>
        <Modal.Title className='brutalist-title-dark'>{title}</Modal.Title>
        <BrutalistButton
          onClick={onHide}
          className='close-button'
          btnSize='small'
        >
          ✕
        </BrutalistButton>
      </Modal.Header>
      <Modal.Body className='brutalist-border'>
        {isLoading ? (
          <div className='loading-container'>Loading users...</div>
        ) : users.length === 0 ? (
          <div className='empty-list'>No users to display</div>
        ) : (
          <div className='follow-list brutalist-container'>
            {users.map((user) => (
              <FollowListItem key={user.id} user={user} />
            ))}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default FollowModal;
