// useAlertsContent.ts
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
  doc,
  updateDoc,
  runTransaction,
  QueryDocumentSnapshot,
} from 'firebase/firestore';
import { useEffect, useState, useCallback } from 'react';

import { IAlert } from './constants/alertsContent.types';
import { useAuth } from '../../../contexts/AuthContext';
import { fsdb } from '../../../firebase';
import { alertEvents } from '../../StartingScreens/PostLogin/DashboardComponents/Navigation/alertEvents';

export default function useAlertsContent(): {
  alerts: IAlert[];
  loading: boolean;
  error: Error | null;
  hasMore: boolean;
  markAsRead: (alertId: string) => Promise<void>;
  markAllAsRead: () => Promise<void>;
  loadMoreAlerts: () => Promise<void>;
} {
  const [alerts, setAlerts] = useState<IAlert[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const [lastVisible, setLastVisible] = useState<
    QueryDocumentSnapshot | null | undefined
  >(null);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const { user } = useAuth();
  const PAGE_SIZE = 10;

  const fetchAlerts = useCallback(
    async (lastDoc: QueryDocumentSnapshot | null | undefined = null) => {
      if (!user) return;

      try {
        setError(null);
        setLoading(true);

        const alertsRef = collection(fsdb, 'alerts');
        let alertsQuery;

        if (lastDoc) {
          alertsQuery = query(
            alertsRef,
            where('userId', '==', user.uid),
            orderBy('timestamp', 'desc'),
            startAfter(lastDoc),
            limit(PAGE_SIZE),
          );
        } else {
          alertsQuery = query(
            alertsRef,
            where('userId', '==', user.uid),
            orderBy('timestamp', 'desc'),
            limit(PAGE_SIZE),
          );
        }

        const snapshot = await getDocs(alertsQuery);

        if (snapshot.empty) {
          setHasMore(false);
          setLoading(false);
          return;
        }

        const lastVisibleDoc = snapshot.docs[snapshot.docs.length - 1];
        setLastVisible(lastVisibleDoc);

        const newAlerts = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as IAlert[];

        if (newAlerts.length < PAGE_SIZE) {
          setHasMore(false);
        }

        if (lastDoc) {
          setAlerts((prevAlerts) => [...prevAlerts, ...newAlerts]);
        } else {
          setAlerts(newAlerts);
        }
      } catch (err) {
        setError(
          err instanceof Error ? err : new Error('Failed to fetch alerts'),
        );
      } finally {
        setLoading(false);
      }
    },
    [user, PAGE_SIZE],
  );

  const loadMoreAlerts = async () => {
    if (!hasMore || loading) return;
    await fetchAlerts(lastVisible);
  };

  const markAsRead = async (alertId: string) => {
    if (!user) return;

    try {
      const alertRef = doc(fsdb, 'alerts', alertId);
      await updateDoc(alertRef, {
        read: true,
      });

      // Update local state
      setAlerts((prevAlerts) =>
        prevAlerts.map((alert) =>
          alert.id === alertId ? { ...alert, read: true } : alert,
        ),
      );

      // Notify subscribers that unread count has changed
      alertEvents.emit();
    } catch (err) {
      setError(
        err instanceof Error ? err : new Error('Failed to mark alert as read'),
      );
    }
  };

  const markAllAsRead = async () => {
    if (!user) return;

    try {
      // Using batch or transaction to update all alerts
      await runTransaction(fsdb, async (transaction) => {
        // Get all unread alerts
        const alertsRef = collection(fsdb, 'alerts');
        const unreadQuery = query(
          alertsRef,
          where('userId', '==', user.uid),
          where('read', '==', false),
        );
        const unreadSnapshot = await getDocs(unreadQuery);

        // Update each alert
        unreadSnapshot.docs.forEach((doc) => {
          const alertRef = doc.ref;
          transaction.update(alertRef, { read: true });
        });
      });

      // Update local state
      setAlerts((prevAlerts) =>
        prevAlerts.map((alert) => ({ ...alert, read: true })),
      );

      // Notify subscribers that unread count has changed
      alertEvents.emit();
    } catch (err) {
      setError(
        err instanceof Error
          ? err
          : new Error('Failed to mark all alerts as read'),
      );
    }
  };

  useEffect(() => {
    if (user) {
      setHasMore(true);
      fetchAlerts();
    } else {
      setAlerts([]);
      setLoading(false);
    }
  }, [fetchAlerts, user]);

  return {
    alerts,
    loading,
    error,
    hasMore,
    markAsRead,
    markAllAsRead,
    loadMoreAlerts,
  };
}
