import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import '../../../../src/styles/brutalist.scss';
import './welcomeScreen.scss';
import { useAuth } from '../../../contexts/AuthContext';

export default function WelcomeScreen(): JSX.Element | null {
  const navigate = useNavigate();
  const { user } = useAuth();
  const displayName = user?.displayName;
  const isAnonymous = !displayName || displayName === 'Anonymous';

  const [showPerspective, setShowPerspective] = useState(false);
  const [animationPhase, setAnimationPhase] = useState(0);

  const isTestMode = false; // set to true for testing
  const timeoutAmount = isTestMode ? 300000 : 3000;

  useEffect(() => {
    // If user is not anonymous, redirect to dashboard immediately
    if (!isAnonymous) {
      navigate('/dashboard');
      return;
    }

    // First animation phase
    const perspectiveTimeout = setTimeout(() => {
      setShowPerspective(true);
    }, 800);

    // Second animation phase
    const phaseTimeout = setTimeout(() => {
      setAnimationPhase(1);
    }, 1500);

    // Navigate to dashboard
    const navigateTimeout = setTimeout(() => {
      navigate('/dashboard');
    }, timeoutAmount);

    return () => {
      clearTimeout(perspectiveTimeout);
      clearTimeout(phaseTimeout);
      clearTimeout(navigateTimeout);
    };
  }, [isAnonymous, navigate, timeoutAmount]);

  const handleClick = () => {
    navigate('/dashboard');
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ' ') {
      navigate('/dashboard');
    }
  };

  // If user is not anonymous, don't render the welcome screen
  if (!isAnonymous) {
    return null; // Return null instead of empty fragment
  }

  return (
    <div
      className='welcome-screen'
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      role='button'
      tabIndex={0}
    >
      <div
        className={`welcome-container ${animationPhase > 0 ? 'phase-1' : ''}`}
      >
        <div className='brutalist-logo-container'>
          <h1 className='brutalist-title-light'>NEGNAN</h1>
        </div>

        <div className='brutalist-card message-card'>
          <h3 className='brutalist-section-title'>
            Don&apos;t Be A Negative Nancy
          </h3>

          <div className={`tagline ${showPerspective ? 'visible' : ''}`}>
            <span className='brutalist-text-primary'>
              Explore Other Viewpoints
            </span>
          </div>
        </div>

        <div className='hint-text'>
          <small className='brutalist-text-dark'>
            Click anywhere to continue
          </small>
        </div>
      </div>
    </div>
  );
}
