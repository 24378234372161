// commentItems.tsx
import React from 'react';
import { BiSolidChevronsUp } from 'react-icons/bi';
import { ImCross, ImPlus } from 'react-icons/im';
import { useNavigate } from 'react-router-dom';

import { RebuttalBox } from './commentInputs';
import { ICommentStyles } from './commentTypes';
import {
  formatTimestamp,
  getUpvoteCount,
  canAddRebuttal,
} from './commentUtils';
import { BrutalistButton } from '../../../SharedComponents/BrutalistUI';
import {
  HandleDeleteReply,
  HandleDeleteRebuttal,
  HandleUpvote,
  HandleAddRebuttal,
  IOpenRebuttals,
  IComment,
  IReply,
  IRebuttal,
} from '../__constants__/comment.types';

/**
 * Component for displaying a rebuttal
 */
export const Rebuttal: React.FC<{
  rebuttalId: string;
  rebuttal: IRebuttal;
  commentId: string;
  replyId: string;
  userId: string;
  isAnonymous: boolean;
  handleUpvote: HandleUpvote;
  handleDeleteRebuttal: HandleDeleteRebuttal;
  styles: ICommentStyles;
}> = ({
  rebuttalId,
  rebuttal,
  commentId,
  replyId,
  userId,
  isAnonymous,
  handleUpvote,
  handleDeleteRebuttal,
  styles,
}) => {
  const navigate = useNavigate();
  if (!rebuttal) return null;

  return (
    <div className='rebuttal-node' style={styles.rebuttalNode}>
      <div className='rebuttal-container' style={styles.rebuttalContainer}>
        <div style={styles.rebuttalWrapper}>
          <div style={styles.commentText}>{rebuttal.content}</div>
          <div style={styles.authorContainer}>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (rebuttal.authorId) {
                  const params = new URLSearchParams();
                  params.append('username', rebuttal.author);
                  if (rebuttal.profilePicture) {
                    params.append('photo', rebuttal.profilePicture);
                  }
                  navigate(
                    `/profile/${rebuttal.authorId}?${params.toString()}`,
                  );
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  if (rebuttal.authorId) {
                    const params = new URLSearchParams();
                    params.append('username', rebuttal.author);
                    if (rebuttal.profilePicture) {
                      params.append('photo', rebuttal.profilePicture);
                    }
                    navigate(
                      `/profile/${rebuttal.authorId}?${params.toString()}`,
                    );
                  }
                }
              }}
              role='button'
              tabIndex={0}
            >
              <img
                src={
                  rebuttal.profilePicture ||
                  'https://upload.wikimedia.org/wikipedia/commons/a/ac/Default_pfp.jpg'
                }
                alt={`${rebuttal.author}'s profile`}
                style={styles.profilePicture}
              />
            </div>
            <div>
              <span
                style={{ ...styles.authorText, cursor: 'pointer' }}
                onClick={() => {
                  if (rebuttal.authorId) {
                    // Add the author's username and image as URL params
                    const params = new URLSearchParams();
                    params.append('username', rebuttal.author);
                    if (rebuttal.profilePicture) {
                      params.append('photo', rebuttal.profilePicture);
                    }
                    navigate(
                      `/profile/${rebuttal.authorId}?${params.toString()}`,
                    );
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    if (rebuttal.authorId) {
                      const params = new URLSearchParams();
                      params.append('username', rebuttal.author);
                      if (rebuttal.profilePicture) {
                        params.append('photo', rebuttal.profilePicture);
                      }
                      navigate(
                        `/profile/${rebuttal.authorId}?${params.toString()}`,
                      );
                    }
                  }
                }}
                role='button'
                tabIndex={0}
              >
                By: {rebuttal.author}
              </span>
              <span style={styles.timestamp}>
                {formatTimestamp(rebuttal.date)}
              </span>
            </div>
          </div>

          {!isAnonymous && (
            <div
              style={styles.upvoteButton}
              onClick={() => handleUpvote(commentId, replyId, rebuttalId)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleUpvote(commentId, replyId, rebuttalId);
                }
              }}
              role='button'
              tabIndex={0}
            >
              <BiSolidChevronsUp size={18} />
              <span>({getUpvoteCount(rebuttal.upvotes)})</span>
            </div>
          )}

          {rebuttal.authorId === userId && !isAnonymous && (
            <ImCross
              style={styles.deleteIcon}
              onClick={() =>
                handleDeleteRebuttal(commentId, replyId, rebuttalId)
              }
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleDeleteRebuttal(commentId, replyId, rebuttalId);
                }
              }}
              role='button'
              tabIndex={0}
            />
          )}
        </div>
      </div>
    </div>
  );
};

// --- RebuttalSection Component ---

/**
 * Component for displaying rebuttals section
 */
export const RebuttalSection: React.FC<{
  reply: IReply; // Use IReply from constants
  commentId: string;
  replyId: string;
  comment: IComment; // Use IComment from constants
  userId: string;
  isAnonymous: boolean;
  handleUpvote: HandleUpvote;
  handleDeleteRebuttal: HandleDeleteRebuttal;
  openRebuttals: IOpenRebuttals;
  setOpenRebuttals: React.Dispatch<React.SetStateAction<IOpenRebuttals>>;
  styles: ICommentStyles;
}> = ({
  reply,
  commentId,
  replyId,
  comment,
  userId,
  isAnonymous,
  handleUpvote,
  handleDeleteRebuttal,
  openRebuttals,
  setOpenRebuttals,
  styles,
}) => {
  if (!reply.rebuttals || Object.keys(reply.rebuttals).length === 0) {
    return null;
  }

  if (!openRebuttals[replyId]?.isOpen) {
    return (
      <BrutalistButton
        className='brutalist-action-button brutalist-small'
        onClick={() =>
          setOpenRebuttals({
            ...openRebuttals,
            [replyId]: { isOpen: true, shownRebuttals: 5 },
          })
        }
      >
        View rebuttals
        {reply.rebuttalThread && (
          <span> ({reply.rebuttalThread.exchangeCount}/5)</span>
        )}
      </BrutalistButton>
    );
  }

  return (
    <>
      {/* Rebuttal Thread Header */}
      {reply.rebuttalThread && (
        <div className='rebuttal-thread-header'>
          <div className='rebuttal-thread-title'>
            REBUTTAL THREAD ({reply.rebuttalThread.exchangeCount}/5)
          </div>
          {reply.rebuttalThread.participants.length === 2 && (
            <div className='rebuttal-participants'>
              Between{' '}
              {reply.rebuttalThread.participants[0] === reply.authorId
                ? reply.author
                : comment.author}{' '}
              and{' '}
              {reply.rebuttalThread.participants[1] === reply.authorId
                ? reply.author
                : comment.author}
            </div>
          )}
        </div>
      )}

      {Object.keys(reply.rebuttals)
        .slice(0, openRebuttals[replyId]?.shownRebuttals)
        .map((rebuttalId) => {
          const rebuttal = reply.rebuttals && reply.rebuttals[rebuttalId];
          if (!rebuttal) return null;
          return (
            <Rebuttal
              key={rebuttalId}
              rebuttalId={rebuttalId}
              rebuttal={rebuttal as IRebuttal} // Use IRebuttal
              commentId={commentId}
              replyId={replyId}
              userId={userId}
              isAnonymous={isAnonymous}
              handleUpvote={handleUpvote}
              handleDeleteRebuttal={handleDeleteRebuttal}
              styles={styles}
            />
          );
        })}

      <BrutalistButton
        className='brutalist-action-button brutalist-small'
        onClick={() =>
          setOpenRebuttals({
            ...openRebuttals,
            [replyId]: {
              isOpen: false,
              shownRebuttals: 5,
            },
          })
        }
      >
        Hide rebuttals
      </BrutalistButton>
    </>
  );
};

// --- Reply Component ---

/**
 * Component for displaying a reply
 */
export const Reply: React.FC<{
  replyId: string;
  reply: IReply;
  commentId: string;
  comment: IComment;
  userId: string;
  isAnonymous: boolean;
  handleUpvote: HandleUpvote;
  handleDeleteReply: HandleDeleteReply;
  handleAddRebuttal: HandleAddRebuttal;
  showRebuttalBox: { [key: string]: boolean };
  setShowRebuttalBox: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  newRebuttal: { [key: string]: string };
  setNewRebuttal: React.Dispatch<
    React.SetStateAction<{ [key: string]: string }>
  >;
  isRebuttalLoading: { [key: string]: boolean };
  openRebuttals: IOpenRebuttals;
  setOpenRebuttals: React.Dispatch<React.SetStateAction<IOpenRebuttals>>;
  handleDeleteRebuttal: HandleDeleteRebuttal;
  styles: ICommentStyles;
}> = ({
  replyId,
  reply,
  commentId,
  comment,
  userId,
  isAnonymous,
  handleUpvote,
  handleDeleteReply,
  handleAddRebuttal,
  showRebuttalBox,
  setShowRebuttalBox,
  newRebuttal,
  setNewRebuttal,
  isRebuttalLoading,
  openRebuttals,
  setOpenRebuttals,
  handleDeleteRebuttal,
  styles,
}) => {
  const navigate = useNavigate();
  if (!reply) return null;

  const canUserAddRebuttal = canAddRebuttal(reply, userId, isAnonymous);

  // Category class removed from Reply
  // const categoryClass = `category--${reply.category || 'other'}`;

  return (
    <div className='reply-node' style={styles.replyNode}>
      <div className='reply-container' style={styles.replyContainer}>
        <div style={styles.replyWrapper}>
          <div style={styles.commentText}>{reply.content}</div>
          <div style={styles.authorContainer}>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (reply.authorId) {
                  // Add the author's username and image as URL params
                  const params = new URLSearchParams();
                  params.append('username', reply.author);
                  if (reply.profilePicture) {
                    params.append('photo', reply.profilePicture);
                  }
                  navigate(`/profile/${reply.authorId}?${params.toString()}`);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  if (reply.authorId) {
                    const params = new URLSearchParams();
                    params.append('username', reply.author);
                    if (reply.profilePicture) {
                      params.append('photo', reply.profilePicture);
                    }
                    navigate(`/profile/${reply.authorId}?${params.toString()}`);
                  }
                }
              }}
              role='button'
              tabIndex={0}
            >
              <img
                src={
                  reply.profilePicture ||
                  'https://upload.wikimedia.org/wikipedia/commons/a/ac/Default_pfp.jpg'
                }
                alt={`${reply.author}'s profile`}
                style={styles.profilePicture}
              />
            </div>
            <div>
              <span
                style={{ ...styles.authorText, cursor: 'pointer' }}
                onClick={() => {
                  if (reply.authorId) {
                    // Add the author's username and image as URL params
                    const params = new URLSearchParams();
                    params.append('username', reply.author);
                    if (reply.profilePicture) {
                      params.append('photo', reply.profilePicture);
                    }
                    navigate(`/profile/${reply.authorId}?${params.toString()}`);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    if (reply.authorId) {
                      const params = new URLSearchParams();
                      params.append('username', reply.author);
                      if (reply.profilePicture) {
                        params.append('photo', reply.profilePicture);
                      }
                      navigate(
                        `/profile/${reply.authorId}?${params.toString()}`,
                      );
                    }
                  }
                }}
                role='button'
                tabIndex={0}
              >
                By: {reply.author}
              </span>
              <span style={styles.timestamp}>
                {formatTimestamp(reply.date)}
              </span>
            </div>
          </div>

          {!isAnonymous && (
            <div
              style={styles.upvoteButton}
              onClick={() => handleUpvote(commentId, replyId)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleUpvote(commentId, replyId);
                }
              }}
              role='button'
              tabIndex={0}
            >
              <BiSolidChevronsUp size={18} />
              <span>({getUpvoteCount(reply.upvotes)})</span>
            </div>
          )}

          {reply.authorId === userId && !isAnonymous && (
            <ImCross
              style={styles.deleteIcon}
              onClick={() => handleDeleteReply(commentId, replyId)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleDeleteReply(commentId, replyId);
                }
              }}
              role='button'
              tabIndex={0}
            />
          )}

          {/* Rebuttal Box - Only show for eligible authenticated users */}
          {canUserAddRebuttal &&
            (showRebuttalBox[replyId] ? (
              <RebuttalBox
                replyId={replyId}
                commentId={commentId}
                newRebuttal={newRebuttal}
                setNewRebuttal={setNewRebuttal}
                isRebuttalLoading={isRebuttalLoading}
                handleAddRebuttal={handleAddRebuttal}
                setShowRebuttalBox={setShowRebuttalBox}
                showRebuttalBox={showRebuttalBox}
                styles={styles}
              />
            ) : (
              <div
                onClick={() =>
                  setShowRebuttalBox({
                    ...showRebuttalBox,
                    [replyId]: true,
                  })
                }
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    setShowRebuttalBox({
                      ...showRebuttalBox,
                      [replyId]: true,
                    });
                  }
                }}
                role='button'
                tabIndex={0}
                style={styles.touchableDiv}
              >
                <ImPlus style={styles.primaryColor} />
                <div style={styles.addReply}>Add Rebuttal</div>
              </div>
            ))}
        </div>
      </div>

      {/* Existing Rebuttals Section */}
      <RebuttalSection
        reply={reply}
        commentId={commentId}
        replyId={replyId}
        comment={comment}
        userId={userId}
        isAnonymous={isAnonymous}
        handleUpvote={handleUpvote}
        handleDeleteRebuttal={handleDeleteRebuttal}
        openRebuttals={openRebuttals}
        setOpenRebuttals={setOpenRebuttals}
        styles={styles}
      />
    </div>
  );
};
