import { doc, getDoc } from 'firebase/firestore';
import { httpsCallable, getFunctions } from 'firebase/functions';
import { useState, useEffect, useCallback } from 'react';

import {
  IUserProfile,
  IUserActivity,
  IUserEngagement,
  IUserSettings,
  IUserBadges,
  ISocialLink,
} from './constants/profileScreen.types';
import { useUserActivityStats } from './useUserActivityStats';
import { useUserMetadata } from './useUserMetadata';
import { useAuth } from '../../../contexts/AuthContext';
import app, { fsdb } from '../../../firebase';

// Initialize Firebase Functions
const functions = getFunctions(app);

interface IUseProfileScreenContentReturn {
  userProfile: IUserProfile | null;
  userActivity: IUserActivity | null;
  userEngagement: IUserEngagement | null;
  userSettings: IUserSettings | null;
  userBadges: IUserBadges | null;
  loading: boolean;
  error: string | null;
}

// Define the type for profile data from Firestore
interface IFirestoreProfileData {
  bio?: string;
  socialLinks?: ISocialLink[];
  [key: string]: unknown; // Allow for other fields that might be in the document
}

interface IUserData {
  displayName?: string;
  photoURL?: string;
}

interface IFollowCounts {
  followers: number;
  following: number;
}

// Helper function to fetch user profile data from Firestore
const fetchProfileFromFirestore = async (
  userId: string,
): Promise<IFirestoreProfileData | null> => {
  try {
    const userProfileDoc = await getDoc(doc(fsdb, 'userProfiles', userId));
    if (userProfileDoc.exists()) {
      return userProfileDoc.data() as IFirestoreProfileData;
    }
  } catch (err) {
    console.error('Error fetching user profile data:', err);
  }
  return null;
};

// Helper function to fetch user data from Firebase Auth and Firestore
const fetchUserDataFromFirestore = async (
  userId: string,
): Promise<IUserData | null> => {
  try {
    // Try the userProfiles collection where we store user profile data
    const userProfileDoc = await getDoc(doc(fsdb, 'userProfiles', userId));
    if (userProfileDoc.exists()) {
      // This won't have displayName but may have other useful profile info
    }

    // As a last resort, try the users collection
    const userDataDoc = await getDoc(doc(fsdb, 'users', userId));
    if (userDataDoc.exists()) {
      const userData = userDataDoc.data();
      if (userData.displayName || userData.photoURL) {
        return {
          displayName: userData.displayName,
          photoURL: userData.photoURL,
        };
      }
    }
  } catch (err) {
    console.error('Error fetching user data:', err);
  }
  return null;
};

// Helper to fetch follow counts
const fetchFollowCounts = async (userId: string): Promise<IFollowCounts> => {
  try {
    const getFollowCountsFn = httpsCallable(functions, 'getFollowCounts');
    const result = await getFollowCountsFn({ userId });
    return result.data as IFollowCounts;
  } catch (err) {
    console.error('Error fetching follow counts:', err);
    return { followers: 0, following: 0 };
  }
};

// Helper to ensure user profile exists
const ensureUserProfile = async (userId: string): Promise<void> => {
  try {
    const ensureProfileFn = httpsCallable(functions, 'ensureUserProfile');
    await ensureProfileFn({ uid: userId });
  } catch (err) {
    console.warn('Failed to ensure user profile:', err);
  }
};

export const useProfileScreenContent = (
  userId?: string,
  providedUsername?: string,
  providedPhotoURL?: string,
): IUseProfileScreenContentReturn => {
  const { user, darkMode } = useAuth();
  const {
    creationDate,
    loading: metadataLoading,
    error: _metadataError,
  } = useUserMetadata(userId);

  const {
    commentsCount,
    likesCount,
    loading: activityStatsLoading,
    error: activityStatsError,
  } = useUserActivityStats(userId || user?.uid);

  // State for profile data
  const [userProfile, setUserProfile] = useState<IUserProfile | null>(null);
  const [userActivity, setUserActivity] = useState<IUserActivity | null>(null);
  const [userEngagement, setUserEngagement] = useState<IUserEngagement | null>(
    null,
  );
  const [userSettings, setUserSettings] = useState<IUserSettings | null>(null);
  const [userBadges, setUserBadges] = useState<IUserBadges | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // Update loading and error states based on child hooks
  useEffect(() => {
    setLoading(metadataLoading || activityStatsLoading);

    if (activityStatsError) {
      setError(activityStatsError);
    }
  }, [metadataLoading, activityStatsLoading, activityStatsError]);

  // Helper to determine user display information
  const getUserDisplayInfo = useCallback(
    async (
      targetUserId: string,
      isOwnProfile: boolean,
    ): Promise<{ displayName: string; photoURL: string }> => {
      // Default values
      let displayName = 'Anonymous User';
      let photoURL = '';

      // Handle own profile
      if (isOwnProfile && user) {
        return {
          displayName: user.displayName || 'Anonymous User',
          photoURL: user.photoURL || '',
        };
      }

      // If we have data from comment component, use it
      if (providedUsername || providedPhotoURL) {
        displayName = providedUsername || displayName;
        photoURL = providedPhotoURL || photoURL;
      }

      // Try to get user data from Firestore to supplement
      const userData = await fetchUserDataFromFirestore(targetUserId);
      if (userData) {
        // Use Firestore data for any missing fields
        if (!providedUsername && userData.displayName) {
          displayName = userData.displayName;
        }

        if (!providedPhotoURL && userData.photoURL) {
          photoURL = userData.photoURL;
        }
      } else if (!providedUsername) {
        // If no displayName from any source, use a fallback
        displayName = 'User ' + targetUserId.substring(0, 5);
      }

      return { displayName, photoURL };
    },
    [user, providedUsername, providedPhotoURL],
  );

  // Main data fetching effect
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        setError(null);

        // Determine if this is the current user's profile
        const isOwnProfile = !userId || (user && userId === user.uid) || false;
        const userIdToFetch = isOwnProfile ? user?.uid : userId;

        if (!userIdToFetch) {
          setLoading(false);
          return;
        }

        // For other users' profiles, ensure their profile exists
        if (!isOwnProfile) {
          await ensureUserProfile(userIdToFetch);
        }

        // Get user display information
        const { displayName, photoURL } = await getUserDisplayInfo(
          userIdToFetch,
          isOwnProfile,
        );

        // Fetch profile data (bio, social links)
        let userBio = 'This is a placeholder bio.';
        let userSocialLinks: ISocialLink[] = [];

        const profileData = await fetchProfileFromFirestore(userIdToFetch);
        if (profileData) {
          userBio = profileData.bio || userBio;
          userSocialLinks = profileData.socialLinks || userSocialLinks;
        }

        // Get follow counts
        const { followers, following } = await fetchFollowCounts(userIdToFetch);

        // Set user profile data
        setUserProfile({
          displayName,
          username: displayName,
          bio: userBio,
          joinDate: creationDate || new Date(),
          photoURL,
          isOwnProfile,
          socialLinks: userSocialLinks.length > 0 ? userSocialLinks : undefined,
          userId: userIdToFetch,
        });

        // Set user activity data
        setUserActivity({
          commentsPosted: commentsCount,
          likesReceived: likesCount,
          followers,
          following,
        });

        // Set user engagement data - only relevant for own profile
        setUserEngagement({
          commentHistory: [], // TODO: Fetch actual comment history
          savedArticles: [], // TODO: Fetch actual saved articles
          likedComments: [], // TODO: Fetch actual liked comments
        });

        // Set user settings - only relevant for own profile
        setUserSettings({
          darkMode: Boolean(darkMode),
          notificationPreferences: {
            emailNotifications: true,
            commentReplies: true,
            mentions: true,
            newFollowers: true,
          },
          privacySettings: {
            profileVisibility: 'public',
            showActivity: true,
            showFollowers: true,
          },
        });

        // Set user badges
        setUserBadges({
          badges: [], // TODO: Fetch actual badges
          reputationScore: 0, // TODO: Fetch actual reputation score
          moderationStatus: 'user', // TODO: Fetch actual moderation status
        });
      } catch (err) {
        setError('Failed to load profile data');
        console.error('Error fetching profile data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [
    user,
    userId,
    darkMode,
    creationDate,
    commentsCount,
    likesCount,
    getUserDisplayInfo,
  ]);

  return {
    userProfile,
    userActivity,
    userEngagement,
    userSettings,
    userBadges,
    loading,
    error,
  };
};
