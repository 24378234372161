import React from 'react';

import { useUnreadAlerts } from './useUnreadAlerts';
import './navbar.scss';

export const AlertCounter: React.FC = () => {
  const { unreadCount, loading } = useUnreadAlerts();

  // Don't show the counter if still loading or if there are no unread alerts
  if (loading || unreadCount === 0) return null;

  return (
    <div className='brutalist-alert-counter'>
      {unreadCount > 99 ? '99+' : unreadCount}
    </div>
  );
};
