import React, { useEffect, useState, ChangeEvent } from 'react';

import ImageCropModal from './ImageCropModal';
import {
  upload,
  useAuth,
  IAuthContextType,
} from '../../../contexts/AuthContext';
import { BrutalistButton } from '../../SharedComponents/BrutalistUI';
import './profile.scss';

interface IProfilePictureProps {
  className?: string;
}

// Track upload frequency
interface IUploadRecord {
  timestamp: number;
  count: number;
}

const MAX_UPLOADS_PER_DAY = 3;
const COOLDOWN_MINUTES = 10;
const COOLDOWN_MS = COOLDOWN_MINUTES * 60 * 1000;

const ProfilePicture: React.FC<IProfilePictureProps> = ({ className = '' }) => {
  const { user } = useAuth() as IAuthContextType;
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [croppedImage, setCroppedImage] = useState<File | null>(null);
  const [showCropModal, setShowCropModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [photoURL, setPhotoURL] = useState(
    'https://upload.wikimedia.org/wikipedia/commons/a/ac/Default_pfp.jpg',
  );
  const [error, setError] = useState<string>('');
  const [uploadRecord, setUploadRecord] = useState<IUploadRecord>({
    timestamp: 0,
    count: 0,
  });

  // Check if user has reached upload limit
  const checkUploadLimit = (): boolean => {
    const now = Date.now();
    const today = new Date().setHours(0, 0, 0, 0);

    // Reset count if it's a new day
    if (uploadRecord.timestamp < today) {
      setUploadRecord({ timestamp: now, count: 0 });
      return true;
    }

    // Check if cooldown period has passed
    if (now - uploadRecord.timestamp < COOLDOWN_MS) {
      const minutesLeft = Math.ceil(
        (COOLDOWN_MS - (now - uploadRecord.timestamp)) / 60000,
      );
      setError(
        `Please wait ${minutesLeft} minute${minutesLeft !== 1 ? 's' : ''} before uploading again.`,
      );
      return false;
    }

    // Check if daily limit reached
    if (uploadRecord.count >= MAX_UPLOADS_PER_DAY) {
      setError(
        `You've reached the maximum of ${MAX_UPLOADS_PER_DAY} profile picture updates per day.`,
      );
      return false;
    }

    return true;
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      // Basic file type check before opening modal
      const fileType = file.type;
      if (
        fileType === 'image/jpeg' ||
        fileType === 'image/jpg' ||
        fileType === 'image/png' ||
        fileType === 'image/webp' ||
        fileType === 'image/gif'
      ) {
        setSelectedImage(file);
        setShowCropModal(true);
        setError('');
      } else {
        setError('Please upload a JPEG, JPG, PNG, WebP, or GIF file.');
        e.target.value = '';
      }
    }
  };

  const handleCropComplete = (croppedFile: File) => {
    setCroppedImage(croppedFile);
    setShowCropModal(false);
  };

  const handleCropCancel = () => {
    setSelectedImage(null);
    setShowCropModal(false);
  };

  const handleSubmit = async () => {
    if (!croppedImage || !user) {
      return;
    }

    // Check upload limits
    if (!checkUploadLimit()) {
      return;
    }

    setLoading(true);
    try {
      await upload(croppedImage, user, setLoading);

      // Update upload record
      setUploadRecord({
        timestamp: Date.now(),
        count: uploadRecord.count + 1,
      });

      // Refresh the photoURL from the user object after upload
      if (user.photoURL) {
        setPhotoURL(user.photoURL);
      }

      // Clear the cropped image after successful upload
      setCroppedImage(null);
      setError('');
    } catch (error) {
      console.error(error);
      setError('Failed to upload image. Please try again.');
    }
  };

  useEffect(() => {
    if (user && user.photoURL) {
      setPhotoURL(user.photoURL);
    }
  }, [user]);

  return (
    <div className={`profile-picture ${className}`}>
      <h2>Profile Image</h2>
      <div className='avatar-container'>
        <img src={photoURL} alt='Avatar' className='avatar' />
      </div>

      <div className='file-input-container'>
        {error && <div className='error-message'>{error}</div>}
        <input
          type='file'
          className='brutalist-file-input'
          onChange={handleChange}
          accept='.jpg,.jpeg,.png,.webp,.gif'
        />
      </div>

      {croppedImage && (
        <div className='selected-image-info'>
          <p>Image ready to upload: {croppedImage.name}</p>
        </div>
      )}

      <BrutalistButton
        variant='primary'
        onClick={handleSubmit}
        disabled={loading || !croppedImage}
        style={{ width: '100%' }}
      >
        {loading ? 'Uploading...' : 'Update profile image'}
      </BrutalistButton>

      {showCropModal && selectedImage && (
        <ImageCropModal
          image={selectedImage}
          onClose={handleCropCancel}
          onCropComplete={handleCropComplete}
        />
      )}

      <div className='upload-limits-info'>
        <p>
          <small>
            You can update your profile picture up to {MAX_UPLOADS_PER_DAY}{' '}
            times per day, with a {COOLDOWN_MINUTES}-minute cooldown between
            uploads.
          </small>
        </p>
      </div>
    </div>
  );
};

export default ProfilePicture;
