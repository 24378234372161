import React, { useState } from 'react';

import { IOpenReplies, IOpenRebuttals } from './__constants__/comment.types';
import CommentBox from './DiscussionComponents/commentBox';
import { CommentComponent } from './DiscussionComponents/commentComponent';
import { CommentControls } from './DiscussionComponents/CommentControls';
import { ConfirmationDialog } from './DiscussionComponents/confirmationDialog';
import useCommentSystemContent from './useCommentSystemContent';
import { useAuth } from '../../../contexts/AuthContext';
import './commentSystem.scss';

interface ICommentSystemProps {
  articleId: string;
  displayName: string;
}

export const CommentSystem: React.FC<ICommentSystemProps> = ({
  articleId,
  displayName,
}) => {
  const { user } = useAuth();
  const userId = user?.uid || 'anonymous';
  const {
    commentsData,
    handleAddComment,
    handleDeleteComment,
    handleDeleteReply,
    handleDeleteRebuttal,
    handleAddReply,
    handleAddRebuttal,
    handleUpvote,
    sortedComments,
    newComment,
    setNewComment,
    newReply,
    setNewReply,
    newRebuttal,
    setNewRebuttal,
    confirmDialogOpen,
    handleCloseConfirmDialog,
    handleDeleteConfirmation,
    isCommentLoading,
    isReplyLoading,
    isRebuttalLoading,
    selectedCategory,
    setSelectedCategory,
    filterCategory,
    setFilterCategory,
    sortOrder,
    setSortOrder,
  } = useCommentSystemContent(articleId, displayName);

  const [openReplies, setOpenReplies] = useState<IOpenReplies>({});
  const [openRebuttals, setOpenRebuttals] = useState<IOpenRebuttals>({});
  const [showReplyBox, setShowReplyBox] = useState<{ [key: string]: boolean }>(
    {},
  );
  const [showRebuttalBox, setShowRebuttalBox] = useState<{
    [key: string]: boolean;
  }>({});

  return (
    <div className='brutalist-comment-system'>
      <h2 className='brutalist-section-title'>Discussion</h2>

      {user && !user.isAnonymous ? (
        <CommentBox
          newComment={newComment}
          setNewComment={setNewComment}
          handleAddComment={handleAddComment}
          isLoading={isCommentLoading}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
      ) : (
        <div className='brutalist-login-prompt'>
          <p>You need to be logged in to comment.</p>
        </div>
      )}

      <CommentControls
        filterCategory={filterCategory}
        setFilterCategory={setFilterCategory}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
      />

      {commentsData &&
        sortedComments.map((commentId) => (
          <CommentComponent
            key={commentId}
            commentId={commentId}
            commentsData={commentsData}
            displayName={displayName}
            userId={userId}
            handleDeleteComment={handleDeleteComment}
            handleDeleteReply={handleDeleteReply}
            handleDeleteRebuttal={handleDeleteRebuttal}
            handleUpvote={handleUpvote}
            handleAddReply={handleAddReply}
            handleAddRebuttal={handleAddRebuttal}
            isAnonymous={!displayName || displayName === 'Anonymous'}
            openReplies={openReplies}
            setOpenReplies={setOpenReplies}
            openRebuttals={openRebuttals}
            setOpenRebuttals={setOpenRebuttals}
            showReplyBox={showReplyBox}
            setShowReplyBox={setShowReplyBox}
            showRebuttalBox={showRebuttalBox}
            setShowRebuttalBox={setShowRebuttalBox}
            newReply={newReply}
            newRebuttal={newRebuttal}
            setNewReply={setNewReply}
            setNewRebuttal={setNewRebuttal}
            isReplyLoading={isReplyLoading}
            isRebuttalLoading={isRebuttalLoading}
          />
        ))}
      {(!commentsData || sortedComments.length === 0) && (
        <div
          className='brutalist-no-comments'
          style={{
            padding: '20px',
            backgroundColor: '$brutalist-bg-light',
            border: '1px solid $brutalist-gray-100',
            borderRadius: '4px',
            marginTop: '20px',
          }}
        >
          <p
            style={{
              fontSize: '16px',
              color: '$brutalist-gray-600',
              textAlign: 'center',
            }}
          >
            No comments yet. Be the first to start the discussion!
          </p>
        </div>
      )}
      <ConfirmationDialog
        open={confirmDialogOpen}
        onClose={handleCloseConfirmDialog}
        onConfirm={handleDeleteConfirmation}
      />
    </div>
  );
};

export default CommentSystem;
