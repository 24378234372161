// PreLoginLayout.tsx
import React from "react";
import { Outlet } from "react-router-dom";

import { BrutalistEarth } from "../../SharedComponents/BrutalistUI";
import "./preLogin.scss";

const PreLoginLayout: React.FC = () => {
  return (
    <div className="prelogin-layout">
      {/* The Outlet component renders the current route */}
      <Outlet />

      {/* Single Earth instance shared across all pre-login screens */}
      <div className="earth-background">
        <BrutalistEarth />
      </div>
    </div>
  );
};

export default PreLoginLayout;
