// ArchiveContent.tsx
import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import { Calendar, CalendarProps } from 'react-calendar';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Carousel } from 'react-responsive-carousel';

import 'react-calendar/dist/Calendar.css';
import '../../../styles/brutalist.scss';
import './archiveContent.scss';

import useArchiveContent from './useArchiveContent';

type Value = CalendarProps['value'];

export default function ArchiveContent(): JSX.Element {
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [currentSlide, setCurrentSlide] = useState(0);

  // Initialize with default values to avoid conditional hook call
  const {
    articles = [],
    loading = false,
    error = null,
  } = useArchiveContent(selectedDate || new Date());

  const handleDateChange = (value: Value) => {
    if (value instanceof Date) {
      setSelectedDate(value);
    } else {
      setSelectedDate(null);
    }
  };

  return (
    <Col xs={12} sm={9} md={8} lg={6} xl={6} className='archive-content'>
      <Calendar
        onChange={handleDateChange}
        value={selectedDate}
        className='brutalist-calendar'
      />

      <div className='mt-4'>
        {loading ? (
          <div className='brutalist-loading'>
            <div className='loading-indicator' />
            <h3>LOADING</h3>
          </div>
        ) : error ? (
          <div className='brutalist-error'>
            <h3>ERROR</h3>
            <p>{error.message}</p>
          </div>
        ) : articles.length === 0 ? (
          <div className='empty-feed'>
            <h3>NO RECORDS FOUND</h3>
            <p>Sorry, our records do not go that far back.</p>
            <div
              className='brutalist-primary-background'
              style={{ width: '50px', height: '50px', margin: '0 auto' }}
            />
          </div>
        ) : (
          <div className='brutalist-carousel-container'>
            <button
              className='carousel-arrow'
              onClick={() => setCurrentSlide(currentSlide - 1)}
              disabled={currentSlide === 0}
            >
              <FaChevronLeft size={20} />
            </button>

            <Carousel
              showArrows={false}
              showStatus={false}
              showIndicators={false}
              selectedItem={currentSlide}
              onChange={(index) => setCurrentSlide(index)}
              className='carousel'
            >
              {articles.map((article) => (
                <div
                  key={`${article.title}-${article.date}`}
                  className='carousel-item'
                >
                  <h5>{article.content.event}</h5>
                </div>
              ))}
            </Carousel>

            <button
              className='carousel-arrow'
              onClick={() => setCurrentSlide(currentSlide + 1)}
              disabled={currentSlide === articles.length - 1}
            >
              <FaChevronRight size={20} />
            </button>
          </div>
        )}
      </div>
    </Col>
  );
}
