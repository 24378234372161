import React from 'react';
import { Col } from 'react-bootstrap';

import { useAuth } from '../../../../contexts/AuthContext';
import AdminDebug from '../../../AdminDebug';
import '../../../../styles/brutalist.scss';
import './rightSidePanel.scss';

const SidePanelBorder = (): JSX.Element => (
  <div className='side-panel-border' />
);

export default function RightSidePanel(): JSX.Element {
  const { user } = useAuth();
  const isDevelopment = process.env.NODE_ENV === 'development';

  return (
    <Col
      xl={4}
      lg={3}
      md={0}
      sm={0}
      className='d-none d-sm-block right-side-panel'
    >
      <SidePanelBorder />

      {/* Only show admin debug tools in development environment */}
      {isDevelopment && user && !user.isAnonymous && (
        <div className='p-3'>
          <AdminDebug />
        </div>
      )}
    </Col>
  );
}
