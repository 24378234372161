export enum AlertType {
  WELCOME = 'welcome',
  ADMIN_CRITICAL = 'admin_critical',
  ADMIN_GENERAL = 'admin_general',
  COMMENT_REPLY = 'comment_reply',
  TRENDING_DISCUSSION = 'trending_discussion',
  BREAKING_NEWS = 'breaking_news',
  SYSTEM_NOTIFICATION = 'system_notification',
}

export interface IAlert {
  id: string;
  type: AlertType;
  message: string;
  timestamp: { seconds: number; nanoseconds: number };
  read: boolean;
  userId: string;
  metadata?: Record<string, unknown>;
}

export interface IAlertsState {
  alerts: IAlert[];
  loading: boolean;
  error: string;
  hasMore: boolean;
  lastVisible: string | null;
}
