import {
  IUserProfile,
  IUserActivity,
  IUserEngagement,
  IUserSettings,
  IUserBadges,
} from './constants/profileScreen.types';

interface IUseProfileSectionsDataParams {
  _userProfile: IUserProfile | null;
  userActivity: IUserActivity | null;
  userEngagement: IUserEngagement | null;
  userSettings: IUserSettings | null;
  userBadges: IUserBadges | null;
}

interface IProfileSectionsData {
  safeUserActivity: IUserActivity;
  safeUserEngagement: IUserEngagement;
  safeUserSettings: IUserSettings;
  safeUserBadges: IUserBadges;
}

export const useProfileSectionsData = ({
  _userProfile,
  userActivity,
  userEngagement,
  userSettings,
  userBadges,
}: IUseProfileSectionsDataParams): IProfileSectionsData => {
  // Default values for each data type
  const defaultUserActivity: IUserActivity = {
    commentsPosted: 0,
    likesReceived: 0,
    followers: 0,
    following: 0,
  };

  const defaultUserEngagement: IUserEngagement = {
    commentHistory: [],
    savedArticles: [],
    likedComments: [],
  };

  const defaultUserSettings: IUserSettings = {
    darkMode: false,
    notificationPreferences: {
      emailNotifications: false,
      commentReplies: false,
      mentions: false,
      newFollowers: false,
    },
    privacySettings: {
      profileVisibility: 'public',
      showActivity: true,
      showFollowers: true,
    },
  };

  const defaultUserBadges: IUserBadges = {
    badges: [],
    reputationScore: 0,
    moderationStatus: 'user',
  };

  // Return non-nullable data with defaults applied
  return {
    safeUserActivity: userActivity || defaultUserActivity,
    safeUserEngagement: userEngagement || defaultUserEngagement,
    safeUserSettings: userSettings || defaultUserSettings,
    safeUserBadges: userBadges || defaultUserBadges,
  };
};
