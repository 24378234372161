import { ref, onValue } from 'firebase/database';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  CommentCategory,
  FilterCategory,
  IComment,
  SortOrder,
} from './__constants__/comment.types';
import { ICommentsData } from './__constants__/content.types';
import { useAuth } from '../../../contexts/AuthContext';
import { db } from '../../../firebase';

const functions = getFunctions();

const addCommentFn = httpsCallable(functions, 'addComment');
const addReplyFn = httpsCallable(functions, 'addReply');
const addRebuttalFn = httpsCallable(functions, 'addRebuttal');
const deleteCommentFn = httpsCallable(functions, 'deleteComment');
const deleteReplyFn = httpsCallable(functions, 'deleteReply');
const deleteRebuttalFn = httpsCallable(functions, 'deleteRebuttal');
const toggleUpvoteFn = httpsCallable(functions, 'toggleUpvote');

interface IDeleteTarget {
  type: 'comment' | 'reply' | 'rebuttal';
  commentId: string;
  replyId?: string;
  rebuttalId?: string;
}

export default function useCommentSystemContent(
  articleId: string,
  displayName: string,
): {
  commentsData: ICommentsData | null;
  handleAddComment: () => Promise<void>;
  handleDeleteComment: (commentId: string) => void;
  handleDeleteReply: (commentId: string, replyId: string) => void;
  handleDeleteRebuttal: (
    commentId: string,
    replyId: string,
    rebuttalId: string,
  ) => void;
  handleAddReply: (commentId: string) => Promise<void>;
  handleAddRebuttal: (commentId: string, replyId: string) => Promise<void>;
  handleUpvote: (
    commentId: string,
    replyId?: string,
    rebuttalId?: string,
  ) => Promise<void>;
  sortedComments: string[];
  newComment: string;
  setNewComment: React.Dispatch<React.SetStateAction<string>>;
  newReply: { [key: string]: string };
  setNewReply: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
  newRebuttal: { [key: string]: string };
  replyId: string;
  setReplyId: React.Dispatch<React.SetStateAction<string>>;
  setNewRebuttal: React.Dispatch<
    React.SetStateAction<{ [key: string]: string }>
  >;
  confirmDialogOpen: boolean;
  handleCloseConfirmDialog: () => void;
  handleDeleteConfirmation: () => Promise<void>;
  isCommentLoading: boolean;
  isReplyLoading: { [key: string]: boolean };
  isRebuttalLoading: { [key: string]: boolean };
  selectedCategory: CommentCategory;
  setSelectedCategory: React.Dispatch<React.SetStateAction<CommentCategory>>;
  filterCategory: FilterCategory;
  setFilterCategory: React.Dispatch<React.SetStateAction<FilterCategory>>;
  sortOrder: SortOrder;
  setSortOrder: React.Dispatch<React.SetStateAction<SortOrder>>;
} {
  const { user } = useAuth();
  const [commentsData, setCommentsData] = useState<ICommentsData | null>(null);
  const [filterCategory, setFilterCategory] = useState<FilterCategory>('all');
  const [sortOrder, setSortOrder] = useState<SortOrder>('recent');
  const [newComment, setNewComment] = useState<string>('');
  const [newReply, setNewReply] = useState<{ [key: string]: string }>({});
  const [newRebuttal, setNewRebuttal] = useState<{ [key: string]: string }>({});
  const [replyId, setReplyId] = useState<string>('');
  const [deleteTarget, setDeleteTarget] = useState<IDeleteTarget | null>(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [isCommentLoading, setIsCommentLoading] = useState<boolean>(false);
  const [isReplyLoading, setIsReplyLoading] = useState<{
    [key: string]: boolean;
  }>({});
  const [isRebuttalLoading, setIsRebuttalLoading] = useState<{
    [key: string]: boolean;
  }>({});
  const [selectedCategory, setSelectedCategory] =
    useState<CommentCategory>('other');

  useEffect(() => {
    const commentsRef = ref(db, `comments/${articleId}`);
    const unsubscribe = onValue(
      commentsRef,
      (snapshot) => {
        setCommentsData(snapshot.val());
      },
      (error) => {
        console.error('Error reading comments: ', error);
      },
    );
    return () => {
      unsubscribe();
    };
  }, [articleId]);

  const handleAddComment = async () => {
    if (newComment.trim()) {
      if (!user || user.isAnonymous) {
        console.error('Anonymous users cannot comment');
        return;
      }

      try {
        setIsCommentLoading(true);

        await addCommentFn({
          articleId,
          content: newComment,
          author: displayName || user.displayName || 'User',
          authorId: user.uid,
          profilePicture: user.photoURL || undefined,
          category: selectedCategory,
        });
        setNewComment('');
        setSelectedCategory('other');
      } catch (error) {
        console.error('Error adding comment:', error);
      } finally {
        setIsCommentLoading(false);
      }
    }
  };

  const handleUpvote = async (
    commentId: string,
    replyId?: string,
    rebuttalId?: string,
  ) => {
    try {
      if (!user) {
        console.error('No user logged in');
        return;
      }
      await toggleUpvoteFn({
        articleId,
        commentId,
        replyId,
        rebuttalId,
        userId: user.uid,
      });
    } catch (error) {
      console.error('Error upvoting:', error);
    }
  };

  const initiateDelete = useCallback((target: IDeleteTarget) => {
    setDeleteTarget(target);
    setConfirmDialogOpen(true);
  }, []);

  const handleCloseConfirmDialog = useCallback(() => {
    setConfirmDialogOpen(false);
    setDeleteTarget(null);
  }, []);

  const handleDeleteConfirmation = useCallback(async () => {
    if (!deleteTarget) return;

    const { type, commentId, replyId, rebuttalId } = deleteTarget;

    try {
      if (type === 'comment') {
        await deleteCommentFn({ articleId, commentId });
      } else if (type === 'reply' && replyId) {
        await deleteReplyFn({ articleId, commentId, replyId });
      } else if (type === 'rebuttal' && replyId && rebuttalId) {
        await deleteRebuttalFn({
          articleId,
          commentId,
          replyId,
          rebuttalId,
        });
      }
    } catch (error) {
      console.error(`Error deleting ${type}:`, error);
    }

    setConfirmDialogOpen(false);
    setDeleteTarget(null);
  }, [articleId, deleteTarget]);

  const handleDeleteComment = (commentId: string) => {
    initiateDelete({ type: 'comment', commentId });
  };

  const handleDeleteReply = (commentId: string, replyId: string) => {
    initiateDelete({ type: 'reply', commentId, replyId });
  };

  const handleDeleteRebuttal = (
    commentId: string,
    replyId: string,
    rebuttalId: string,
  ) => {
    initiateDelete({ type: 'rebuttal', commentId, replyId, rebuttalId });
  };

  const handleAddReply = async (commentId: string) => {
    if (newReply[commentId]?.trim()) {
      if (!user || user.isAnonymous) {
        console.error('Anonymous users cannot reply');
        return;
      }

      try {
        setIsReplyLoading((prev) => ({ ...prev, [commentId]: true }));
        await addReplyFn({
          articleId,
          commentId,
          content: newReply[commentId],
          author: displayName || user.displayName || 'User',
          authorId: user.uid,
          profilePicture: user.photoURL || undefined,
        });
        setNewReply({ ...newReply, [commentId]: '' });
      } catch (error) {
        console.error('Error adding reply:', error);
      } finally {
        setIsReplyLoading((prev) => ({ ...prev, [commentId]: false }));
      }
    }
  };

  const handleAddRebuttal = async (commentId: string, replyId: string) => {
    if (newRebuttal[replyId]?.trim()) {
      if (!user || user.isAnonymous) {
        console.error('Anonymous users cannot add rebuttals');
        return;
      }

      try {
        setIsRebuttalLoading((prev) => ({ ...prev, [replyId]: true }));
        await addRebuttalFn({
          articleId,
          commentId,
          replyId,
          content: newRebuttal[replyId],
          author: displayName || user.displayName || 'User',
          authorId: user.uid,
          profilePicture: user.photoURL || undefined,
        });
        setNewRebuttal({ ...newRebuttal, [replyId]: '' });
      } catch (error) {
        console.error('Error adding rebuttal:', error);
      } finally {
        setIsRebuttalLoading((prev) => ({ ...prev, [replyId]: false }));
      }
    }
  };

  const sortedComments = useMemo(() => {
    if (!commentsData) return [];

    const commentEntries = Object.entries(commentsData);

    const filteredEntries =
      filterCategory === 'all'
        ? commentEntries
        : commentEntries.filter(([, comment]: [string, IComment]) =>
            comment.category ? comment.category === filterCategory : false,
          );

    const calculateTrendingScore = (comment: IComment): number => {
      const upvotes = Object.keys(comment.upvotes || {}).length;
      const replies = Object.keys(comment.replies || {}).length;
      const commentDate = new Date(comment.date);
      const ageInHours =
        (Date.now() - commentDate.getTime()) / (1000 * 60 * 60);
      const nonNegativeAge = Math.max(0, ageInHours);
      return upvotes * 2 + replies * 1.5 - nonNegativeAge * 0.5;
    };

    const sorted = filteredEntries.sort(
      ([, commentA]: [string, IComment], [, commentB]: [string, IComment]) => {
        switch (sortOrder) {
          case 'recent':
            return (
              new Date(commentB.date).getTime() -
              new Date(commentA.date).getTime()
            );
          case 'upvoted':
            return (
              Object.keys(commentB.upvotes || {}).length -
              Object.keys(commentA.upvotes || {}).length
            );
          case 'replies':
            return (
              Object.keys(commentB.replies || {}).length -
              Object.keys(commentA.replies || {}).length
            );
          case 'trending':
            return (
              calculateTrendingScore(commentB) -
              calculateTrendingScore(commentA)
            );
          default:
            return 0;
        }
      },
    );

    return sorted.map(([commentId]) => commentId);
  }, [commentsData, filterCategory, sortOrder]);

  return {
    commentsData,
    handleAddComment,
    handleDeleteComment,
    handleDeleteReply,
    handleDeleteRebuttal,
    handleAddReply,
    handleAddRebuttal,
    handleUpvote,
    sortedComments,
    newComment,
    setNewComment,
    newReply,
    setNewReply,
    newRebuttal,
    replyId,
    setReplyId,
    setNewRebuttal,
    confirmDialogOpen,
    handleCloseConfirmDialog,
    handleDeleteConfirmation,
    isCommentLoading,
    isReplyLoading,
    isRebuttalLoading,
    selectedCategory,
    setSelectedCategory,
    filterCategory,
    setFilterCategory,
    sortOrder,
    setSortOrder,
  };
}
