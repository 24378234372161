import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";

import {
  checkUsernameUnique,
  isValidUsername,
  useAuth,
} from "../../../contexts/AuthContext";

interface IUpdateUsernameFormProps {
  onUsernameUpdated: () => void;
}

const UpdateUsernameForm: React.FC<IUpdateUsernameFormProps> = ({
  onUsernameUpdated,
}) => {
  const [username, setUsername] = useState("");
  const { user, updateUsername } = useAuth();

  const [error, setError] = useState("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");

    if (!isValidUsername(username)) {
      setError("Username can only contain alphabets and numbers");
      return;
    }

    try {
      const isUnique = await checkUsernameUnique(username);
      if (user) {
        if (isUnique) {
          await updateUsername(username);
          onUsernameUpdated();
        } else {
          setError("Username already exists");
        }
      } else {
        setError("User is not logged in");
      }
    } catch (error) {
      setError("Failed to update username");
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      {error && <div className="alert alert-danger">{error}</div>}
      <Form.Group controlId="username">
        <Form.Label>Username</Form.Label>
        <Form.Control
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
      </Form.Group>
      <Button type="submit" className="mt-3">
        Update Username
      </Button>
    </Form>
  );
};

export default UpdateUsernameForm;
