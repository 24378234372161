import React from 'react';
import { Col } from 'react-bootstrap';

import NavbarFull from './Navigation/navbarFull';
import '../../../../styles/brutalist.scss';
import './leftSidePanel.scss';

export default function LeftSidePanel(): JSX.Element {
  return (
    <Col
      xl={2}
      lg={3}
      md={4}
      sm={3}
      className='d-none d-sm-block left-side-panel'
    >
      <NavbarFull />
    </Col>
  );
}
