import { httpsCallable } from 'firebase/functions';
import React, { useState } from 'react';
import {
  Alert as BootstrapAlert,
  Button,
  Card,
  Form,
  Spinner,
} from 'react-bootstrap';

import { AlertType } from './types';
import { useAuth } from '../../../contexts/AuthContext';
import { functions } from '../../../firebase';

// Define function outside component to prevent recreation on each render
const sendAdminAlertFunction = httpsCallable(functions, 'sendAdminAlert');

interface ISendAdminAlertFormData {
  title: string;
  message: string;
  type: AlertType.ADMIN_CRITICAL | AlertType.ADMIN_GENERAL;
  link?: string;
}

const initialFormData: ISendAdminAlertFormData = {
  title: '',
  message: '',
  type: AlertType.ADMIN_GENERAL,
  link: '',
};

const SendAdminAlert: React.FC = () => {
  const { user } = useAuth();
  const [formData, setFormData] =
    useState<ISendAdminAlertFormData>(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alertState, setAlertState] = useState<{
    show: boolean;
    variant: 'success' | 'danger';
    message: string;
  }>({
    show: false,
    variant: 'success',
    message: '',
  });

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!user) return;

    // Validation
    if (!formData.title.trim() || !formData.message.trim()) {
      setAlertState({
        show: true,
        variant: 'danger',
        message: 'Title and message are required.',
      });
      return;
    }

    setIsSubmitting(true);
    setAlertState({ show: false, variant: 'success', message: '' });

    try {
      // Send alert to all users

      const result = await sendAdminAlertFunction({
        ...formData,
        adminId: user.uid,
      });

      const data = result.data as {
        success: boolean;
        message: string;
        data?: { alertCount: number };
        error?: string;
      };

      if (data.success) {
        setAlertState({
          show: true,
          variant: 'success',
          message: `Alert sent successfully to ${data.data?.alertCount || 'all'} users!`,
        });
        // Reset form after successful submission
        setFormData(initialFormData);
      } else {
        throw new Error(data.error || data.message || 'Failed to send alert');
      }
    } catch (error: unknown) {
      console.error('Error sending admin alert:', error);

      // More detailed error logging to help diagnose the issue
      if (error instanceof Error) {
        console.error('Error details:', {
          name: error.name,
          message: error.message,
          stack: error.stack,
        });
      } else {
        console.error('Unknown error type:', typeof error);
      }

      setAlertState({
        show: true,
        variant: 'danger',
        message:
          error instanceof Error
            ? error.message
            : 'An error occurred while sending the alert',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Card className='mb-4'>
      <Card.Header>
        <h4>Send Alert to All Users</h4>
      </Card.Header>
      <Card.Body>
        {alertState.show && (
          <BootstrapAlert
            variant={alertState.variant}
            dismissible
            onClose={() => setAlertState((prev) => ({ ...prev, show: false }))}
          >
            {alertState.message}
          </BootstrapAlert>
        )}

        <Form onSubmit={handleSubmit}>
          <Form.Group className='mb-3'>
            <Form.Label>Alert Type</Form.Label>
            <Form.Select
              name='type'
              value={formData.type}
              onChange={handleInputChange}
              required
            >
              <option value={AlertType.ADMIN_GENERAL}>General Update</option>
              <option value={AlertType.ADMIN_CRITICAL}>Critical Alert</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Title</Form.Label>
            <Form.Control
              type='text'
              name='title'
              value={formData.title}
              onChange={handleInputChange}
              placeholder='Enter alert title'
              required
              maxLength={100}
            />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Message</Form.Label>
            <Form.Control
              as='textarea'
              name='message'
              value={formData.message}
              onChange={handleInputChange}
              placeholder='Enter alert message'
              required
              rows={4}
              maxLength={500}
            />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Link (Optional)</Form.Label>
            <Form.Control
              type='url'
              name='link'
              value={formData.link}
              onChange={handleInputChange}
              placeholder='https://example.com'
            />
            <Form.Text className='text-muted'>
              Add a URL that users can click to learn more
            </Form.Text>
          </Form.Group>

          <Button
            variant='primary'
            type='submit'
            disabled={isSubmitting}
            className='mt-3'
          >
            {isSubmitting ? (
              <>
                <Spinner
                  as='span'
                  animation='border'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                  className='me-2'
                />
                Sending...
              </>
            ) : (
              'Send Alert to All Users'
            )}
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default SendAdminAlert;
