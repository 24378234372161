import React from 'react';
import './BrutalistUI.scss';
import {
  FaChevronLeft,
  FaChevronRight,
  FaChevronUp,
  FaChevronDown,
} from 'react-icons/fa';

type ArrowDirection = 'left' | 'right' | 'up' | 'down';

interface IBrutalistArrowButtonProps {
  direction: ArrowDirection;
  onClick: () => void;
  className?: string;
  label?: string;
  style?: React.CSSProperties;
  isNav?: boolean;
  iconSize?: number;
}

const BrutalistArrowButton: React.FC<IBrutalistArrowButtonProps> = ({
  direction,
  onClick,
  className = '',
  label,
  style,
  isNav = false,
  iconSize = 24,
}) => {
  const getIcon = () => {
    switch (direction) {
      case 'left':
        return <FaChevronLeft size={iconSize} />;
      case 'right':
        return <FaChevronRight size={iconSize} />;
      case 'up':
        return <FaChevronUp size={iconSize} />;
      case 'down':
        return <FaChevronDown size={iconSize} />;
    }
  };

  const baseClass = isNav
    ? 'brutalist-arrow-button brutalist-arrow-button--nav'
    : 'brutalist-arrow-button';
  const directionClass = `brutalist-arrow-button--${direction}`;

  return (
    <button
      className={`${baseClass} ${directionClass} ${className}`}
      onClick={onClick}
      style={style}
    >
      {label && direction === 'down' && (
        <span className='labeled-arrow-text'>{label}</span>
      )}
      {getIcon()}
      {label && direction === 'up' && (
        <span className='labeled-arrow-text'>{label}</span>
      )}
    </button>
  );
};

export default BrutalistArrowButton;
