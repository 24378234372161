import { httpsCallable, getFunctions } from 'firebase/functions';
import { useState, useCallback } from 'react';

import { IFollowUser } from './constants/profileScreen.types';
import app from '../../../firebase';

// Initialize Firebase Functions
const functions = getFunctions(app);

interface IUseFollowModalProps {
  profileId: string;
}

export const useFollowModal = ({
  profileId,
}: IUseFollowModalProps): {
  showFollowersModal: boolean;
  showFollowingModal: boolean;
  followers: IFollowUser[];
  following: IFollowUser[];
  isLoadingFollowers: boolean;
  isLoadingFollowing: boolean;
  error: string | null;
  openFollowersModal: () => void;
  openFollowingModal: () => void;
  closeFollowersModal: () => void;
  closeFollowingModal: () => void;
} => {
  const [showFollowersModal, setShowFollowersModal] = useState<boolean>(false);
  const [showFollowingModal, setShowFollowingModal] = useState<boolean>(false);
  const [followers, setFollowers] = useState<IFollowUser[]>([]);
  const [following, setFollowing] = useState<IFollowUser[]>([]);
  const [isLoadingFollowers, setIsLoadingFollowers] = useState<boolean>(false);
  const [isLoadingFollowing, setIsLoadingFollowing] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // Fetch followers
  const fetchFollowers = useCallback(async () => {
    if (!profileId) return;

    setIsLoadingFollowers(true);
    setError(null);

    try {
      const getFollowersFn = httpsCallable(functions, 'getFollowers');
      const result = await getFollowersFn({ userId: profileId });
      setFollowers(result.data as IFollowUser[]);
    } catch (err) {
      console.error('Error fetching followers:', err);
      setError('Failed to load followers');
    } finally {
      setIsLoadingFollowers(false);
    }
  }, [profileId]);

  // Fetch following
  const fetchFollowing = useCallback(async () => {
    if (!profileId) return;

    setIsLoadingFollowing(true);
    setError(null);

    try {
      const getFollowingFn = httpsCallable(functions, 'getFollowing');
      const result = await getFollowingFn({ userId: profileId });
      setFollowing(result.data as IFollowUser[]);
    } catch (err) {
      console.error('Error fetching following:', err);
      setError('Failed to load following');
    } finally {
      setIsLoadingFollowing(false);
    }
  }, [profileId]);

  // Modal controls
  const openFollowersModal = useCallback(() => {
    fetchFollowers();
    setShowFollowersModal(true);
  }, [fetchFollowers]);

  const openFollowingModal = useCallback(() => {
    fetchFollowing();
    setShowFollowingModal(true);
  }, [fetchFollowing]);

  const closeFollowersModal = useCallback(() => {
    setShowFollowersModal(false);
  }, []);

  const closeFollowingModal = useCallback(() => {
    setShowFollowingModal(false);
  }, []);

  return {
    showFollowersModal,
    showFollowingModal,
    followers,
    following,
    isLoadingFollowers,
    isLoadingFollowing,
    error,
    openFollowersModal,
    openFollowingModal,
    closeFollowersModal,
    closeFollowingModal,
  };
};
