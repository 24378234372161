import { httpsCallable, getFunctions } from 'firebase/functions';
import { useState, useEffect, useCallback } from 'react';

import { useAuth } from '../../../contexts/AuthContext';
import app from '../../../firebase';

// Define Firebase error type
interface IFirebaseError {
  code?: string;
  message?: string;
  details?: unknown;
}

// Initialize Firebase Functions
const functions = getFunctions(app);

interface IUseFollowSystemProps {
  profileId: string;
  isOwnProfile: boolean;
}

interface IFollowSystemResult {
  isFollowing: boolean;
  isLoading: boolean;
  followersCount: number;
  followingCount: number;
  followUser: () => Promise<void>;
  unfollowUser: () => Promise<void>;
  setInitialCounts: (followers: number, following: number) => Promise<void>;
  error: string | null;
}

export const useFollowSystem = ({
  profileId,
  isOwnProfile,
}: IUseFollowSystemProps): IFollowSystemResult => {
  const { user } = useAuth();
  const [isFollowing, setIsFollowing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [followersCount, setFollowersCount] = useState<number>(0);
  const [followingCount, setFollowingCount] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);

  // Check if current user is following the profile
  useEffect(() => {
    const checkFollowStatus = async (): Promise<void> => {
      if (isOwnProfile || !user || !profileId) return;

      try {
        setIsLoading(true);
        const checkFollowStatusFn = httpsCallable(
          functions,
          'checkFollowStatus',
        );
        const result = await checkFollowStatusFn({ targetUserId: profileId });
        const data = result.data as { isFollowing: boolean };
        setIsFollowing(data.isFollowing);
      } catch (err: unknown) {
        const firebaseErr = err as IFirebaseError;
        console.error('Error checking follow status:', firebaseErr);
        // Extract more detailed error message if available
        let errorMessage = 'Failed to check follow status';
        if (firebaseErr.message) {
          errorMessage = firebaseErr.message;
        }
        // Check for specific Firebase error types
        if (firebaseErr.code === 'functions/not-found') {
          errorMessage = 'User profile not found. Please try again later.';
        } else if (firebaseErr.code === 'functions/internal') {
          errorMessage = 'Server error. Please try again later.';
        }
        setError(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };

    checkFollowStatus();
  }, [profileId, user, isOwnProfile]);

  // Follow a user
  const followUser = useCallback(async (): Promise<void> => {
    if (!user || !profileId) return;

    setIsLoading(true);
    setError(null);

    try {
      const followUserFn = httpsCallable(functions, 'followUser');
      await followUserFn({ targetUserId: profileId });
      setIsFollowing(true);

      // Update follower count locally
      setFollowersCount((prev) => prev + 1);

      // Get the updated counts from the server to ensure accuracy
      try {
        const getFollowCountsFn = httpsCallable(functions, 'getFollowCounts');
        const result = await getFollowCountsFn({ userId: profileId });
        const data = result.data as { followers: number; following: number };
        setFollowersCount(data.followers);
      } catch (countErr: unknown) {
        console.error('Error fetching updated follow counts:', countErr);
      }
    } catch (err: unknown) {
      const firebaseErr = err as IFirebaseError;
      console.error('Error following user:', firebaseErr);
      // Extract more detailed error message if available
      let errorMessage = 'Failed to follow user';
      if (firebaseErr.message) {
        errorMessage = firebaseErr.message;
      }
      // Check for specific Firebase error types
      if (firebaseErr.code === 'functions/not-found') {
        errorMessage = 'User profile not found. Please try again later.';
      } else if (firebaseErr.code === 'functions/internal') {
        errorMessage = 'Server error. Please try again later.';
      }
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  }, [profileId, user]);

  // Unfollow a user
  const unfollowUser = useCallback(async (): Promise<void> => {
    if (!user || !profileId) return;

    setIsLoading(true);
    setError(null);

    try {
      const unfollowUserFn = httpsCallable(functions, 'unfollowUser');
      await unfollowUserFn({ targetUserId: profileId });
      setIsFollowing(false);

      // Update follower count locally
      setFollowersCount((prev) => Math.max(0, prev - 1));

      // Get the updated counts from the server to ensure accuracy
      try {
        const getFollowCountsFn = httpsCallable(functions, 'getFollowCounts');
        const result = await getFollowCountsFn({ userId: profileId });
        const data = result.data as { followers: number; following: number };
        setFollowersCount(data.followers);
      } catch (countErr: unknown) {
        console.error('Error fetching updated follow counts:', countErr);
      }
    } catch (err: unknown) {
      const firebaseErr = err as IFirebaseError;
      console.error('Error unfollowing user:', firebaseErr);
      // Extract more detailed error message if available
      let errorMessage = 'Failed to unfollow user';
      if (firebaseErr.message) {
        errorMessage = firebaseErr.message;
      }
      // Check for specific Firebase error types
      if (firebaseErr.code === 'functions/not-found') {
        errorMessage = 'User profile not found. Please try again later.';
      } else if (firebaseErr.code === 'functions/internal') {
        errorMessage = 'Server error. Please try again later.';
      }
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  }, [profileId, user]);

  // Set initial followers and following counts
  const setInitialCounts = useCallback(
    async (followers: number, following: number): Promise<void> => {
      // Always set the initial counts from the user activity data
      setFollowersCount(followers);
      setFollowingCount(following);

      // If we have a profileId and the counts are 0, fetch the real counts
      if (profileId && followers === 0 && following === 0) {
        try {
          const getFollowCountsFn = httpsCallable(functions, 'getFollowCounts');
          const result = await getFollowCountsFn({ userId: profileId });
          const data = result.data as { followers: number; following: number };

          // Update the counts with the fetched values
          setFollowersCount(data.followers);
          setFollowingCount(data.following);
        } catch (error: unknown) {
          console.error('Error fetching initial follow counts:', error);
        }
      }
    },
    [profileId],
  );

  return {
    isFollowing,
    isLoading,
    followersCount,
    followingCount,
    followUser,
    unfollowUser,
    setInitialCounts,
    error,
  };
};
