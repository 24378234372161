import React from 'react';
import { FaMoon, FaSun } from 'react-icons/fa';

import { BrutalistButton } from '../../../SharedComponents/BrutalistUI';
import { IUserSettings } from '../constants/profileScreen.types';

interface ISettingsSectionProps {
  userSettings: IUserSettings;
  toggleDarkMode: () => Promise<void>;
  darkMode: boolean;
  handleLogout: () => Promise<void>;
  isOwnProfile: boolean;
}

const SettingsSection: React.FC<ISettingsSectionProps> = ({
  userSettings,
  toggleDarkMode,
  darkMode,
  handleLogout,
  isOwnProfile,
}) => {
  return (
    <div className='section-content'>
      <div className='settings-grid'>
        <div className='setting-group'>
          <h3>Appearance</h3>
          <div className='setting-item'>
            <div className='setting-label'>
              <span>Dark Mode</span>
              <button onClick={toggleDarkMode} className='dark-mode-button'>
                {darkMode ? <FaSun /> : <FaMoon />}
              </button>
            </div>
          </div>
          {/* TODO: Add more appearance settings */}
        </div>

        <div className='setting-group'>
          <h3>Notifications</h3>
          <div className='setting-item'>
            <div className='setting-label'>
              <span id='email-notifications-label'>Email Notifications</span>
              <div className='toggle-switch'>
                <input
                  id='email-notifications'
                  type='checkbox'
                  aria-labelledby='email-notifications-label'
                  checked={
                    userSettings.notificationPreferences.emailNotifications
                  }
                  onChange={() => {
                    /* TODO: Implement notification toggle */
                  }}
                />
                <span className='slider' />
              </div>
            </div>
          </div>
          <div className='setting-item'>
            <div className='setting-label'>
              <span id='comment-replies-label'>Comment Replies</span>
              <div className='toggle-switch'>
                <input
                  id='comment-replies'
                  type='checkbox'
                  aria-labelledby='comment-replies-label'
                  checked={userSettings.notificationPreferences.commentReplies}
                  onChange={() => {
                    /* TODO: Implement notification toggle */
                  }}
                />
                <span className='slider' />
              </div>
            </div>
          </div>
          <div className='setting-item'>
            <div className='setting-label'>
              <span id='mentions-label'>Mentions</span>
              <div className='toggle-switch'>
                <input
                  id='mentions'
                  type='checkbox'
                  aria-labelledby='mentions-label'
                  checked={userSettings.notificationPreferences.mentions}
                  onChange={() => {
                    /* TODO: Implement notification toggle */
                  }}
                />
                <span className='slider' />
              </div>
            </div>
          </div>
          <div className='setting-item'>
            <div className='setting-label'>
              <span id='new-followers-label'>New Followers</span>
              <div className='toggle-switch'>
                <input
                  id='new-followers'
                  type='checkbox'
                  aria-labelledby='new-followers-label'
                  checked={userSettings.notificationPreferences.newFollowers}
                  onChange={() => {
                    /* TODO: Implement notification toggle */
                  }}
                />
                <span className='slider' />
              </div>
            </div>
          </div>
        </div>

        <div className='setting-group'>
          <h3>Privacy</h3>
          <div className='setting-item'>
            <div className='setting-label'>
              <span id='profile-visibility-label'>Profile Visibility</span>
              <select
                id='profile-visibility'
                aria-labelledby='profile-visibility-label'
                value={userSettings.privacySettings.profileVisibility}
                onChange={() => {
                  /* TODO: Implement privacy setting change */
                }}
              >
                <option value='public'>Public</option>
                <option value='private'>Private</option>
              </select>
            </div>
          </div>
          <div className='setting-item'>
            <div className='setting-label'>
              <span id='show-activity-label'>Show Activity</span>
              <div className='toggle-switch'>
                <input
                  id='show-activity'
                  type='checkbox'
                  aria-labelledby='show-activity-label'
                  checked={userSettings.privacySettings.showActivity}
                  onChange={() => {
                    /* TODO: Implement privacy toggle */
                  }}
                />
                <span className='slider' />
              </div>
            </div>
          </div>
          <div className='setting-item'>
            <div className='setting-label'>
              <span id='show-followers-label'>Show Followers</span>
              <div className='toggle-switch'>
                <input
                  id='show-followers'
                  type='checkbox'
                  aria-labelledby='show-followers-label'
                  checked={userSettings.privacySettings.showFollowers}
                  onChange={() => {
                    /* TODO: Implement privacy toggle */
                  }}
                />
                <span className='slider' />
              </div>
            </div>
          </div>
        </div>
      </div>

      {isOwnProfile && (
        <BrutalistButton onClick={handleLogout} className='logout-button'>
          Log Out
        </BrutalistButton>
      )}
    </div>
  );
};

export default SettingsSection;
