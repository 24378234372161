import React from 'react';
import { Modal } from 'react-bootstrap';

import BrutalistButton from './BrutalistButton';
import './BrutalistUI.scss';

interface IBrutalistModalProps {
  show: boolean;
  onHide: () => void;
  title: string;
  children: React.ReactNode;
  className?: string;
}

const BrutalistModal: React.FC<IBrutalistModalProps> = ({
  show,
  onHide,
  title,
  children,
  className = '',
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className={`brutalist-modal ${className}`}
    >
      <Modal.Header className='brutalist-border'>
        <Modal.Title className='brutalist-title-dark'>{title}</Modal.Title>
        <BrutalistButton
          onClick={onHide}
          className='close-button'
          btnSize='small'
        >
          ✕
        </BrutalistButton>
      </Modal.Header>
      <Modal.Body className='brutalist-border'>{children}</Modal.Body>
    </Modal>
  );
};

export default BrutalistModal;
