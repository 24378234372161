// commentInputs.tsx
import React from 'react';
import { Form } from 'react-bootstrap';

import CharacterCounter from './characterCounter';
import { ICommentStyles } from './commentTypes';
import {
  BrutalistButton,
  BrutalistLoading,
} from '../../../SharedComponents/BrutalistUI';

/**
 * Component for adding a reply to a comment
 */
export const ReplyBox: React.FC<{
  commentId: string;
  newReply: { [key: string]: string };
  setNewReply: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
  isReplyLoading: { [key: string]: boolean };
  handleAddReply: (commentId: string) => void;
  setShowReplyBox: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  showReplyBox: { [key: string]: boolean };
  styles: ICommentStyles;
}> = ({
  commentId,
  newReply,
  setNewReply,
  isReplyLoading,
  handleAddReply,
  setShowReplyBox,
  showReplyBox,
  styles,
}) => {
  return (
    <div className='brutalist-reply-box'>
      <Form.Control
        as='textarea'
        value={newReply[commentId] || ''}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          setNewReply({
            ...newReply,
            [commentId]: e.target.value,
          })
        }
        placeholder='Write a reply...'
        maxLength={250}
        style={styles.replyBox}
        disabled={isReplyLoading[commentId]}
      />
      <CharacterCounter
        currentCount={(newReply[commentId] || '').length}
        limit={250}
      />

      {isReplyLoading[commentId] ? (
        <BrutalistLoading text='SUBMITTING REPLY' size='small' />
      ) : (
        <div className='brutalist-button-group'>
          <BrutalistButton
            variant='primary'
            size='sm'
            onClick={() => handleAddReply(commentId)}
          >
            Reply
          </BrutalistButton>
          <BrutalistButton
            variant='secondary'
            size='sm'
            onClick={() =>
              setShowReplyBox({
                ...showReplyBox,
                [commentId]: false,
              })
            }
          >
            Cancel
          </BrutalistButton>
        </div>
      )}
    </div>
  );
};

/**
 * Component for adding a rebuttal to a reply
 */
export const RebuttalBox: React.FC<{
  replyId: string;
  commentId: string;
  newRebuttal: { [key: string]: string };
  setNewRebuttal: React.Dispatch<
    React.SetStateAction<{ [key: string]: string }>
  >;
  isRebuttalLoading: { [key: string]: boolean };
  handleAddRebuttal: (commentId: string, replyId: string) => void;
  setShowRebuttalBox: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  showRebuttalBox: { [key: string]: boolean };
  styles: ICommentStyles;
}> = ({
  replyId,
  commentId,
  newRebuttal,
  setNewRebuttal,
  isRebuttalLoading,
  handleAddRebuttal,
  setShowRebuttalBox,
  showRebuttalBox,
  styles,
}) => {
  return (
    <div className='brutalist-rebuttal-box'>
      <Form.Control
        as='textarea'
        value={newRebuttal[replyId] || ''}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          setNewRebuttal({
            ...newRebuttal,
            [replyId]: e.target.value,
          })
        }
        placeholder='Write a rebuttal...'
        maxLength={250}
        style={styles.replyBox}
        disabled={isRebuttalLoading[replyId]}
      />
      <CharacterCounter
        currentCount={(newRebuttal[replyId] || '').length}
        limit={250}
      />

      {isRebuttalLoading[replyId] ? (
        <BrutalistLoading text='SUBMITTING REBUTTAL' size='small' />
      ) : (
        <div className='brutalist-button-group'>
          <BrutalistButton
            variant='primary'
            size='sm'
            onClick={() => handleAddRebuttal(commentId, replyId)}
          >
            Submit
          </BrutalistButton>
          <BrutalistButton
            variant='secondary'
            size='sm'
            onClick={() =>
              setShowRebuttalBox({
                ...showRebuttalBox,
                [replyId]: false,
              })
            }
          >
            Cancel
          </BrutalistButton>
        </div>
      )}
    </div>
  );
};
