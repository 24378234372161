import { httpsCallable } from 'firebase/functions';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Alert as BootstrapAlert,
  Badge,
  Button,
  Card,
  Spinner,
  Table,
  Row,
  Col,
} from 'react-bootstrap';

import DeleteAdminAlerts from './DeleteAdminAlerts';
import SendAdminAlert from './SendAdminAlert';
import { IAlert, AlertType, IAlertsState } from './types';
import { useAuth } from '../../../contexts/AuthContext';
import { functions } from '../../../firebase';

// Define functions outside component to prevent recreation on each render
const getAlertsFunction = httpsCallable(functions, 'getAlerts');
const markAlertReadFunction = httpsCallable(functions, 'markAlertRead');
const markAllAlertsReadFunction = httpsCallable(functions, 'markAllAlertsRead');

const AlertManagement: React.FC = () => {
  const { user } = useAuth();
  const [alertsState, setAlertsState] = useState<IAlertsState>({
    alerts: [],
    loading: false,
    error: '',
    hasMore: false,
    lastVisible: null,
  });
  // Use a ref to track loading state without creating a dependency
  const loadingRef = useRef(false);

  // Format timestamp to readable date
  const formatDate = (timestamp: { seconds: number; nanoseconds: number }) => {
    if (!timestamp) return 'N/A';
    return new Date(timestamp.seconds * 1000).toLocaleString();
  };

  // Get alert type badge color
  const getAlertTypeColor = (type: AlertType) => {
    switch (type) {
      case AlertType.WELCOME:
        return 'success';
      case AlertType.ADMIN_CRITICAL:
        return 'danger';
      case AlertType.ADMIN_GENERAL:
        return 'primary';
      case AlertType.COMMENT_REPLY:
        return 'info';
      case AlertType.TRENDING_DISCUSSION:
        return 'warning';
      case AlertType.BREAKING_NEWS:
        return 'danger';
      case AlertType.SYSTEM_NOTIFICATION:
        return 'secondary';
      default:
        return 'primary';
    }
  };

  // Format alert type to readable form
  const formatAlertType = (type: string) => {
    return type
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  // Function to fetch alerts
  const fetchAlerts = useCallback(
    async (loadMore = false) => {
      if (!user) return;

      // Use the ref to check and update loading state
      if (loadingRef.current) return;
      loadingRef.current = true;

      setAlertsState((prev) => ({
        ...prev,
        loading: true,
        error: '',
      }));

      try {
        const result = await getAlertsFunction({
          userId: user.uid,
          limit: 10,
          startAfter: loadMore ? alertsState.lastVisible : null,
        });

        const data = result.data as {
          alerts: IAlert[];
          lastVisible: string | null;
          hasMore: boolean;
        };

        setAlertsState((prev) => ({
          alerts: loadMore ? [...prev.alerts, ...data.alerts] : data.alerts,
          loading: false,
          error: '',
          hasMore: data.hasMore,
          lastVisible: data.lastVisible,
        }));
        // Reset loading ref after state update
        loadingRef.current = false;
      } catch (err: unknown) {
        setAlertsState((prev) => ({
          ...prev,
          loading: false,
          error: 'Failed to load alerts. Please try again.',
        }));
        // Reset loading ref in case of error
        loadingRef.current = false;
      }
    },
    [user, alertsState.lastVisible],
  );

  // Load alerts on component mount
  useEffect(() => {
    if (user) {
      fetchAlerts();
    }
  }, [fetchAlerts, user]);

  // Mark alert as read
  const handleMarkAsRead = async (alertId: string) => {
    if (!user) return;

    try {
      await markAlertReadFunction({
        userId: user.uid,
        alertId,
      });

      // Update local state to reflect change
      setAlertsState((prev) => ({
        ...prev,
        alerts: prev.alerts.map((alert) =>
          alert.id === alertId ? { ...alert, read: true } : alert,
        ),
      }));
    } catch (err: unknown) {
      console.error('Error marking alert as read:', err);
    }
  };

  // Mark all alerts as read
  const handleMarkAllAsRead = async () => {
    if (!user) return;

    try {
      await markAllAlertsReadFunction({
        userId: user.uid,
      });

      // Update local state to reflect all alerts as read
      setAlertsState((prev) => ({
        ...prev,
        alerts: prev.alerts.map((alert) => ({ ...alert, read: true })),
      }));
    } catch (err: unknown) {
      console.error('Error marking all alerts as read:', err);
    }
  };

  // Load more alerts
  const handleLoadMore = () => {
    fetchAlerts(true);
  };

  // Count unread alerts
  const unreadCount =
    alertsState.alerts?.filter((alert) => !alert.read)?.length || 0;

  return (
    <div className='alert-management'>
      <Row>
        <Col md={6}>
          <SendAdminAlert />
          <DeleteAdminAlerts />
        </Col>
        <Col md={6}>
          <Card className='mb-4'>
            <Card.Header className='d-flex justify-content-between align-items-center'>
              <h4>My Alerts</h4>
              <Button
                variant='primary'
                onClick={handleMarkAllAsRead}
                disabled={unreadCount === 0}
                size='sm'
              >
                Mark All as Read
              </Button>
            </Card.Header>
            <Card.Body>
              {alertsState.error && (
                <BootstrapAlert variant='danger'>
                  {alertsState.error}
                </BootstrapAlert>
              )}

              {alertsState.loading &&
              (!alertsState.alerts || alertsState.alerts.length === 0) ? (
                <div className='text-center my-4'>
                  <Spinner animation='border' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                  </Spinner>
                </div>
              ) : !alertsState.alerts || alertsState.alerts.length === 0 ? (
                <BootstrapAlert variant='info'>No alerts found.</BootstrapAlert>
              ) : (
                <>
                  <Table striped bordered hover responsive>
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>Message</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {alertsState.alerts?.map((alert) => (
                        <tr
                          key={alert.id}
                          className={!alert.read ? 'table-active' : ''}
                        >
                          <td>
                            <Badge
                              bg={getAlertTypeColor(alert.type as AlertType)}
                            >
                              {formatAlertType(alert.type)}
                            </Badge>
                          </td>
                          <td>{alert.message}</td>
                          <td>{formatDate(alert.timestamp)}</td>
                          <td>
                            <Badge bg={alert.read ? 'secondary' : 'primary'}>
                              {alert.read ? 'Read' : 'Unread'}
                            </Badge>
                          </td>
                          <td>
                            <Button
                              variant='outline-primary'
                              size='sm'
                              onClick={() => handleMarkAsRead(alert.id)}
                              disabled={alert.read}
                            >
                              Mark as Read
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  {alertsState.hasMore && (
                    <div className='text-center mt-3'>
                      <Button
                        variant='outline-primary'
                        onClick={handleLoadMore}
                        disabled={alertsState.loading}
                      >
                        {alertsState.loading ? (
                          <>
                            <Spinner
                              as='span'
                              animation='border'
                              size='sm'
                              role='status'
                              aria-hidden='true'
                              className='me-2'
                            />
                            Loading...
                          </>
                        ) : (
                          'Load More'
                        )}
                      </Button>
                    </div>
                  )}
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AlertManagement;
