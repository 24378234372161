import React from 'react';
import { FaMoon, FaSun } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';

import { ProfileSections } from './profileSections';
import { useMuteStatus } from './useMuteStatus';
import { useProfileScreenContent } from './useProfileScreenContent';
import { useProfileScreenPresentation } from './useProfileScreenPresentation';
import { useProfileSectionsData } from './useProfileSectionsData';
import { useAuth } from '../../../contexts/AuthContext';
import {
  BrutalistArrowButton,
  BrutalistButton,
  BrutalistLoading,
} from '../../SharedComponents/BrutalistUI';
import './profileScreen.scss';

interface IProfileScreenProps {
  userId?: string;
}

interface IUserProfile {
  isOwnProfile: boolean;
  displayName: string;
  // Add other profile properties as needed
}

const ProfileHeader: React.FC<{
  userProfile: IUserProfile;
  isMuted: boolean;
  muteStatusLoading: boolean;
  handleMuteToggle: () => Promise<void>;
  darkMode: boolean;
  toggleDarkMode: () => void;
}> = ({
  userProfile,
  isMuted,
  muteStatusLoading,
  handleMuteToggle,
  darkMode,
  toggleDarkMode,
}) => {
  const { user } = useAuth();

  return (
    <>
      <div className='dark-mode-toggle'>
        <button
          onClick={toggleDarkMode}
          className='dark-mode-button'
          aria-label={darkMode ? 'Switch to light mode' : 'Switch to dark mode'}
        >
          {darkMode ? <FaSun /> : <FaMoon />}
        </button>
      </div>
      <div className='profile-header'>
        <h1>
          {userProfile.isOwnProfile
            ? 'Your Profile'
            : `${userProfile.displayName}'s Profile`}
        </h1>

        {/* Show Mute/Unmute button when viewing someone else's profile */}
        {!userProfile.isOwnProfile && user && (
          <div className='mute-user-container'>
            <BrutalistButton
              onClick={handleMuteToggle}
              disabled={muteStatusLoading}
              className={`mute-toggle-button ${isMuted ? 'unmute-button' : 'mute-button'}`}
            >
              {muteStatusLoading
                ? 'Loading...'
                : isMuted
                  ? 'Unmute User'
                  : 'Mute User'}
            </BrutalistButton>
          </div>
        )}
      </div>
    </>
  );
};

const NavigationButton: React.FC<{
  userProfile: IUserProfile;
}> = ({ userProfile }) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    // If viewing another user's profile, go back to previous page
    if (!userProfile?.isOwnProfile) {
      navigate(-1);
    } else {
      // If on own profile, go back to dashboard
      navigate('/dashboard');
    }
  };

  return (
    <BrutalistArrowButton
      direction='left'
      onClick={handleNavigation}
      className='profile-back-button'
    />
  );
};

const ProfileLoadingState: React.FC = () => (
  <div className='profile-container'>
    <BrutalistLoading text='LOADING PROFILE DATA' size='medium' />
  </div>
);

const ProfileErrorState: React.FC<{ error: string }> = ({ error }) => (
  <div className='profile-container'>Error: {error}</div>
);

const ProfileNotFoundState: React.FC = () => (
  <div className='profile-container'>User not found</div>
);

const ProfileScreen: React.FC<IProfileScreenProps> = ({
  userId: propUserId,
}) => {
  const { userId: paramUserId } = useParams<{ userId: string }>();
  const { user } = useAuth();
  const location = window.location;
  const queryParams = new URLSearchParams(location.search);
  const username = queryParams.get('username');
  const photoURL = queryParams.get('photo');

  const effectiveUserId = paramUserId || propUserId;

  // Profile data and presentation hooks
  const {
    userProfile,
    userActivity,
    userEngagement,
    userSettings,
    userBadges,
    loading,
    error,
  } = useProfileScreenContent(
    effectiveUserId,
    username || undefined,
    photoURL || undefined,
  );

  const { safeUserActivity, safeUserEngagement, safeUserBadges } =
    useProfileSectionsData({
      _userProfile: userProfile,
      userActivity,
      userEngagement,
      userSettings,
      userBadges,
    });

  const {
    activeTab,
    setActiveTab,
    isEditMode,
    toggleEditMode,
    handleLogout,
    toggleDarkMode,
    darkMode,
    expandedSections,
    toggleSection,
  } = useProfileScreenPresentation();

  // Mute status management
  const { isMuted, muteStatusLoading, handleMuteToggle } = useMuteStatus({
    userId: effectiveUserId,
    isOwnProfile: userProfile?.isOwnProfile,
    currentUser: user,
  });

  // Rendering conditions
  if (loading && !userProfile) {
    return <ProfileLoadingState />;
  }

  if (error) {
    return <ProfileErrorState error={error} />;
  }

  if (!userProfile) {
    return <ProfileNotFoundState />;
  }

  return (
    <div className={`profile-container ${darkMode ? 'dark-mode' : ''}`}>
      <NavigationButton userProfile={userProfile} />
      <ProfileHeader
        userProfile={userProfile}
        isMuted={isMuted}
        muteStatusLoading={muteStatusLoading}
        handleMuteToggle={handleMuteToggle}
        darkMode={darkMode}
        toggleDarkMode={toggleDarkMode}
      />
      <ProfileSections
        userProfile={userProfile}
        userActivity={safeUserActivity}
        userEngagement={safeUserEngagement}
        userBadges={safeUserBadges}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        isEditMode={isEditMode}
        toggleEditMode={toggleEditMode}
        handleLogout={handleLogout}
        toggleDarkMode={toggleDarkMode}
        darkMode={darkMode}
        expandedSections={expandedSections}
        toggleSection={toggleSection}
        isLoading={loading}
      />
    </div>
  );
};

export default ProfileScreen;
