import React from 'react';
import { Button, ButtonProps } from 'react-bootstrap';
import './BrutalistUI.scss';

interface IBrutalistButtonProps extends ButtonProps {
  children: React.ReactNode;
  className?: string;
  btnSize?: 'small' | 'medium' | 'large';
}

const BrutalistButton: React.FC<IBrutalistButtonProps> = ({
  children,
  className = '',
  btnSize = 'medium',
  ...props
}) => {
  return (
    <Button
      className={`brutalist-button brutalist-button-${btnSize} ${className}`}
      {...props}
    >
      {children}
    </Button>
  );
};

export default BrutalistButton;
