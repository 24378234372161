import React from 'react';
import { Link } from 'react-router-dom';

import { IFollowUser } from '../constants/profileScreen.types';

interface IFollowListItemProps {
  user: IFollowUser;
}

export const FollowListItem: React.FC<IFollowListItemProps> = ({ user }) => {
  const formattedDate = new Date(user.timestamp).toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  return (
    <div className='follow-list-item brutalist-border'>
      <Link to={`/profile/${user.id}`} className='user-link'>
        <div className='user-avatar-container'>
          <img
            src={user.photoURL || '/path/to/default-avatar.jpg'}
            alt={`${user.displayName}'s avatar`}
            className='user-avatar-small'
          />
        </div>
        <div className='user-info'>
          <div className='user-name'>{user.displayName}</div>
          <div className='user-username'>
            {user.username.startsWith('@')
              ? user.username
              : `@${user.username}`}
          </div>
          <div className='follow-date'>Following since {formattedDate}</div>
        </div>
      </Link>
    </div>
  );
};
