import { onValue, ref } from 'firebase/database';
import { useEffect, useState } from 'react';

import { ContentPaths } from './constants/content.paths';
import { db } from '../../../firebase';
import '../../../App.scss';

interface IArticle {
  id: string;
  title: string;
  author: string;
  date: string;
  weight: string;
  image: string;
  urlSlug?: string;
  content: {
    event: string;
    [key: string]: string;
  };
  bulletPoints?: string[];
  [key: string]: unknown;
}

export default function useFeedMainContent(): {
  articles: IArticle[];
  loading: boolean;
} {
  const [articles, setArticles] = useState<IArticle[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const articlesRef = ref(db, ContentPaths.ARTICLES);
    const unsubscribe = onValue(
      articlesRef,
      (snapshot) => {
        setLoading(true);
        const articlesData = snapshot.val();
        if (articlesData) {
          const sortedArticles = Object.keys(articlesData)
            .map((key) => ({ id: key, ...articlesData[key] }))
            // Filter out unpublished articles
            .filter((article) => article.isPublished === true)
            .sort((a, b) => parseInt(a.weight) - parseInt(b.weight));
          setArticles(sortedArticles);
        } else {
          setArticles([]);
        }
        setLoading(false);
      },
      (error) => {
        console.error('Error reading data: ', error);
      },
    );
    return () => {
      unsubscribe();
    };
  }, []);

  return {
    articles,
    loading,
  };
}
