import { useState, useCallback } from 'react';

import { useAuth } from '../../../contexts/AuthContext';

interface IUseProfileScreenPresentationReturn {
  activeTab: string;
  setActiveTab: (tab: string) => void;
  isEditMode: boolean;
  toggleEditMode: () => void;
  handleLogout: () => Promise<void>;
  toggleDarkMode: () => Promise<void>;
  darkMode: boolean;
  expandedSections: Record<string, boolean>;
  toggleSection: (section: string) => void;
}

export const useProfileScreenPresentation =
  (): IUseProfileScreenPresentationReturn => {
    const { logout, toggleDarkMode, darkMode } = useAuth();

    const [activeTab, setActiveTab] = useState<string>('activity');

    const [isEditMode, setIsEditMode] = useState<boolean>(false);

    const [expandedSections, setExpandedSections] = useState<
      Record<string, boolean>
    >({
      userInfo: true,
      activity: true,
      engagement: false,
      settings: false,
      badges: false,
    });

    const toggleEditMode = useCallback(() => {
      setIsEditMode((prev) => !prev);
    }, []);

    const handleLogout = useCallback(async () => {
      try {
        await logout();
      } catch (error) {
        console.error('Failed to log out:', error);
      }
    }, [logout]);

    const toggleSection = useCallback((section: string) => {
      setExpandedSections((prev) => ({
        ...prev,
        [section]: !prev[section],
      }));
    }, []);

    return {
      activeTab,
      setActiveTab,
      isEditMode,
      toggleEditMode,
      handleLogout,
      toggleDarkMode,
      darkMode,
      expandedSections,
      toggleSection,
    };
  };
