//useArchiveContent.ts
import { onValue, ref, query, orderByChild, equalTo } from 'firebase/database';
import { useEffect, useState } from 'react';

import { IArticle } from './constants/archiveContent.types';
import { db } from '../../../firebase';

export default function useArchiveContent(selectedDate: Date): {
  articles: IArticle[];
  loading: boolean;
  error: Error | null;
} {
  const [articles, setArticles] = useState<IArticle[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);
    setError(null);

    const formattedDate = selectedDate.toISOString().split('T')[0];
    const articlesRef = query(
      ref(db, 'articles'),
      orderByChild('date'),
      equalTo(formattedDate),
    );

    const unsubscribe = onValue(
      articlesRef,
      (snapshot) => {
        const fetchedArticles = snapshot.val();
        if (fetchedArticles) {
          setArticles(Object.values(fetchedArticles));
        } else {
          setArticles([]);
        }
        setLoading(false);
      },
      (errorObject) => {
        setError(errorObject);
        setLoading(false);
      },
    );

    return () => unsubscribe();
  }, [selectedDate]);

  return { articles, loading, error };
}
