import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { useAuth } from '../../../contexts/AuthContext';
import { authService } from '../../../services/authService';
import BrutalistLoading from '../../SharedComponents/BrutalistUI/BrutalistLoading';
import '../../../styles/brutalist.scss';
import './FollowingFeed.scss';

// Interface for comment data from followed users
interface IFollowedComment {
  id: string;
  articleId: string;
  articleTitle?: string;
  content: string;
  author: string;
  authorId: string;
  authorUsername?: string;
  profilePicture?: string;
  timestamp: number;
  timestampISO: string;
}

// Interface for muted user data
interface IMutedUser {
  id: string;
  displayName: string;
  username: string;
  photoURL: string;
}

const FollowersFeed: React.FC = () => {
  const { user } = useAuth();
  const [comments, setComments] = useState<IFollowedComment[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [hasMore, setHasMore] = useState(false);
  const [lastComment, setLastComment] = useState<{
    timestamp: number;
    commentId: string;
  } | null>(null);
  const [mutedUsers, setMutedUsers] = useState<IMutedUser[]>([]);
  const [showMutedList, setShowMutedList] = useState(false);

  // Function to load comments from followed users
  const loadComments = async (refresh = false) => {
    if (!user) return;

    try {
      setLoading(true);
      setError(null);

      // If refresh is true, clear the current comments and reset pagination
      if (refresh) {
        setComments([]);
        setLastComment(null);
      }

      // Set up pagination options for the API call
      const options = {
        limit: 10,
        includeArticleDetails: true,
        ...(lastComment && !refresh ? { startAfter: lastComment } : {}),
      };

      // Fetch comments from followed users
      const followedComments = await authService.getFollowedComments(options);

      // Update state with fetched comments
      if (refresh) {
        setComments(followedComments);
      } else {
        setComments((prev) => [...prev, ...followedComments]);
      }

      // Update pagination status
      if (followedComments.length === 10) {
        setHasMore(true);
        const lastItem = followedComments[followedComments.length - 1];
        setLastComment({
          timestamp: lastItem.timestamp,
          commentId: lastItem.id,
        });
      } else {
        setHasMore(false);
        setLastComment(null);
      }
    } catch (error) {
      console.error('Error loading followed comments:', error);
      const errorMessage =
        error instanceof Error
          ? error.message
          : 'Failed to load comments from users you follow';
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  // Function to load muted users
  const loadMutedUsers = async () => {
    if (!user) return;

    try {
      const mutedUsersList = await authService.getMutedUsers();
      setMutedUsers(mutedUsersList);
    } catch (error) {
      console.error('Error loading muted users:', error);
    }
  };

  // Function to mute a user
  const handleMuteUser = async (authorId: string) => {
    if (!user) return;

    try {
      const result = await authService.muteUser(authorId);

      if (result.success) {
        // Filter out comments from the muted user
        setComments((prev) =>
          prev.filter((comment) => comment.authorId !== authorId),
        );

        // Refresh muted users list
        await loadMutedUsers();
      }
    } catch (error) {
      console.error('Error muting user:', error);
    }
  };

  // Function to unmute a user
  const handleUnmuteUser = async (userId: string) => {
    if (!user) return;

    try {
      const result = await authService.unmuteUser(userId);

      if (result.success) {
        // Remove user from muted list
        setMutedUsers((prev) => prev.filter((user) => user.id !== userId));

        // Refresh comments to include newly unmuted user
        loadComments(true);
      }
    } catch (error) {
      console.error('Error unmuting user:', error);
    }
  };

  // Function to format dates in a relative way
  const formatDate = (timestamp: number): string => {
    try {
      return format(new Date(timestamp), 'MMM d, yyyy h:mm a');
    } catch (error) {
      return 'Unknown date';
    }
  };

  // Load comments and muted users on component mount
  // Need to use useCallback to resolve dependency issues with the useEffect
  const memoizedLoadComments = React.useCallback(
    (refresh = false) => loadComments(refresh),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user],
  );

  const memoizedLoadMutedUsers = React.useCallback(() => {
    loadMutedUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (user) {
      memoizedLoadComments(true);
      memoizedLoadMutedUsers();
    }
  }, [user, memoizedLoadComments, memoizedLoadMutedUsers]);

  if (!user) {
    return (
      <Col
        xs={12}
        sm={9}
        md={8}
        lg={6}
        xl={6}
        className='followers-feed-container'
      >
        <div className='followers-feed-header'>
          <h2>Following Feed</h2>
        </div>
        <div className='no-comments-message'>
          <p>Please log in to see comments from users you follow.</p>
        </div>
      </Col>
    );
  }

  return (
    <Col
      xs={12}
      sm={9}
      md={8}
      lg={6}
      xl={6}
      className='followers-feed-container'
    >
      <div className='followers-feed-header'>
        <h2>Following Feed</h2>
        <button
          className='manage-muted-btn'
          onClick={() => setShowMutedList(!showMutedList)}
        >
          {showMutedList ? 'Hide Muted Users' : 'Manage Muted Users'}
        </button>
      </div>

      {/* Display muted users list if showMutedList is true */}
      {showMutedList && (
        <div className='muted-users-container'>
          <h3>Muted Users</h3>
          {mutedUsers.length === 0 ? (
            <p>You haven&apos;t muted any users yet.</p>
          ) : (
            <ul className='muted-users-list'>
              {mutedUsers.map((mutedUser) => (
                <li key={mutedUser.id} className='muted-user-item'>
                  <div className='muted-user-info'>
                    <img
                      src={
                        mutedUser.photoURL ||
                        require('../../assets/anonymous.jpg')
                      }
                      alt={mutedUser.displayName}
                      className='muted-user-avatar'
                    />
                    <div className='muted-user-details'>
                      <span className='muted-user-name'>
                        {mutedUser.displayName}
                      </span>
                      <span className='muted-user-username'>
                        @{mutedUser.username}
                      </span>
                    </div>
                  </div>
                  <button
                    className='unmute-btn'
                    onClick={() => handleUnmuteUser(mutedUser.id)}
                  >
                    Unmute
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}

      {/* Display error message if there's an error */}
      {error && <div className='error-message'>{error}</div>}

      {/* Display comments from followed users */}
      {comments.length === 0 && !loading ? (
        <div className='no-comments-message'>
          <p>No comments from users you follow yet.</p>
          <p>
            When users you follow comment on articles, their comments will
            appear here.
          </p>
        </div>
      ) : (
        <div className='comments-list'>
          {comments.map((comment) => (
            <div key={comment.id} className='comment-card'>
              <div className='comment-header'>
                <div className='comment-author-info'>
                  <img
                    src={
                      comment.profilePicture ||
                      require('../../assets/anonymous.jpg')
                    }
                    alt={comment.author}
                    className='author-avatar'
                  />
                  <div className='author-details'>
                    <span className='author-name'>{comment.author}</span>
                    <span className='comment-date'>
                      {formatDate(comment.timestamp)}
                    </span>
                  </div>
                </div>
                <button
                  className='mute-btn'
                  onClick={() => handleMuteUser(comment.authorId)}
                  title='Mute this user'
                >
                  Mute
                </button>
              </div>

              <div className='article-info'>
                <h3 className='article-title'>
                  <Link to={`/article/${comment.articleId}`}>
                    {comment.articleTitle || 'Article'}
                  </Link>
                </h3>
              </div>

              <div className='comment-content'>{comment.content}</div>

              <div className='comment-footer'>
                <Link
                  to={`/article/${comment.articleId}#comment-${comment.id}`}
                  className='view-in-context-link'
                >
                  View in context
                </Link>
              </div>
            </div>
          ))}

          {/* Load more button */}
          {hasMore && (
            <div className='load-more-container'>
              <button
                className='load-more-btn'
                onClick={() => loadComments()}
                disabled={loading}
              >
                {loading ? 'Loading...' : 'Load More'}
              </button>
            </div>
          )}
        </div>
      )}

      {/* Loading indicator */}
      {loading && <BrutalistLoading text='LOADING FEED' size='medium' />}
    </Col>
  );
};

export default FollowersFeed;
