import React from 'react';
import {
  FaTwitter,
  FaInstagram,
  FaFacebook,
  FaLinkedin,
  FaGithub,
  FaPlus,
  FaTrash,
} from 'react-icons/fa';

import { ISocialLink } from '../constants/profileScreen.types';

interface ISocialMediaLinksProps {
  links: ISocialLink[];
  isEditMode: boolean;
  onUpdate: (index: number, username: string) => void;
  onRemove: (index: number) => void;
  onAdd: (platform: ISocialLink['platform']) => void;
}

// Platform base URLs
const PLATFORM_URLS: Record<ISocialLink['platform'], string> = {
  twitter: 'https://twitter.com/',
  instagram: 'https://instagram.com/',
  facebook: 'https://facebook.com/',
  linkedin: 'https://linkedin.com/in/',
  github: 'https://github.com/',
};

// Platform icons
const PLATFORM_ICONS: Record<ISocialLink['platform'], React.ReactNode> = {
  twitter: <FaTwitter />,
  instagram: <FaInstagram />,
  facebook: <FaFacebook />,
  linkedin: <FaLinkedin />,
  github: <FaGithub />,
};

// Available platforms for adding
const AVAILABLE_PLATFORMS: ISocialLink['platform'][] = [
  'twitter',
  'instagram',
  'facebook',
  'linkedin',
  'github',
];

export const SocialMediaLinks: React.FC<ISocialMediaLinksProps> = ({
  links,
  isEditMode,
  onUpdate,
  onRemove,
  onAdd,
}) => {
  // Get platforms that are not yet added
  const availablePlatforms = AVAILABLE_PLATFORMS.filter(
    (platform) => !links.some((link) => link.platform === platform),
  );

  return (
    <div className='social-links-container'>
      <h4>Social Media</h4>

      <div className='social-links-list'>
        {links.length === 0 && !isEditMode && (
          <p className='no-socials-message'>No social media links added</p>
        )}

        {links.map((link, index) => (
          <div key={link.platform} className='social-link-item'>
            <div className='social-link-icon'>
              {PLATFORM_ICONS[link.platform]}
            </div>
            {isEditMode ? (
              <div className='social-link-edit'>
                <span className='social-link-base-url'>
                  {PLATFORM_URLS[link.platform]}
                </span>
                <input
                  type='text'
                  value={link.username}
                  onChange={(e) => onUpdate(index, e.target.value)}
                  placeholder={`Enter your ${link.platform} username`}
                  className='social-link-input'
                  maxLength={30}
                />
                <button
                  onClick={() => onRemove(index)}
                  className='social-link-remove-btn'
                  aria-label={`Remove ${link.platform}`}
                >
                  <FaTrash />
                </button>
              </div>
            ) : (
              <a
                href={`${PLATFORM_URLS[link.platform]}${link.username}`}
                target='_blank'
                rel='noopener noreferrer'
                className='social-link'
              >
                {link.username}
              </a>
            )}
          </div>
        ))}

        {isEditMode && availablePlatforms.length > 0 && (
          <div className='add-social-links'>
            <p>Add social media:</p>
            <div className='platform-buttons'>
              {availablePlatforms.map((platform) => (
                <button
                  key={platform}
                  onClick={() => onAdd(platform)}
                  className='add-platform-btn'
                  aria-label={`Add ${platform}`}
                >
                  {PLATFORM_ICONS[platform]}
                  <FaPlus className='add-icon' />
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
