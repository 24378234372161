import React from 'react';
import { CgProfile } from 'react-icons/cg';

import { BrutalistButton } from '../../../SharedComponents/BrutalistUI';
import { IUserBadges } from '../constants/profileScreen.types';

interface IBadgesSectionProps {
  userBadges: IUserBadges;
  isOwnProfile: boolean;
}

const BadgesSection: React.FC<IBadgesSectionProps> = ({
  userBadges,
  isOwnProfile,
}) => {
  return (
    <div className='section-content badges-section'>
      <div className='badges-grid'>
        {/* TODO: Implement badges display */}
        <div className='badge-item'>
          <CgProfile className='badge-icon' />
          <div className='badge-name'>New User</div>
          <div className='badge-date'>
            Earned: {new Date().toLocaleDateString()}
          </div>
        </div>
      </div>

      <div className='reputation-score'>
        <div className='score-value'>{userBadges.reputationScore}</div>
        <div className='score-label'>Reputation Score</div>
      </div>

      {!isOwnProfile && (
        <BrutalistButton className='follow-button'>Follow User</BrutalistButton>
      )}
    </div>
  );
};

export default BadgesSection;
