import React, { useState } from 'react';
import { Card, Button, Alert } from 'react-bootstrap';

import { useAuth } from '../contexts/AuthContext';
import { authService } from '../services/authService';

const AdminDebug: React.FC = () => {
  const {
    user,
    checkAdminStatus: checkAdmin,
    refreshUserClaims,
    isAdmin,
  } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const handleCheckAdminStatus = async () => {
    setLoading(true);
    setError('');
    setMessage('');

    try {
      const adminStatus = await checkAdmin();
      setMessage(`Admin status: ${adminStatus ? 'Yes' : 'No'}`);
    } catch (error) {
      setError(
        'Failed to check admin status: ' +
          (error instanceof Error ? error.message : String(error)),
      );
    } finally {
      setLoading(false);
    }
  };

  // This would only work for testing and should be removed in production
  const setCurrentUserAsAdmin = async () => {
    if (!user) {
      setError('No user is logged in');
      return;
    }

    setLoading(true);
    setError('');
    setMessage('');

    try {
      const result = await authService.setUserAdmin(user.uid, true);
      if (result.success) {
        setMessage('User set as admin. Refreshing token...');
        await refreshUserClaims();
        setMessage(
          'User set as admin and token refreshed. Admin status: ' +
            (isAdmin ? 'Yes' : 'No'),
        );
      } else {
        setError(result.error || 'Failed to set admin status');
      }
    } catch (error) {
      setError(
        'Error setting admin status: ' +
          (error instanceof Error ? error.message : String(error)),
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card className='mb-4'>
      <Card.Header>Admin Debug Tools</Card.Header>
      <Card.Body>
        {error && <Alert variant='danger'>{error}</Alert>}
        {message && <Alert variant='success'>{message}</Alert>}

        <div className='mb-2'>
          <Button
            onClick={handleCheckAdminStatus}
            disabled={loading}
            variant='secondary'
            className='mr-2'
          >
            Check Admin Status
          </Button>

          <Button
            onClick={setCurrentUserAsAdmin}
            disabled={loading}
            variant='warning'
            className='ml-2'
          >
            Set as Admin (Test Only)
          </Button>

          <Button
            onClick={refreshUserClaims}
            disabled={loading}
            variant='info'
            className='ml-2'
          >
            Refresh Token
          </Button>
        </div>

        <div className='mt-3'>
          <strong>Current Admin Status:</strong>{' '}
          {isAdmin ? 'Is Admin' : 'Not Admin'}
        </div>

        <div className='mt-3'>
          <strong>User ID:</strong> {user?.uid || 'Not logged in'}
        </div>
      </Card.Body>
    </Card>
  );
};

export default AdminDebug;
