import React from 'react';

import { IUserEngagement } from '../constants/profileScreen.types';

interface IEngagementSectionProps {
  userEngagement: IUserEngagement;
  activeTab: string;
  setActiveTab: (tab: string) => void;
}

const EngagementSection: React.FC<IEngagementSectionProps> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  userEngagement,
  activeTab,
  setActiveTab,
}) => {
  return (
    <div className='section-content'>
      <div className='engagement-tabs'>
        <button
          className={`tab-button ${activeTab === 'comments' ? 'active' : ''}`}
          onClick={() => setActiveTab('comments')}
        >
          Comments
        </button>
        <button
          className={`tab-button ${activeTab === 'saved' ? 'active' : ''}`}
          onClick={() => setActiveTab('saved')}
        >
          Saved Articles
        </button>
        <button
          className={`tab-button ${activeTab === 'liked' ? 'active' : ''}`}
          onClick={() => setActiveTab('liked')}
        >
          Liked Comments
        </button>
      </div>

      <div className='engagement-content'>
        {activeTab === 'comments' && (
          <div>
            {/* TODO: Implement comment history list */}
            <p>
              No comments yet. This section will display the user&apos;s comment
              history.
            </p>
          </div>
        )}

        {activeTab === 'saved' && (
          <div>
            {/* TODO: Implement saved articles list */}
            <p>
              No saved articles yet. This section will display the user&apos;s
              saved articles.
            </p>
          </div>
        )}

        {activeTab === 'liked' && (
          <div>
            {/* TODO: Implement liked comments list */}
            <p>
              No liked comments yet. This section will display comments the user
              has liked.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default EngagementSection;
