import React, {
  useRef,
  useState,
  useEffect,
  FormEvent,
  MutableRefObject,
  ReactElement,
} from "react";
import { Form, Alert } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import { useAuth } from "../../../contexts/AuthContext";
import {
  BrutalistButton,
  BrutalistCard,
  BrutalistHeading,
} from "../../SharedComponents/BrutalistUI";
import "../../../styles/brutalist.scss";
import "./preLogin.scss";

interface IForgotPassFormProps {
  handleSubmit: (e: FormEvent) => Promise<void>;
  emailRef: MutableRefObject<HTMLInputElement | null>;
  error: string;
  message: string;
  loading: boolean;
}

function ForgotPassForm({
  handleSubmit,
  emailRef,
  error,
  message,
  loading,
}: IForgotPassFormProps): ReactElement {
  return (
    <>
      <BrutalistHeading
        level="h2"
        className="text-center mb-4 brutalist-rotate-1"
      >
        PASSWORD RESET
      </BrutalistHeading>

      {error && (
        <Alert variant="danger" className="brutalist-alert">
          {error}
        </Alert>
      )}

      {message && (
        <div className="brutalist-success-alert">
          <h5>{message}</h5>
        </div>
      )}

      <Form onSubmit={handleSubmit} className="brutalist-form">
        <Form.Group id="email" className="mb-4">
          <Form.Label className="brutalist-label">EMAIL</Form.Label>
          <Form.Control
            type="email"
            ref={emailRef}
            required
            className="brutalist-form-input"
          />
        </Form.Group>

        <BrutalistButton
          disabled={loading}
          className="w-100 text-center"
          type="submit"
        >
          {loading ? "SENDING..." : "RESET PASSWORD"}
        </BrutalistButton>
      </Form>

      <div className="w-100 text-center mt-4">
        <Link to="/login" className="brutalist-link">
          BACK TO LOGIN
        </Link>
      </div>
    </>
  );
}

// Using CSS classes instead of inline styles

export default function ForgotPass(): ReactElement {
  const emailRef = useRef<HTMLInputElement | null>(null);
  const { user, forgotPassword } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  // Redirect authenticated users appropriately
  useEffect(() => {
    if (user) {
      // If user is anonymous (guest), redirect to welcome screen
      // Otherwise redirect to dashboard
      if (user.isAnonymous) {
        navigate("/welcome");
      } else {
        navigate("/dashboard");
      }
    }
  }, [user, navigate]);

  async function handleSubmit(e: FormEvent): Promise<void> {
    e.preventDefault();

    if (emailRef.current) {
      const email = emailRef.current.value;

      try {
        setMessage("");
        setError("");
        setLoading(true);
        await forgotPassword(email);
        setMessage("Check Inbox For Further Instructions");
      } catch (error) {
        // Error is handled by setting the error state
        setError("Email not found or invalid.");
      }
      setLoading(false);
    }
  }

  return (
    <div className="login-page-wrapper">
      <div className="login-card-container">
        <BrutalistCard className="forgot-password-card">
          <ForgotPassForm
            handleSubmit={handleSubmit}
            emailRef={emailRef}
            error={error}
            message={message}
            loading={loading}
          />
        </BrutalistCard>

        <div className="brutalist-signup-link">
          Don&apos;t have an account?{" "}
          <Link to="/signup" className="brutalist-link">
            SIGN UP
          </Link>
        </div>
      </div>
    </div>
  );
}
