import React from 'react';
import { Col, Tab, Tabs } from 'react-bootstrap';

import FeedMain from '../../../MainComponents/MainFeed/feedMain';
import '../../../../styles/brutalist.scss';
import './mainContent.scss';

// TODO: Will remove later
const TestComponent = (): JSX.Element => (
  <div className='empty-feed'>
    <h3>COMING SOON</h3>
    <p>This feed will be available in future updates</p>
    <div
      className='brutalist-primary-background'
      style={{ width: '50px', height: '50px', margin: '0 auto' }}
    />
  </div>
);

export default function MainContent(): JSX.Element {
  return (
    <Col xs={12} sm={9} md={8} lg={6} xl={6} className='main-content'>
      <Tabs
        defaultActiveKey='usNews'
        id='content-tabs'
        className='justify-content-center brutalist-tabs'
      >
        <Tab eventKey='usNews' title='US Feed'>
          <div className='feed-container'>
            <FeedMain />
          </div>
        </Tab>
        <Tab eventKey='worldNews' title='World Feed'>
          <div className='feed-container'>
            <TestComponent />
          </div>
        </Tab>
      </Tabs>
    </Col>
  );
}
