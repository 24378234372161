import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";

import BrutalistModal from "../../SharedComponents/BrutalistUI/BrutalistModal";

interface ITermsOfServiceModalProps {
  show: boolean;
  onHide: () => void;
}

const TermsOfServiceModal: React.FC<ITermsOfServiceModalProps> = ({
  show,
  onHide,
}) => {
  const [termsContent, setTermsContent] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    // Import the raw content of the terms of service
    const fetchTerms = async () => {
      if (!show) return;

      setLoading(true);
      setError("");

      try {
        const response = await fetch("/legal/termsOfService.md");

        if (!response.ok) {
          throw new Error(`Failed to fetch terms: ${response.status}`);
        }

        const text = await response.text();
        setTermsContent(text);
      } catch (error) {
        console.error("Failed to load terms of service:", error);
        setError(
          "Terms of Service content could not be loaded. Please try again later.",
        );
      } finally {
        setLoading(false);
      }
    };

    fetchTerms();
  }, [show]);

  return (
    <BrutalistModal
      show={show}
      onHide={onHide}
      title="TERMS OF SERVICE"
      className="terms-modal"
    >
      <div className="terms-content">
        {loading ? (
          <p className="loading-message">Loading terms of service...</p>
        ) : error ? (
          <p className="error-message">{error}</p>
        ) : termsContent ? (
          <ReactMarkdown>{termsContent}</ReactMarkdown>
        ) : (
          <p className="empty-message">
            No terms of service content available.
          </p>
        )}
      </div>
    </BrutalistModal>
  );
};

export default TermsOfServiceModal;
