import React from 'react';
import './BrutalistUI.scss';

interface IBrutalistLoadingProps {
  text?: string;
  size?: 'small' | 'medium' | 'large';
  className?: string;
}

const BrutalistLoading: React.FC<IBrutalistLoadingProps> = ({
  text = 'LOADING CONTENT',
  size = 'medium',
  className = '',
}) => {
  return (
    <div className={`brutalist-loading ${size} ${className}`}>
      <div className='loading-box' />
      {text && <h3>{text}</h3>}
    </div>
  );
};

export default BrutalistLoading;
