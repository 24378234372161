// CommentComponent.tsx
import React from 'react';
import { BiSolidChevronsUp } from 'react-icons/bi';
import { ImCross, ImPlus } from 'react-icons/im';
import { useNavigate } from 'react-router-dom';

import { ReplyBox } from './commentInputs';
import { Reply } from './commentItems';
import { ICommentStyles } from './commentTypes'; // Keep ICommentStyles if defined here, remove others
import { formatTimestamp, getUpvoteCount } from './commentUtils';
import { BrutalistButton } from '../../../SharedComponents/BrutalistUI';
// IExtendedComment and IExtendedReply will be replaced by IComment and IReply from constants
import {
  ICommentsData,
  IOpenReplies,
  IOpenRebuttals,
  HandleDeleteComment,
  HandleDeleteReply,
  HandleDeleteRebuttal,
  HandleUpvote,
  HandleAddReply,
  HandleAddRebuttal,
  IComment, // Import IComment
  IReply, // Import IReply
} from '../__constants__/comment.types';
import useCommentSystemPresentation from '../useCommentSystemPresentation';

interface ICommentComponentProps {
  commentId: string;
  commentsData: ICommentsData;
  displayName: string;
  userId: string;
  handleDeleteComment: HandleDeleteComment;
  handleDeleteReply: HandleDeleteReply;
  handleDeleteRebuttal: HandleDeleteRebuttal;
  handleUpvote: HandleUpvote;
  handleAddReply: HandleAddReply;
  handleAddRebuttal: HandleAddRebuttal;
  isAnonymous: boolean;
  openReplies: IOpenReplies;
  setOpenReplies: React.Dispatch<React.SetStateAction<IOpenReplies>>;
  openRebuttals: IOpenRebuttals;
  setOpenRebuttals: React.Dispatch<React.SetStateAction<IOpenRebuttals>>;
  showReplyBox: { [key: string]: boolean };
  setShowReplyBox: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  showRebuttalBox: { [key: string]: boolean };
  setShowRebuttalBox: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  newReply: { [key: string]: string };
  newRebuttal: { [key: string]: string };
  setNewReply: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
  setNewRebuttal: React.Dispatch<
    React.SetStateAction<{ [key: string]: string }>
  >;
  isReplyLoading: { [key: string]: boolean };
  isRebuttalLoading: { [key: string]: boolean };
  // Removed reply/rebuttal category props
}

/**
 * Main component for displaying a comment with its replies and rebuttals
 */
export const CommentComponent: React.FC<ICommentComponentProps> = ({
  commentId,
  commentsData,
  userId,
  handleDeleteComment,
  handleDeleteReply,
  handleDeleteRebuttal,
  handleUpvote,
  handleAddReply,
  handleAddRebuttal,
  isAnonymous,
  openReplies,
  setOpenReplies,
  openRebuttals,
  setOpenRebuttals,
  showReplyBox,
  setShowReplyBox,
  showRebuttalBox,
  setShowRebuttalBox,
  newReply,
  newRebuttal,
  setNewReply,
  setNewRebuttal,
  isReplyLoading,
  isRebuttalLoading,
  // Removed reply/rebuttal category props destructuring
}) => {
  const navigate = useNavigate();
  const { styles } = useCommentSystemPresentation() as {
    styles: ICommentStyles;
  };
  // Use IComment from constants which now includes category
  const comment = commentsData[commentId] as IComment;

  if (!comment) return null;

  // Determine category class for styling
  const categoryClass = `category--${comment.category || 'other'}`; // Use single quotes

  return (
    <div className='comment-tree' style={styles.commentTree}>
      <div
        className={`comment-node ${categoryClass}`}
        style={styles.commentNode}
      >
        <div className='comment-container' style={styles.commentContainer}>
          <div key={commentId} style={styles.commentWrapper}>
            <div style={styles.commentText}>{comment.content}</div>
            <div style={styles.authorContainer}>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (comment.authorId) {
                    // Add the author's username and image as URL params
                    const params = new URLSearchParams();
                    params.append('username', comment.author);
                    if (comment.profilePicture) {
                      params.append('photo', comment.profilePicture);
                    }
                    navigate(
                      `/profile/${comment.authorId}?${params.toString()}`,
                    );
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    if (comment.authorId) {
                      const params = new URLSearchParams();
                      params.append('username', comment.author);
                      if (comment.profilePicture) {
                        params.append('photo', comment.profilePicture);
                      }
                      navigate(
                        `/profile/${comment.authorId}?${params.toString()}`,
                      );
                    }
                  }
                }}
                role='button'
                tabIndex={0}
              >
                <img
                  src={
                    comment.profilePicture ||
                    'https://upload.wikimedia.org/wikipedia/commons/a/ac/Default_pfp.jpg'
                  }
                  alt={`${comment.author}'s profile`}
                  style={styles.profilePicture}
                />
              </div>
              <div>
                <span
                  style={{ ...styles.authorText, cursor: 'pointer' }}
                  onClick={() => {
                    if (comment.authorId) {
                      // Add the author's username and image as URL params
                      const params = new URLSearchParams();
                      params.append('username', comment.author);
                      if (comment.profilePicture) {
                        params.append('photo', comment.profilePicture);
                      }
                      navigate(
                        `/profile/${comment.authorId}?${params.toString()}`,
                      );
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      if (comment.authorId) {
                        const params = new URLSearchParams();
                        params.append('username', comment.author);
                        if (comment.profilePicture) {
                          params.append('photo', comment.profilePicture);
                        }
                        navigate(
                          `/profile/${comment.authorId}?${params.toString()}`,
                        );
                      }
                    }
                  }}
                  role='button'
                  tabIndex={0}
                >
                  By: {comment.author}
                </span>
                <span style={styles.timestamp}>
                  {formatTimestamp(comment.date)}
                </span>
              </div>
            </div>

            {!isAnonymous && (
              <div
                style={styles.upvoteButton}
                onClick={() => handleUpvote(commentId)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleUpvote(commentId);
                  }
                }}
                role='button'
                tabIndex={0}
              >
                <BiSolidChevronsUp size={18} />
                <span>({getUpvoteCount(comment.upvotes)})</span>
              </div>
            )}

            {comment.authorId === userId && !isAnonymous && (
              <ImCross
                style={styles.deleteIcon}
                onClick={() => handleDeleteComment(commentId)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleDeleteComment(commentId);
                  }
                }}
                role='button'
                tabIndex={0}
              />
            )}

            {/* Reply Button - Always show for non-anonymous users */}
            {!isAnonymous &&
              (showReplyBox[commentId] ? (
                <ReplyBox
                  commentId={commentId}
                  newReply={newReply}
                  setNewReply={setNewReply}
                  isReplyLoading={isReplyLoading}
                  handleAddReply={handleAddReply}
                  setShowReplyBox={setShowReplyBox}
                  showReplyBox={showReplyBox}
                  styles={styles}
                  // Removed category props from ReplyBox
                />
              ) : (
                <div
                  onClick={() =>
                    setShowReplyBox({ ...showReplyBox, [commentId]: true })
                  }
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      setShowReplyBox({ ...showReplyBox, [commentId]: true });
                    }
                  }}
                  role='button'
                  tabIndex={0}
                  style={styles.touchableDiv}
                >
                  <ImPlus style={styles.primaryColor} />
                  <div style={styles.addReply}>Reply</div>
                </div>
              ))}
          </div>
        </div>

        {/* Existing Replies Section */}
        {comment.replies &&
          Object.keys(comment.replies).length > 0 &&
          (!openReplies[commentId]?.isOpen ? (
            <div className='reply-container' style={styles.replyContainer}>
              {' '}
              <BrutalistButton
                className='brutalist-action-button' // Use single quotes
                onClick={() =>
                  setOpenReplies({
                    ...openReplies,
                    [commentId]: { isOpen: true, shownReplies: 5 },
                  })
                }
              >
                View replies
              </BrutalistButton>
            </div>
          ) : (
            <>
              {Object.keys(comment.replies)
                .slice(0, openReplies[commentId]?.shownReplies)
                .map((replyId) => {
                  const reply = comment.replies && comment.replies[replyId];
                  if (!reply) return null;

                  return (
                    <Reply
                      key={replyId}
                      replyId={replyId}
                      // Use IReply from constants which now includes category
                      reply={reply as IReply}
                      commentId={commentId}
                      comment={comment}
                      userId={userId}
                      isAnonymous={isAnonymous}
                      handleUpvote={handleUpvote}
                      handleDeleteReply={handleDeleteReply}
                      handleAddRebuttal={handleAddRebuttal}
                      showRebuttalBox={showRebuttalBox}
                      setShowRebuttalBox={setShowRebuttalBox}
                      newRebuttal={newRebuttal}
                      setNewRebuttal={setNewRebuttal}
                      isRebuttalLoading={isRebuttalLoading}
                      openRebuttals={openRebuttals}
                      setOpenRebuttals={setOpenRebuttals}
                      handleDeleteRebuttal={handleDeleteRebuttal}
                      styles={styles}
                      // Removed category props from Reply
                    />
                  );
                })}
              <BrutalistButton
                className='brutalist-action-button' // Use single quotes
                onClick={() =>
                  setOpenReplies({
                    ...openReplies,
                    [commentId]: { isOpen: false, shownReplies: 5 },
                  })
                }
              >
                Hide replies
              </BrutalistButton>
            </>
          ))}
      </div>
    </div>
  );
};
