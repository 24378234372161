// Simple event system for alerts
type Listener = () => void;

class AlertEvents {
  private listeners: Listener[] = [];

  subscribe(listener: Listener): () => void {
    this.listeners.push(listener);

    // Return unsubscribe function
    return () => {
      this.listeners = this.listeners.filter((l) => l !== listener);
    };
  }

  emit(): void {
    this.listeners.forEach((listener) => {
      try {
        listener();
      } catch (error) {
        console.error('Error in alert event listener:', error);
      }
    });
  }
}

// Create a singleton instance
export const alertEvents = new AlertEvents();
