// commentUtils.tsx
import { IUpvotes } from '../__constants__/comment.types';
import { IReply, IRebuttal } from '../__constants__/content.types';

// Define extended interfaces inline
interface IExtendedRebuttal extends IRebuttal {
  profilePicture?: string;
}

interface IExtendedReply extends IReply {
  profilePicture?: string;
}

/**
 * Formats a timestamp string into a human-readable format
 * @param dateString The date string to format
 * @returns A formatted string (e.g., "5 mins ago", "2 hrs ago", or "MM/DD/YYYY")
 */
export const formatTimestamp = (dateString: string): string => {
  const date = new Date(dateString);
  const now = new Date();
  const diffMs = now.getTime() - date.getTime();
  const diffMinutes = Math.floor(diffMs / 60000);
  if (diffMinutes < 60) {
    return diffMinutes === 1 ? '1 min ago' : `${diffMinutes} mins ago`;
  }
  const diffHours = Math.floor(diffMs / 3600000);
  if (diffHours < 24) {
    return diffHours === 1 ? '1 hr ago' : `${diffHours} hrs ago`;
  }
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

/**
 * Gets the count of upvotes from an upvotes object
 * @param upvotes The upvotes object
 * @returns The number of upvotes
 */
export const getUpvoteCount = (
  upvotes: IUpvotes | undefined | null,
): number => {
  if (!upvotes) return 0;
  return Object.keys(upvotes).length;
};

/**
 * Checks initial conditions for adding a rebuttal
 * @param reply The reply object
 * @param userId The current user's ID
 * @param isAnonymous Whether the user is anonymous
 * @returns Whether the initial conditions are met
 */
export const checkInitialRebuttalConditions = (
  reply: IExtendedReply,
  userId: string,
  isAnonymous: boolean,
): boolean => {
  // If user is anonymous, they can't add rebuttals
  if (isAnonymous) return false;

  // If there's no rebuttal thread yet, only non-reply author can start it
  if (!reply.rebuttalThread || reply.rebuttalThread.participants.length === 0) {
    return userId !== reply.authorId;
  }

  return true;
};

/**
 * Checks if it's the user's turn in an empty thread
 * @param reply The reply object
 * @param userId The current user's ID
 * @returns Whether it's the user's turn
 */
export const checkEmptyThreadTurn = (
  reply: IExtendedReply,
  userId: string,
): boolean => {
  // If user is the reply author, they can't go first
  if (userId === reply.authorId) {
    return false;
  }
  return true;
};

/**
 * Checks if it's the user's turn based on the most recent rebuttal
 * @param rebuttals The array of rebuttals
 * @param userId The current user's ID
 * @returns Whether it's the user's turn
 */
export const checkExistingThreadTurn = (
  rebuttals: IExtendedRebuttal[],
  userId: string,
): boolean => {
  // Sort rebuttals by date (newest first)
  const sortedRebuttals = [...rebuttals].sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
  );

  // Check if it's this user's turn (they shouldn't be the author of the most recent rebuttal)
  return sortedRebuttals[0]?.authorId !== userId;
};

/**
 * Checks if it's the user's turn to add a rebuttal
 * @param reply The reply object
 * @param userId The current user's ID
 * @returns Whether it's the user's turn
 */
export const checkRebuttalTurn = (
  reply: IExtendedReply,
  userId: string,
): boolean => {
  // If there's a rebuttal thread, check if user is a participant
  if (reply.rebuttalThread?.participants.includes(userId)) {
    // Check if it's this user's turn (alternating)
    const rebuttals = reply.rebuttals ? Object.values(reply.rebuttals) : [];

    // If no rebuttals yet and thread is established
    if (rebuttals.length === 0) {
      return checkEmptyThreadTurn(reply, userId);
    }

    // Cast the rebuttals to the correct type
    return checkExistingThreadTurn(rebuttals as IExtendedRebuttal[], userId);
  }

  // User is not a participant
  return false;
};

/**
 * Determines if a user can add a rebuttal to a reply
 * @param reply The reply object
 * @param userId The current user's ID
 * @param isAnonymous Whether the user is anonymous
 * @returns Whether the user can add a rebuttal
 */
export const canAddRebuttal = (
  reply: IExtendedReply,
  userId: string,
  isAnonymous: boolean,
): boolean => {
  const initialCheck = checkInitialRebuttalConditions(
    reply,
    userId,
    isAnonymous,
  );
  if (!initialCheck) return false;

  return checkRebuttalTurn(reply, userId);
};
