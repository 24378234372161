import { httpsCallable, getFunctions } from 'firebase/functions';
import { useState, useEffect } from 'react';

import { useAuth } from '../../../contexts/AuthContext';
import app from '../../../firebase';

interface IUserMetadata {
  creationTime: string;
  lastSignInTime: string;
}

interface IUserMetadataResult {
  creationDate: Date | null;
  loading: boolean;
  error: string | null;
}

export const useUserMetadata = (userId?: string): IUserMetadataResult => {
  const { user } = useAuth();
  const [creationDate, setCreationDate] = useState<Date | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchUserMetadata = async () => {
      try {
        setLoading(true);
        setError(null);

        // Use the current user's ID if userId is not provided
        const targetUserId = userId || (user ? user.uid : null);

        if (!targetUserId) {
          setLoading(false);
          return;
        }

        // Call Firebase function to get user metadata
        const functions = getFunctions(app);
        const getUserMetadata = httpsCallable(functions, 'getUserMetadata');
        const result = await getUserMetadata({ uid: targetUserId });

        // Extract metadata from result

        // The data structure is different than expected
        // It's { success: true, data: { metadata: { creationTime: ... } } }
        const responseData = result.data as {
          success: boolean;
          data?: {
            metadata?: IUserMetadata;
          };
        };

        if (responseData.success && responseData.data?.metadata?.creationTime) {
          const creationTime = responseData.data.metadata.creationTime;
          // Convert the creation time string to a Date object
          const parsedDate = new Date(creationTime);
          setCreationDate(parsedDate);
        } else {
          console.error('Creation time not found in metadata');
        }
      } catch (err) {
        console.error('Error fetching user metadata:', err);
        setError('Failed to load user metadata');
      } finally {
        setLoading(false);
      }
    };

    fetchUserMetadata();
  }, [user, userId]);

  return { creationDate, loading, error };
};
